/**
 * @generated SignedSource<<212079204d76ee23022d71a8ccde562c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceCallListPageContentDefaultFiltersFragment$data = {
  readonly representativeId: string | null | undefined;
  readonly roles: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"JobFilters_useJobFiltersSearchParamsFragment">;
  readonly " $fragmentType": "ServiceCallListPageContentDefaultFiltersFragment";
};
export type ServiceCallListPageContentDefaultFiltersFragment$key = {
  readonly " $data"?: ServiceCallListPageContentDefaultFiltersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceCallListPageContentDefaultFiltersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceCallListPageContentDefaultFiltersFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "representativeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobFilters_useJobFiltersSearchParamsFragment"
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "b5cb706723a3f3a0e81d7662d9410200";

export default node;
