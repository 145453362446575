import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton, ListItem, Menu, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { useMemo, useState, MouseEvent, useCallback } from 'react';
import { useRefetchableFragment, useSubscription } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { NotificationRow } from './NotificationRow';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { useTranslation } from 'react-i18next';
import { NotificationMenu_MarkAllNotificationsReadMutation } from './__generated__/NotificationMenu_MarkAllNotificationsReadMutation.graphql';
import { NotificationMenuFragment$key } from './__generated__/NotificationMenuFragment.graphql';
import CheckIcon from '@mui/icons-material/Check';

type SubscriptionPayload = { response: unknown };

function useNotification(onNext?: (response: SubscriptionPayload['response']) => void) {
  const config = useMemo(
    () => ({
      subscription: graphql`
        subscription NotificationMenu_NotificationSubscription {
          notificationChanged {
            notification {
              ...NotificationRowFragment
            }
          }
        }
      `,
      variables: {},
      onNext: onNext,
    }),
    [onNext],
  );

  useSubscription(config);
}

export function NotificationButton({
  onClick: handleClick,
  unreadBadgeContent,
  sx,
}: {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  unreadBadgeContent: string | undefined;
  sx?: SxProps<Theme>;
}) {
  return (
    <IconButton sx={sx} onClick={handleClick} aria-label='notification'>
      <Badge badgeContent={unreadBadgeContent} color='secondary' overlap='circular'>
        <NotificationsIcon
          sx={{
            fontSize: '1.75rem',
            color: '#b3b3b3', // set to #b3b3b3 to match what's used in other projects.
          }}
        />
      </Badge>
    </IconButton>
  );
}

export function NotificationMenu({ $key, sx }: { $key: NotificationMenuFragment$key; sx?: SxProps<Theme> }) {
  const { t } = useTranslation('layout');

  const [$data, refetch] = useRefetchableFragment(
    graphql`
      fragment NotificationMenuFragment on Query @refetchable(queryName: "NotificationMenuFragmentQuery") {
        notifications(order: { createdAt: DESC }, first: 100) {
          nodes {
            id
            ...NotificationRowFragment
          }
        }
        unreadNotifications: notifications(where: { isRead: { eq: false } }, first: 100) {
          nodes {
            ...NotificationRowFragment
          }
        }
      }
    `,
    $key,
  );

  const [commit] = usePromiseMutation<NotificationMenu_MarkAllNotificationsReadMutation>(graphql`
    mutation NotificationMenu_MarkAllNotificationsReadMutation {
      markAllNotificationsRead {
        success
      }
    }
  `);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRefetch = useCallback(() => {
    // TODO: make it work without refetching (@prepend directive, updater function, etc.) (GG-8008)
    refetch({}, { fetchPolicy: 'store-and-network' });
  }, [refetch]);
  useNotification(handleRefetch);

  const handleClick = async () => {
    await commit({ variables: {} });
  };

  const notifications = $data.notifications?.nodes?.map((n) => <NotificationRow key={n.id} $key={n} onClick={handleRefetch} />) ?? [];
  const unreadNotificationCount = $data.unreadNotifications?.nodes?.length;
  const unreadBadgeContent = unreadNotificationCount ? unreadNotificationCount.toString() : undefined;

  return (
    <>
      <NotificationButton sx={sx} onClick={handleMenuClick} unreadBadgeContent={unreadBadgeContent} />
      <Menu MenuListProps={{ 'aria-label': 'notificationMenu' }} anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
        {notifications.length > 0 ? (
          <ListItem sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: '1rem' }} divider>
            <Typography variant='h5' component='h2'>
              {t('notification.title')}
            </Typography>
            <Tooltip title={t('notification.markAllRead')}>
              <IconButton size='medium' onClick={handleClick}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
        ) : (
          <ListItem>{t('notification.empty')}</ListItem>
        )}
        {notifications}
      </Menu>
    </>
  );
}
