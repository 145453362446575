import { Button, ButtonProps } from '@mui/material';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { useCallback, useState } from 'react';
import { SxProps } from '@mui/system';

interface ConfirmationProps {
  buttonColor?: ButtonProps['color'];
  buttonText: string;
  confirmationTitle: string;
  confirmationMessage: string;
  confirmationButton: string;
  onConfirm: () => void;
  sx?: SxProps;
}

export function ConfirmationButton({
  buttonColor,
  buttonText,
  confirmationTitle,
  confirmationMessage,
  confirmationButton,
  onConfirm: handleConfirm,
  sx,
}: ConfirmationProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleComplete = useCallback(async () => {
    setIsLoading(true);
    try {
      await handleConfirm();
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    setIsDialogOpen(false);
  }, [handleConfirm]);

  const handleButtonClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsLoading(false);
    setIsDialogOpen(false);
  }, []);

  return (
    <>
      <Button color={buttonColor} onClick={handleButtonClick} sx={sx}>
        {buttonText}
      </Button>

      <ConfirmationDialog
        confirmColor={buttonColor}
        isOpen={isDialogOpen}
        title={confirmationTitle}
        message={confirmationMessage}
        confirmText={confirmationButton}
        onConfirm={handleComplete}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </>
  );
}
