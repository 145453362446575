export class ElementNotFoundError extends Error {
  public readonly entity: string;
  public readonly id: string;
  public readonly previousPath: string;

  constructor(entity: string, id: string, previousPath: string) {
    super('Element not found');
    this.entity = entity;
    this.id = id;
    this.previousPath = previousPath;
    Object.setPrototypeOf(this, ElementNotFoundError.prototype);
  }
}
