import * as Sentry from '@sentry/react';
import { useTheme } from '@mui/material';
import ErrorFallback from './ErrorFallback';
import { DetailsLayout, SidebarContentProps } from './Layouts';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationMenu } from './SidebarDrawer';
import { errorBoundaryBeforeCaptureFn } from '../AppErrorBoundary';
import { defaultLogger, Logger } from '../common/utils/logging';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { DetailsPageRootErrorBoundaryFragment$key } from './__generated__/DetailsPageRootErrorBoundaryFragment.graphql';

const logger = new Logger(defaultLogger, 'DetailsPageRootErrorBoundary', () => new Date().toISOString());

export function DetailsPageRootErrorBoundary({
  heading,
  $key,
  children,
}: {
  heading: string;
  $key: DetailsPageRootErrorBoundaryFragment$key;
  children: ReactNode;
}) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  const $data = useFragment(
    graphql`
      fragment DetailsPageRootErrorBoundaryFragment on Query {
        ...SidebarDrawerFragment
        ...LayoutsDetailsLayoutFragment
      }
    `,
    $key,
  );

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <DetailsLayout heading={heading} sidebarProvider={sidebar} $key={$data}>
          <ErrorFallback
            error={error}
            subtitleMessage={t('requestSupport')}
            onButtonClick={() => resetError()}
            buttonMessage={t('button.retry')}
          />
        </DetailsLayout>
      )}
      beforeCapture={(scope) => errorBoundaryBeforeCaptureFn(scope, theme)}
      onError={(...args) => {
        logger.error(...args);
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
