import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import TimeAgo from 'react-timeago';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import CircleIcon from '@mui/icons-material/Circle';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationRow_PermalinkFragment$key } from './__generated__/NotificationRow_PermalinkFragment.graphql';
import { NotificationRow_BaseFragment$key } from './__generated__/NotificationRow_BaseFragment.graphql';
import { PropsWithChildren, ReactNode, useCallback } from 'react';
import { NotificationRowFragment$key } from './__generated__/NotificationRowFragment.graphql';
import { NotificationRow_MarkNotificationReadMutation } from './__generated__/NotificationRow_MarkNotificationReadMutation.graphql';
import { dateTimeFormat, timeAgoFormatter } from '../common/utils/dateTimeUtils';
import { DateTime } from 'luxon';

function NotificationRow_Permalink({
  $key,
  onClick,
}: {
  $key: NotificationRow_PermalinkFragment$key;
  onClick: (id: DataID) => Promise<void> | void;
}) {
  const { t } = useTranslation('layout');
  const $data = useFragment(
    graphql`
      fragment NotificationRow_PermalinkFragment on PermalinkNotification {
        permalink
        isRead
        ...NotificationRow_BaseFragment
      }
    `,
    $key,
  );

  const navigate = useNavigate();

  const handleClick = useCallback(
    async (id: DataID) => {
      await onClick(id);
      const permalinkUrl = new URL($data.permalink);
      if (permalinkUrl.origin === window.location.origin) {
        navigate(permalinkUrl.pathname);
        return;
      }

      window.location.assign($data.permalink);
    },
    [$data.permalink, navigate, onClick],
  );

  return (
    <NotificationRow_Base
      heading={
        <Typography variant='body1' sx={{ flexGrow: 1, fontWeight: $data.isRead ? 'normal' : 'bold' }}>
          {t('notification.permalink')}
        </Typography>
      }
      $key={$data}
      onClick={handleClick}>
      <Box
        component='span'
        sx={{
          fontWeight: $data.isRead ? 'normal' : 'bold',
        }}>
        {$data.permalink}
      </Box>
    </NotificationRow_Base>
  );
}

function NotificationRow_Base({
  $key,
  onClick,
  heading,
  children,
}: PropsWithChildren<{ $key: NotificationRow_BaseFragment$key; heading: ReactNode; onClick: (id: DataID) => Promise<void> | void }>) {
  const { i18n } = useTranslation('layout');
  const language = i18n.resolvedLanguage || i18n.languages[0];

  const $data = useFragment(
    graphql`
      fragment NotificationRow_BaseFragment on INotification {
        id
        isRead
        createdAt
      }
    `,
    $key,
  );

  const [commit] = usePromiseMutation<NotificationRow_MarkNotificationReadMutation>(graphql`
    mutation NotificationRow_MarkNotificationReadMutation($input: MarkNotificationReadInput!) {
      markNotificationRead(input: $input) {
        notification {
          ...NotificationRowFragment
        }
      }
    }
  `);

  const handleClick = async (id: DataID) => {
    await commit({ variables: { input: { id: id } } });
    await onClick(id);
  };

  const theme = useTheme();

  return (
    <MenuItem divider onClick={() => handleClick($data.id)} sx={{ flexDirection: 'column', alignItems: 'stretch', gap: '0.5rem' }}>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'baseline' }}>
        {heading}
        <TimeAgo
          style={{ ...theme.typography.body2, fontWeight: $data.isRead ? 'normal' : 'bold' }}
          date={$data.createdAt}
          formatter={language === 'fr' ? timeAgoFormatter(language) : undefined}
          title={DateTime.fromISO($data.createdAt).toFormat(dateTimeFormat)}
        />
        {!$data.isRead && <CircleIcon sx={{ fontSize: '0.75rem' }} color='secondary' />}
      </Box>
      {children}
    </MenuItem>
  );
}

export function NotificationRow({
  $key,
  onClick: handleClick,
}: {
  $key: NotificationRowFragment$key;
  onClick: (id: DataID) => Promise<void> | void;
}) {
  const $data = useFragment(
    graphql`
      fragment NotificationRowFragment on INotification {
        __typename
        ...NotificationRow_BaseFragment
        ...NotificationRow_PermalinkFragment
      }
    `,
    $key,
  );

  switch ($data.__typename) {
    case 'PermalinkNotification':
      return <NotificationRow_Permalink $key={$data} onClick={handleClick} />;
    case 'NotificationBase':
      return <NotificationRow_Base heading={<>&nbsp;</>} $key={$data} onClick={handleClick} />;
    default:
      return null;
  }
}
