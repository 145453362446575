/**
 * @generated SignedSource<<79c45d871a0517ad6c0642d3f4c6dab3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteFragment">;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment";
};
export type JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobEquipment_useCraneSelectorFavoriteFragment"
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "0a4a02cbe8b5781dfd10d2587301a576";

export default node;
