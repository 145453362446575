import { KeyType } from 'react-relay/relay-hooks/helpers';
import {
  ElementType,
  forwardRef,
  ReactElement,
  Ref,
  RefAttributes,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { ChipTypeMap, CircularProgress } from '@mui/material';
import { RelayObservable } from 'relay-runtime/lib/network/RelayObservable';
import { useCancellableSubscription } from '../hooks/useCancellableSubscription';
import { GraphQLTaggedNode, usePaginationFragment, useRefetchableFragment } from 'react-relay';
import { Options } from 'react-relay/relay-hooks/useRefetchableFragmentNode';
import { ForwardAutocompleteProps, SelectPicker, SelectPickerProps, SelectPickerValue } from './SelectPicker';

const infiniteScrollTriggerOffset = 300;

/***
 * Creates a ref based on another ref that might not yet have been initialized. This method ensures that the resulting
 * ref will always be initialized but might fail when accessing specific properties instead of the ref itself.
 *
 * @param ref
 */
export function proxyConditionalRef<TRef extends object>(ref: RefObject<TRef>) {
  return new Proxy({} as unknown as TRef, {
    get(target: TRef, p: string | symbol): unknown {
      if (ref.current == null) {
        throw new Error('Operation not valid at this time. Backing ref is null or undefined');
      }

      const candidate = (ref.current as unknown as Record<string | symbol, unknown>)[p];
      if (typeof candidate === 'function' && !Object.hasOwnProperty.call(ref.current, p)) {
        return candidate.bind(ref.current);
      }
      return candidate;
    },
    set(target: TRef, p: string | symbol, newValue: unknown): boolean {
      if (ref.current == null) {
        throw new Error('Operation not valid at this time. Backing ref is null or undefined');
      }

      (ref.current as unknown as Record<string | symbol, unknown>)[p] = newValue;

      return true;
    },
  });
}

export const groupBySymbol = Symbol('AutocompleteMetadata GroupBy key');

export type GroupByKey = 'suggestions' | 'searchResults';

export type AutocompleteMetadata = {
  readonly [groupBySymbol]: GroupByKey;
};

export type Node = {
  readonly id: string;
};

export type Suggestion<T> = {
  readonly score: number;
  readonly value: T;
};

export type Connection<N extends Node> =
  | {
      readonly edges:
        | ReadonlyArray<{
            readonly node: N;
          }>
        | null
        | undefined;
    }
  | null
  | undefined;

type QueryResults<N extends Node> = {
  readonly searchResults: Connection<N>;
  readonly suggestions?: ReadonlyArray<Suggestion<N>>;
};

type ConnectionResultsFromQuery<TData extends Pick<QueryResults<Node>, 'searchResults'>> = NonNullable<
  NonNullable<TData['searchResults']>['edges']
>[number]['node'];

type ConnectionSuggestionsFromQuery<TData extends Pick<QueryResults<Node>, 'suggestions'>> = NonNullable<
  TData['suggestions']
>[number]['value'];

export type ConnectionNode<TData extends QueryResults<Node>> = TData['suggestions'] extends readonly unknown[] | undefined
  ? ConnectionResultsFromQuery<TData> | ConnectionSuggestionsFromQuery<TData>
  : ConnectionResultsFromQuery<TData>;

export type ForwardSelectPickerProps<
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  SelectPickerProps<Option, Multiple, DisableClearable, false, ChipComponent> &
    ForwardAutocompleteProps<Option, Multiple, DisableClearable, false, ChipComponent>,
  | 'ref'
  | 'autoComplete'
  | 'filterOptions'
  | 'options'
  | 'renderInput'
  | 'onOpen'
  | 'onInputChange'
  | 'onChange'
  | 'loading'
  | 'getOptionKey'
>;

export interface Queryable {
  query(): void;

  reset(): void;
}

export type ForwardPaginatedAutocompleteProps<
  N extends Node,
  K extends keyof (PaginatedAutocompleteProps<N> & ForwardSelectPickerProps<N, Multiple, DisableClearable, ChipComponent>),
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  PaginatedAutocompleteProps<N, Multiple, DisableClearable> & ForwardSelectPickerProps<N, Multiple, DisableClearable, ChipComponent>,
  K
>;

export type PaginatedAutocompleteProps<
  N extends Node,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
> = {
  fragment: GraphQLTaggedNode;
  onQuery: (searchTerm: string | null, suggest: boolean) => RelayObservable<KeyType<QueryResults<N>>>;
  onChange?: (value: SelectPickerValue<N, Multiple, DisableClearable, false>, event: SyntheticEvent) => void;
  autocompleteRef?: Ref<HTMLDivElement>;
  queryRef?: Ref<Queryable | undefined>;
  filterSelectedOptions?: boolean;
  getOptionKey?: SelectPickerProps<N, Multiple, DisableClearable, false, ElementType>['getOptionKey'];
};

export const ConnectionPaginatedAutocomplete = forwardRef<
  HTMLInputElement,
  PaginatedAutocompleteProps<Node & AutocompleteMetadata, boolean, boolean> &
    ForwardSelectPickerProps<Node & AutocompleteMetadata, boolean, boolean, ElementType>
>(function ConnectionPaginatedAutocomplete<
  N extends Node,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    // PaginatedAutocomplete
    fragment,
    onQuery,
    onChange,
    queryRef,
    // Autocomplete
    ListboxProps,
    textFieldProps,
    autocompleteRef,
    multiple,
    ...autocompleteProps
  }: PaginatedAutocompleteProps<N & AutocompleteMetadata, Multiple, DisableClearable> &
    ForwardSelectPickerProps<N & AutocompleteMetadata, Multiple, DisableClearable, ChipComponent>,
  ref: Ref<HTMLInputElement>,
) {
  const [, setSubscription] = useCancellableSubscription();
  const [isLoading, setIsLoading] = useState(false);

  const [results, setResults] = useState<KeyType<QueryResults<N>> | null>(null);
  const doQuery = useCallback(
    (searchTerm: string | null, suggest?: boolean) => {
      let result: KeyType<QueryResults<N>>;
      setSubscription(
        onQuery(searchTerm, !!suggest).subscribe({
          complete: () => {
            setResults(result);
            setIsLoading(false);
          },
          error: () => setIsLoading(false),
          next: (nextResult) => (result = nextResult),
          start: () => setIsLoading(true),
          unsubscribe: () => setIsLoading(false),
        }),
      );
    },
    [setSubscription, onQuery],
  );

  useEffect(() => {
    setResults(null);
  }, [doQuery, fragment]);

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => proxyConditionalRef<HTMLInputElement>(inputRef));
  useImperativeHandle(queryRef, () => ({
    query: () => doQuery(inputRef.current?.value ?? null),
    reset: () => setResults(null),
  }));
  const { data, loadNext, isLoadingNext } = usePaginationFragment(fragment, results);
  const options = [
    // TODO: We have no idea why the s in this context can be undefined
    // Basically the suggestions array contains 5 times "undefined" rather than being undefined itself
    ...(data?.suggestions
      ?.filter((s) => s)
      .map(
        (s) =>
          ({
            ...s.value,
            [groupBySymbol]: 'suggestions',
          }) satisfies N & AutocompleteMetadata,
      ) ?? []),
    ...(data?.searchResults?.edges?.map(
      (e) =>
        ({
          ...e.node,
          [groupBySymbol]: 'searchResults',
        }) satisfies N & AutocompleteMetadata,
    ) ?? []),
  ];

  return (
    <SelectPicker<N & AutocompleteMetadata, Multiple, DisableClearable, false, ChipComponent>
      isOptionEqualToValue={(value, option) => value.id === option.id}
      {...autocompleteProps}
      ref={autocompleteRef}
      multiple={multiple}
      filterOptions={(o) => o}
      options={options}
      getOptionKey={(o) => o.id}
      loading={isLoading || isLoadingNext}
      onOpen={() => doQuery(null, true)}
      onInputChange={(e, v, reason) => {
        if (reason !== 'reset') {
          doQuery(v, !v);
        }
      }}
      onChange={(e, v, reason) => {
        if (reason !== 'blur') {
          onChange?.(v, e);
        }
      }}
      textFieldProps={(params) => {
        const p = textFieldProps?.(params);
        return {
          ...p,
          inputRef: inputRef,
          InputProps: {
            ...(p?.InputProps ?? params.InputProps),
            endAdornment: (
              <>
                {(isLoading || isLoadingNext) && <CircularProgress color='inherit' size={20} />}
                {p?.InputProps?.endAdornment ?? params.InputProps.endAdornment}
              </>
            ),
          },
        };
      }}
      ListboxProps={{
        ...ListboxProps,
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight + infiniteScrollTriggerOffset >= listboxNode.scrollHeight) {
            loadNext(25, {
              UNSTABLE_extraVariables: {
                suggest: false,
              },
            });
          }
        },
      }}
    />
  );
}) as <
  N extends Node,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: PaginatedAutocompleteProps<N & AutocompleteMetadata, Multiple, DisableClearable> &
    ForwardSelectPickerProps<N & AutocompleteMetadata, Multiple, DisableClearable, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;

export type OffsetResult = Record<string, unknown>;

export type OffsetItem<R extends OffsetResult> = {
  readonly result: R;
};

export type OffsetPage<R extends OffsetResult> =
  | {
      readonly items: ReadonlyArray<OffsetItem<R>> | null | undefined;
      readonly pageInfo: { hasNextPage: boolean };
    }
  | null
  | undefined;

type QueryResultsOffset<R extends OffsetResult> = {
  readonly searchResults: OffsetPage<R>;
  readonly suggestions?: ReadonlyArray<Suggestion<R>>;
};

type OffsetResultsFromQuery<TData extends Pick<QueryResultsOffset<OffsetResult>, 'searchResults'>> = NonNullable<
  NonNullable<TData['searchResults']>['items']
>[number]['result'];

type OffsetSuggestionsFromQuery<TData extends Pick<QueryResultsOffset<OffsetResult>, 'suggestions'>> = NonNullable<
  TData['suggestions']
>[number]['value'];

export type OffsetNode<TData extends QueryResultsOffset<OffsetResult>> = TData['suggestions'] extends readonly unknown[] | undefined
  ? OffsetResultsFromQuery<TData> | OffsetSuggestionsFromQuery<TData>
  : OffsetResultsFromQuery<TData>;

export type ForwardOffsetPaginatedAutocompleteProps<
  R extends OffsetResult,
  K extends keyof (OffsetPaginatedAutocompleteProps<R> & ForwardSelectPickerProps<R, Multiple, DisableClearable, ChipComponent>),
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  OffsetPaginatedAutocompleteProps<R, Multiple, DisableClearable> & ForwardSelectPickerProps<R, Multiple, DisableClearable, ChipComponent>,
  K
>;

export type OffsetPaginatedAutocompleteProps<
  R extends OffsetResult,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
> = {
  fragment: GraphQLTaggedNode;
  onQuery: (searchTerm: string | null, suggest: boolean) => RelayObservable<KeyType<QueryResultsOffset<R>>>;
  onChange?: (value: SelectPickerValue<R, Multiple, DisableClearable, false>, event: SyntheticEvent) => void;
  autocompleteRef?: Ref<HTMLDivElement>;
  queryRef?: Ref<Queryable | undefined>;
  filterSelectedOptions?: boolean;
  getOptionKey: SelectPickerProps<R, Multiple, DisableClearable, false, ElementType>['getOptionKey'];
};

export const OffsetPaginatedAutocomplete = forwardRef<
  HTMLInputElement,
  OffsetPaginatedAutocompleteProps<Node & AutocompleteMetadata, boolean, boolean> &
    ForwardSelectPickerProps<Node & AutocompleteMetadata, boolean, boolean, ElementType>
>(function OffsetPaginatedAutocomplete<
  R extends OffsetResult,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    // PaginatedAutocomplete
    fragment,
    onQuery,
    onChange,
    queryRef,
    // Autocomplete
    ListboxProps,
    textFieldProps,
    autocompleteRef,
    multiple,
    ...autocompleteProps
  }: OffsetPaginatedAutocompleteProps<R & AutocompleteMetadata, Multiple, DisableClearable> &
    ForwardSelectPickerProps<R & AutocompleteMetadata, Multiple, DisableClearable, ChipComponent>,
  ref: Ref<HTMLInputElement>,
) {
  const [, setSubscription] = useCancellableSubscription();
  const [isLoading, setIsLoading] = useState(false);
  const [, startTransition] = useTransition();

  const [fragmentKey, setFragmentKey] = useState<KeyType<QueryResultsOffset<R>> | null>(null);

  const doQuery = useCallback(
    (searchTerm: string | null, suggest?: boolean) => {
      setFragmentKey(null);
      setPages([]);
      if (suggest) {
        setSuggestions([]);
      }
      setIsLoading(true);
      setSubscription(
        onQuery(searchTerm, !!suggest).subscribe({
          next: (result) => setFragmentKey(result),
          error: () => setIsLoading(false),
          unsubscribe: () => setIsLoading(false),
        }),
      );
    },
    [setSubscription, onQuery],
  );

  useEffect(() => {
    setFragmentKey(null);
    setPages([]);
    setSuggestions([]);
  }, [doQuery, fragment]);

  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLBaseElement>(null);

  useImperativeHandle(ref, () => proxyConditionalRef<HTMLInputElement>(inputRef));
  useImperativeHandle(queryRef, () => ({
    query: () => doQuery(inputRef.current?.value ?? null),
    reset: () => setFragmentKey(null),
  }));

  const [pages, setPages] = useState<OffsetPage<R>[]>([]);
  const [suggestions, setSuggestions] = useState<ReadonlyArray<Suggestion<R>>>([]);
  const [page, refetch] = useRefetchableFragment(fragment, fragmentKey);

  const loadNext = useCallback(
    (options: Options): boolean => {
      if (!pages[pages.length - 1]?.pageInfo.hasNextPage) {
        return false;
      }
      const skip = pages.reduce((acc, cur) => acc + (cur?.items?.length ?? 0), 0);
      setIsLoading(true);
      startTransition(() => {
        refetch({ skip }, options);
      });

      return true;
    },
    [refetch, pages],
  );

  useEffect(() => {
    const p = page?.searchResults;
    const s = page?.suggestions;
    const missingData = !p || !p.items;
    const invalidPage = !p?.items?.length && p?.pageInfo.hasNextPage;

    if (missingData || invalidPage) {
      setPages([]);
      setSuggestions([]);
      setIsLoading(false);
      return;
    }

    setPages((ps) => [...ps, p]);
    setSuggestions(s ?? []);
  }, [page]);

  useEffect(() => {
    if (!pages.length) return;

    const listBoxHeight = listBoxRef.current?.clientHeight ?? 0;
    const lastElementTop = (listBoxRef.current?.lastElementChild as HTMLElement | undefined | null)?.offsetTop ?? 0;

    if (lastElementTop < listBoxHeight && pages[pages.length - 1]?.pageInfo.hasNextPage) {
      loadNext({});
      return;
    }
    setIsLoading(false);
  }, [pages, loadNext]);

  const data = useMemo(
    () => [
      ...(suggestions.map(
        (s) =>
          ({
            ...s.value,
            [groupBySymbol]: 'suggestions',
          }) satisfies R & AutocompleteMetadata,
      ) ?? []),
      ...pages.reduce(
        (acc, cur) => [
          ...acc,
          ...(cur?.items?.map(
            (i) =>
              ({
                ...i.result,
                [groupBySymbol]: 'searchResults',
              }) satisfies R & AutocompleteMetadata,
          ) ?? []),
        ],
        [] as (R & AutocompleteMetadata)[],
      ),
    ],
    [pages, suggestions],
  );

  return (
    <>
      <SelectPicker<R & AutocompleteMetadata, Multiple, DisableClearable, false, ChipComponent>
        filterOptions={(o) => o}
        {...autocompleteProps}
        ref={autocompleteRef}
        multiple={multiple}
        options={data}
        loading={isLoading}
        onOpen={() => {
          doQuery(null, true);
        }}
        onInputChange={(_, v, reason) => {
          if (reason !== 'reset') {
            doQuery(v, !v);
          }
        }}
        onChange={(e, v, reason) => {
          if (reason !== 'blur') {
            onChange?.(v, e);
          }
        }}
        textFieldProps={(params) => {
          const p = textFieldProps?.(params);
          return {
            ...p,
            inputRef: inputRef,
            InputProps: {
              ...(p?.InputProps ?? params.InputProps),
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color='inherit' size={20} />}
                  {p?.InputProps?.endAdornment ?? params.InputProps.endAdornment}
                </>
              ),
            },
          };
        }}
        ListboxProps={{
          ...ListboxProps,

          ref: listBoxRef,
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop + listboxNode.clientHeight + infiniteScrollTriggerOffset >= listboxNode.scrollHeight) {
              loadNext({});
            }
          },
        }}
      />
    </>
  );
}) as <
  R extends OffsetResult,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: OffsetPaginatedAutocompleteProps<R, Multiple, DisableClearable> &
    ForwardSelectPickerProps<R, Multiple, DisableClearable, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
