/**
 * @generated SignedSource<<ba550dda3586c46785401431b0014d41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorizationAdminFragment$data = {
  readonly me: {
    readonly accessLevels: {
      readonly hasAdmin: boolean;
    };
  } | null | undefined;
  readonly " $fragmentType": "AuthorizationAdminFragment";
};
export type AuthorizationAdminFragment$key = {
  readonly " $data"?: AuthorizationAdminFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationAdminFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthorizationAdminFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccessLevels",
          "kind": "LinkedField",
          "name": "accessLevels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasAdmin",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "36d3c179f10c54290e8beae8418cb838";

export default node;
