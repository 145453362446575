/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const rateStrategyCategories = ["additionalCrane", "mainBoomCounterweight", "mainCrane", "otherBoomCounterweight"] as const;
export type RateStrategyCategory = typeof rateStrategyCategories[number];

export function isRateStrategyCategory(value: string): value is RateStrategyCategory {
  return rateStrategyCategories.includes(value)
}

export function asRateStrategyCategory(value: string): RateStrategyCategory | null {
  return isRateStrategyCategory(value) ? value : null;
}

export function castRateStrategyCategory(value: string): RateStrategyCategory {
  if(!isRateStrategyCategory(value)) {
    throw new Error(`Invalid Enum value for type "RateStrategyCategory": "${value}"`);
  }
  
  return value;
}
