import { ReactNode, SetStateAction, useCallback, useMemo } from 'react';

/**
 * Returns a transformation function for an input array that replaces values that matches a predicate with a given value.
 * @param value     The replacement value.
 * @param predicate A predicate that determines if a value should be replaced.
 */
export function arrSetBy<V>(value: V, predicate: (value: V, index: number) => boolean) {
  return (vs: V[]): V[] => vs.map((v, i) => (predicate(v, i) ? value : v));
}

/**
 * Returns a transformation function for an input array that replaces the value at the given index.
 *
 * If the index isn't present in the input array, then no value is changed.
 * @param value The replacement value.
 * @param index The index to replace.
 */
export function arrSetByIndex<V>(value: V, index: number): ReturnType<typeof arrSetBy<V>> {
  return arrSetBy(value, (_, i) => i === index);
}

/**
 * Returns a transformation function for an input array that applies a mapping function on values matching a predicate.
 * @param mapper    A transformation function that takes the old value with its index and produce a new value.
 * @param predicate A predicate that determines if a value should go through the transformation function.
 */
export function arrPatchBy<V>(mapper: (value: V, index: number) => V, predicate: (value: V, index: number) => boolean) {
  return (vs: V[]): V[] => vs.map((v, i) => (predicate(v, i) ? mapper(v, i) : v));
}

/**
 * Returns a transformation function for an input array that prepend the collection with a given value.
 * @param value The value to prepend.
 */
export function arrPrepend<V>(value: V) {
  return (vs: V[]): V[] => [value, ...vs];
}

/**
 * Returns a transformation function for an input array that append the collection with a given value.
 * @param value The value to append.
 */
export function arrAppend<V>(value: V) {
  return (vs: V[]): V[] => [...vs, value];
}

/**
 * Returns a transformation function for an input array that removes elements matching a predicate from the collection.
 * @param predicate A predicate that determines if a value should be removed from the collection.
 */
export function arrRemove<V>(predicate: (value: V, index: number) => boolean) {
  return (vs: V[]): V[] => vs.filter((v, i) => !predicate(v, i));
}

/**
 * A Patchable value is a value that includes change tracking metadata; typically used as part of a collection of values.
 */
export type Patchable<V> = V & {
  /**
   * A flag that tracks whether the value not yet saved.
   */
  $new?: true;
  /**
   * A flag that tracks whether the value was deleted.
   */
  $removed?: true;
  /**
   * A collection of flags that tracks whether individual fields in the value contains unsaved changed.
   */
  $dirty?: {
    [k in keyof V]?: true;
  };
};

/**
 * A utility function that flags a Patchable as new.
 *
 * @see Patchable
 *
 * @param value A Patchable value.
 */
export function flagNew<V extends Patchable<unknown>>(value: V): V {
  value.$new = true;
  return value;
}

/**
 * A utility function that flags a Patchable as removed.
 *
 * @see Patchable
 *
 * @param value A Patchable value.
 */
export function flagRemoved<V extends Patchable<unknown>>(value: V): V {
  return { ...value, $removed: true };
}

/**
 * A utility function that flags some fields of a patchable value as dirty.
 *
 * Dirty fields can be unmarked, dirty, or not dirty. Unmarked fields should be considered not dirty.
 * @param value A Patchable value.
 * @param dirty The fields to add or remove to the list of dirty fields.
 */

export function flagDirty<V extends Patchable<Record<string, unknown>>>(
  value: V,
  dirty: Partial<Record<keyof V | `${string & keyof V}.${string}`, boolean>>,
): V {
  value.$dirty = { ...value.$dirty, ...dirty };
  return value;
}

/**
 * Checks whether a patchable value has some of its dirty flags set.
 * @param value A patchable value.
 */
export function hasDirty<V extends Patchable<Record<string, unknown>>>(value: V): boolean {
  return Object.values(value.$dirty ?? {}).some((x) => x);
}

/**
 * Checks whether a patchable value is considered changed from the point of view of an external system.
 * @param value A patchable value.
 */
export function hasChanged(value: Patchable<unknown>): boolean {
  return value.$new && value.$removed ? false : value.$new || value.$removed || hasDirty(value);
}

/**
 * Describes a json-compatible command that can be applied on a collection to update its state.
 */
export type PatchOperation<V> =
  | {
      action: 'insert';
      value: V;
    }
  | {
      action: 'update';
      id: string;
      value: V;
    }
  | {
      action: 'delete';
      id: string;
    };

/**
 * Converts a changed patchable value into a GraphQL Patch object.
 * @param value         The patchable value to convert.
 * @param keySelector   A function that returns a unique identifier for the object.
 * @param valueSelector A function that returns the value to include in the GraphQL Patch object.
 */
export function toPatchOperation<V extends Patchable<Record<string, unknown>>, R>(
  value: V,
  keySelector: (value: V) => string,
  valueSelector: (value: V) => R,
): PatchOperation<R> {
  if (value.$new) {
    return {
      action: 'insert',
      value: valueSelector(value),
    };
  }

  if (value.$removed) {
    return {
      action: 'delete',
      id: keySelector(value),
    };
  }

  return {
    action: 'update',
    id: keySelector(value),
    value: valueSelector(value),
  };
}

/**
 * A React hook that wraps a given SetStateAction and exposes it as a set of high-level array mutation functions.
 *
 * @see SetStateAction
 *
 * @param setValues   The collection setter to wrap.
 * @param keySelector A function that returns a unique key for each element.
 */
export function usePatchable<V extends Patchable<unknown>>(
  setValues: (action: SetStateAction<V[]>) => void,
  keySelector: (value: V) => string,
): Readonly<{
  prepend: (value: V) => void;
  append: (value: V) => void;
  replace: (value: V) => void;
  patch: (value: Partial<V>) => void;
  remove: (value: V) => void;
}> {
  const prepend = useCallback(
    (value: V) => {
      setValues(arrPrepend(flagNew(value)));
    },
    [setValues],
  );
  const append = useCallback(
    (value: V) => {
      setValues(arrAppend(flagNew(value)));
    },
    [setValues],
  );
  const replace = useCallback(
    (value: V) => {
      setValues(arrSetBy(value, (v) => keySelector(v) === keySelector(value)));
    },
    [keySelector, setValues],
  );
  const patch = useCallback(
    (value: Partial<V>) =>
      setValues(
        arrPatchBy(
          (v) => ({ ...v, ...value }),
          (v) => keySelector(v) === keySelector(value as V),
        ),
      ),
    [keySelector, setValues],
  );
  const remove = useCallback(
    (value: V) => setValues(arrPatchBy(flagRemoved, (v) => keySelector(v) === keySelector(value))),
    [keySelector, setValues],
  );

  return useMemo(
    () =>
      Object.freeze({
        prepend,
        append,
        replace,
        patch,
        remove,
      }),
    [prepend, append, replace, patch, remove],
  );
}

export type PatchablePropsBase<V extends Patchable<unknown>> = {
  id: string;
  onChange: (newValue: V) => void;
};

export type PatchableEditProps<V extends Patchable<unknown>> = PatchablePropsBase<V> & {
  value: V;
  onDelete: () => void;
};

export function isPatchableEditProps<V extends Patchable<unknown>>(props: PatchablePropsBase<V>): props is PatchableEditProps<V> {
  return 'value' in props && 'onDelete' in props;
}

export function Patchable_EditItem<V extends Patchable<unknown>>({
  index,
  value,
  setValues,
  keySelector,
  render,
}: {
  index: number;
  value: V;
  setValues: (action: SetStateAction<V[]>) => void;
  keySelector: (value: V) => string;
  render: PatchableList_RenderFn<V>;
}): ReactNode {
  const onChange = useCallback(
    (newValue: V) =>
      setValues(
        arrPatchBy(
          (oldValue) => ({ ...oldValue, ...newValue }),
          (oldValue) => keySelector(oldValue) === keySelector(newValue),
        ),
      ),
    [keySelector, setValues],
  );
  const onDelete = useCallback(
    () => setValues(arrPatchBy(flagRemoved, (oldValue) => keySelector(oldValue) === keySelector(value))),
    [keySelector, setValues, value],
  );

  const props = useMemo(
    () => ({
      id: keySelector(value),
      value,
      onChange,
      onDelete,
    }),
    [keySelector, onChange, onDelete, value],
  );
  return render(props, index);
}

export type PatchableList_RenderFn<V extends Patchable<unknown>> = (params: PatchableEditProps<V>, index: number) => ReactNode;

/**
 * A React component that renders a list of patchable values using a given render function for each individual value.
 *
 * This list obeys the invariants of Patchable which specified that removed values should be considered as deleted.
 *
 * @see Patchable
 *
 * @param values      A list of Patchable values.
 * @param setValues   The setter for the provided list of Patchable values.
 * @param keySelector A function that returns a unique key for each element.
 * @param render      A function that returns a ReactNode for each element.
 */
export function PatchableList<V extends Patchable<unknown>>({
  values,
  setValues,
  keySelector,
  render,
}: {
  values: V[];
  setValues: (action: SetStateAction<V[]>) => void;
  keySelector: (value: V) => string;
  render: PatchableList_RenderFn<V>;
}): ReactNode {
  return useMemo(
    () =>
      values
        .filter((value) => !value.$removed)
        .map((value, index) => (
          <Patchable_EditItem<V>
            key={keySelector(value)}
            index={index}
            value={value}
            setValues={setValues}
            keySelector={keySelector}
            render={render}
          />
        )),
    [keySelector, render, setValues, values],
  );
}

// Examples
////////////////////////////////////////////////////////////////////////////////
//
// // Declare your type as Patchable
// type SubField = Patchable<{ id: string; kind: 'a' | 'b'; name: string }>;
//
// // In this case, we use our form tooling to store the data, but this also works with useState.
// const fieldTestColKey = createFieldKey<SubField[]>();
//
// export function useFieldTestCollection() {
//   // We start by getting a value/setter pair...
//   const [testCol, setTestCol] = useField(billingCodeRuleDetailsFormContext, fieldTestColKey, [
//     { id: nanoid(), kind: 'a', name: 'foo' },
//     { id: nanoid(), kind: 'b', name: 'bar' },
//     { id: nanoid(), kind: 'b', name: 'biz' },
//   ]);
//
//   // ...then we can the setter with usePatchable to expand it into some utility functions.
//   const { append } = usePatchable(setTestCol, (v) => v.id);
//
//   // Since we're using useField, we are expected to return a render function for this field.
//   const renderTestCol = useCallback(
//     () => (
//       <>
//         {/* We render a button at the top of the list to add new items... */}
//         <Button onClick={() => append({ id: nanoid(), kind: 'a', name: 'new' })}>Add</Button>
//
//         {/* ...then we use PatchableList to render only relevant values in the list and manage changes.  */}
//         <PatchableList
//           values={testCol}
//           setValues={setTestCol}
//           keySelector={(v) => v.id}
//           render={(params) => (
//             // This example chose to track individual entries' changes using a separate sub-form,
//             // so we have to setup individual FormProviders for each field.
//             // We also get the field's unique key to use as a component key in the entry's params.
//             <FormProvider key={params.key} context={subFormCtx}>
//               // We can finally render our sub-form. It takes all the params from the render function.
//               <SubFormComponent {...params} />
//             </FormProvider>
//           )}
//         />
//       </>
//     ),
//     [append, setTestCol, testCol],
//   );
//
//   // This field isn't any different from other form fields.
//   return { testCol, renderTestCol };
// }
//
// // We set up our sub-form that will track the content of our entries.
// // We also declare a mapping that we will use to communicate back with the main list of values.
// const subFormCtx = createFormContext<{ sync: SubField }>();
//
// // In this case, we only want to change the name in our entries, so we only declare one field.
// const nameKey = createFieldKey<string>();
//
// // The name field has validations that depends on the entity kind, and also notify the caller that its value is
// // changing to trigger the synchronisation.
// function useFieldName(initialValue: string, kind: 'a' | 'b', onChange: (value: string) => void) {
//   const [name, setName] = useField(subFormCtx, nameKey, initialValue);
//
//   const useNameMappers = useFieldMapper(subFormCtx, nameKey);
//
//   // We declare a mapper for the 'sync' mapping that maps the field to the 'name' property of a SubField,
//   // and also includes metadata on its dirtiness. This isn't done automatically, and you don't have to maintain
//   // them if they aren't valuable to you.
//   useNameMappers((v, { isDirty }) => flagDirty({ name: v }, { name: isDirty }), [], 'sync');
//
//   const useNameValidator = useFieldValidation(subFormCtx, nameKey);
//
//   // Here, we declare a validation that depends on other properties in the entry, specifically, we depend on 'kind'.
//   useNameValidator((v) => (kind === 'a' ? v.length > 0 : true), [kind], 'change:required-when-kind-a');
//
//   // We produce a completely standard render function for our field.
//   const renderName = useCallback(
//     () => (
//       <WithFieldErrors
//         context={subFormCtx}
//         fieldKey={nameKey}
//         render={(errors) => (
//           <TextField
//             value={name}
//             error={Object.keys(errors).length > 0}
//             onChange={({ target: { value } }) => {
//               setName(value);
//
//               // And after storing our value, we notify our caller that something changed.
//               // This could also be done using a useEffect with a dependency on 'name', but it risks rendering data
//               // that is out of sync due to the nature of effects' execution.
//               onChange(value);
//             }}
//           />
//         )}
//       />
//     ),
//     [onChange, setName, name],
//   );
//
//   return { name, setName, renderName };
// }
//
// // We can now finally declare the component that will display an individual entry in our collection.
// export function SubFormComponent({ value: { id, kind, name }, onChange, onDelete: handleDelete }: PatchableProps<SubField>) {
//   const { mapAll } = useFormMappings(subFormCtx);
//
//   // We call the field's hook, and make sure to notify the parent that changes were made though the provided function.
//   // In this case, this is done as you type through form mappings, but it could be done with a manual trigger, like
//   // a save button, or even without mappings if the use case is simple enough.
//   const { renderName } = useFieldName(name, kind, () => onChange(mapAll('sync')));
//
//   return (
//     <Box sx={{ display: 'flex' }}>
//       <div>{id}</div>
//       {renderName()}
//
//       {/* The last step is to call the provided delete function when we want to remove the entry. */}
//       <Button onClick={handleDelete}>Delete</Button>
//     </Box>
//   );
// }
