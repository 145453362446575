/**
 * @generated SignedSource<<de677b8141fc84e282d64dba0af8bb69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useResetBoomConfigurationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneCapacityFragment" | "SaleCraneSelectorFields_CraneConfigurationKindFragment" | "SaleCraneSelectorFields_EquipmentKindFragment" | "SaleCraneSelectorFields_Manual_BoomConfigurationFragment">;
  readonly " $fragmentType": "JobEquipment_useResetBoomConfigurationFragment";
};
export type JobEquipment_useResetBoomConfigurationFragment$key = {
  readonly " $data"?: JobEquipment_useResetBoomConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useResetBoomConfigurationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useResetBoomConfigurationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneCapacityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_EquipmentKindFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneConfigurationKindFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_Manual_BoomConfigurationFragment"
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "28e17717002059a6138398c2a6233999";

export default node;
