import graphql from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import { WorkForceTypeLabelResolver$key } from './__generated__/WorkForceTypeLabelResolver.graphql';

/**
 * @RelayResolver WorkForceTypeLookup.label: Localized
 * @rootFragment WorkForceTypeLabelResolver
 *
 * An object that contains a label for every language for WorkForceType which includes code and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(workForceType$key: WorkForceTypeLabelResolver$key): Record<string, string> {
  const workForceType$data = readFragment(
    graphql`
      fragment WorkForceTypeLabelResolver on WorkForceTypeLookup {
        code
        description
      }
    `,
    workForceType$key,
  );

  return Object.fromEntries(Object.entries(workForceType$data.description).map(([k, v]) => [k, `${workForceType$data.code} - ${v}`]));
}
