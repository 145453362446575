import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ReactNode } from 'react';
import { EmployeeNotFoundError } from './EmployeeNotFoundErrorBoundary';
import { useTranslation } from 'react-i18next';
import { AuthorizationWriteFragment$key } from './__generated__/AuthorizationWriteFragment.graphql';
import { AuthorizationReadFragment$key } from './__generated__/AuthorizationReadFragment.graphql';
import { AuthorizationAdminFragment$key } from './__generated__/AuthorizationAdminFragment.graphql';
import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

export function RequireRead({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationReadFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanRead $key={$key} render={(hasRead: boolean) => (hasRead ? children : fallback)} />;
}

export function CanRead({ $key, render }: { $key: AuthorizationReadFragment$key; render: (hasRead: boolean) => ReactNode }) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationReadFragment on Query {
        me {
          accessLevels {
            hasRead
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return render($data.me.accessLevels.hasRead);
}

export function RequireWrite({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationWriteFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanWrite $key={$key} render={(hasWrite: boolean) => (hasWrite ? children : fallback)} />;
}

export function CanWrite({ $key, render }: { $key: AuthorizationWriteFragment$key; render: (hasWrite: boolean) => ReactNode }) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationWriteFragment on Query {
        me {
          accessLevels {
            hasWrite
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return render($data.me.accessLevels.hasWrite);
}

export function RequireAdmin({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationAdminFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanAdmin $key={$key} render={(hasAdmin: boolean) => (hasAdmin ? children : fallback)} />;
}

export function CanAdmin({ $key, render }: { $key: AuthorizationAdminFragment$key; render: (hasAdmin: boolean) => ReactNode }) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationAdminFragment on Query {
        me {
          accessLevels {
            hasAdmin
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return render($data.me.accessLevels.hasAdmin);
}

export function UnauthorizedFallback({ onButtonClick: handleButtonClick }: { onButtonClick?: () => void }) {
  const { t } = useTranslation('auth');

  return (
    <Stack
      sx={{
        px: '1rem',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
      spacing={1}>
      <Typography variant='h5'>{t('unauthorized')}</Typography>
      <Typography>{t('contactYourAdmin')}</Typography>
      {handleButtonClick && (
        <Button onClick={handleButtonClick} variant='contained'>
          {t('button.goBack', { ns: 'common' })}
        </Button>
      )}
    </Stack>
  );
}
