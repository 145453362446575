/**
 * @generated SignedSource<<a61fe8569955906e7d4f0f3d4a292980>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PowerlineProximity = "from126KVTo250KV" | "from251KVTo550KV" | "lessThan126KV" | "moreThan550KV" | "%future added value";
export type SalesQuestionKind = "accidentScene" | "bargeWork" | "clamShellBucketOrGrapple" | "conventionalCrawlerCranesWithTravel" | "conventionalCrawlerCranesWithoutTravel" | "craneOrObstacleProximity" | "events" | "excavationProximityLessThan10Feet" | "excavationProximityMoreThan10Feet" | "hazardousTerrain" | "jettyBridgeViaductDamSlabOrParkingDeck" | "luffingJibOrExtensionInstallation" | "movingCraneOrEquipmentWithPulling" | "movingCraneOrEquipmentWithoutPulling" | "peopleLifting" | "powerlineProximity" | "tandemLiftingClientCrane" | "tandemLiftingGuayCrane" | "unloadingOnQuay" | "valueOf1MillionOrMore" | "videoOrPhotoShooting" | "windTurbines" | "workOf80TOrMore" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuestionsBaseFields_ProximityToPowerlineFragment$data = {
  readonly salesQuestions: ReadonlyArray<{
    readonly kind: SalesQuestionKind;
    readonly proximity?: PowerlineProximity | null | undefined;
  }>;
  readonly " $fragmentType": "QuestionsBaseFields_ProximityToPowerlineFragment";
};
export type QuestionsBaseFields_ProximityToPowerlineFragment$key = {
  readonly " $data"?: QuestionsBaseFields_ProximityToPowerlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsBaseFields_ProximityToPowerlineFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionsBaseFields_ProximityToPowerlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "salesQuestions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "proximity",
              "storageKey": null
            }
          ],
          "type": "SalesQuestionForPowerlineProximity",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuestionsInternalBase",
  "abstractKey": null
};

(node as any).hash = "953d0199ac4b0e848b35a6ea0c05c67b";

export default node;
