import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { Box, Chip, ChipTypeMap, Typography, useTheme } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  groupBySymbol,
  Queryable,
} from './PaginatedAutocomplete';
import { useTranslation } from 'react-i18next';
import { convertToTsQuery } from '../utils/stringUtils';
import { SelectPickerContentSuggestibleProps } from './SelectPicker';
import { ClientAutocompleteListFragment$data } from './__generated__/ClientAutocompleteListFragment.graphql';
import { ClientAutocompleteQuery } from './__generated__/ClientAutocompleteQuery.graphql';
import { CreditAlertIndicator } from '../../jobs/CreditAlertIndicator';
import { toDashedNumber } from '../utils/phoneUtils';

export type ForwardClientAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<ClientAutocompleteListFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const ClientAutocomplete = forwardRef<
  HTMLInputElement,
  ForwardPaginatedAutocompleteProps<
    ConnectionNode<ClientAutocompleteListFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    boolean,
    boolean,
    ElementType
  >
>(function ClientAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    multiple,
    ...paginatedAutocompleteProps
  }: ForwardPaginatedAutocompleteProps<
    ConnectionNode<ClientAutocompleteListFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  >,
  ref: Ref<HTMLInputElement>,
) {
  const env = useRelayEnvironment();

  const handleQuery = useCallback(
    (searchTerm: string | null) =>
      fetchQuery<ClientAutocompleteQuery>(
        env,
        graphql`
          query ClientAutocompleteQuery($searchTerm: String) {
            ...ClientAutocompleteListFragment @arguments(searchTerm: $searchTerm)
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
        },
      ),
    [env],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<ClientAutocompleteListFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      groupBy={(o) => o[groupBySymbol]}
      renderListItemContent={(params) => <ClientAutocompleteContent {...params} />}
      {...paginatedAutocompleteProps}
      ref={ref as Ref<HTMLInputElement & Queryable>}
      fragment={graphql`
        fragment ClientAutocompleteListFragment on Query
        @refetchable(queryName: "clientAutocompleteListFragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          searchCursor: { type: "String" }
          searchCount: { type: "Int", defaultValue: 25 }
        ) {
          searchResults: searchClients(searchTerm: $searchTerm, after: $searchCursor, first: $searchCount)
            @connection(key: "clientAutocompleteListFragmentQuery_searchResults") {
            edges {
              node {
                id
                name
                location {
                  address
                }
                category
                number
                phoneNumber
                representative {
                  name
                }
                ...CreditAlertIndicatorFragment
                deletedAt
                externalId
              }
            }
          }
        }
      `}
      onQuery={handleQuery}
      multiple={multiple}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: ForwardPaginatedAutocompleteProps<
    ConnectionNode<ClientAutocompleteListFragment$data>,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;

function ClientAutocompleteContent({
  option: $data,
}: SelectPickerContentSuggestibleProps<ConnectionNode<ClientAutocompleteListFragment$data> & AutocompleteMetadata>) {
  const theme = useTheme();
  const { t } = useTranslation('client');
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography color={theme.palette.text.secondary} variant='caption' data-testid={'client_number_rep_name'}>
          {$data.number} - {t('dialog.representative')}: {$data.representative?.name ?? ''}
        </Typography>
        {$data.deletedAt && (
          <Typography variant='overline' sx={{ color: theme.palette.error.main, ml: 1 }}>
            {t('inactive', { ns: 'common' })}
          </Typography>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.25rem' }}>
          <Typography variant='h6' component='h3' data-testid={'client_name'}>
            {$data.name}
          </Typography>
          <CreditAlertIndicator fragmentKey={$data} />
        </Box>
        <Typography variant='subtitle2' whiteSpace='pre-wrap' data-testid={'client_address'}>
          {$data.location?.address}
        </Typography>
        <Typography variant='subtitle1' data-testid={'client_phone'}>
          {toDashedNumber($data.phoneNumber)}
        </Typography>
      </Box>
      {$data.category !== 'NONE' && <Chip variant='outlined' data-testid={'client_category'} label={$data.category} />}
    </>
  );
}
