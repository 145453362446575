import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useFieldAssignedClient } from './fields/ClientBaseFields';
import { useFieldArrivalDate, useFieldWorksiteOverridesLocation, useFieldWorksiteOverridesName } from './fields/ProjectBaseFields';
import { useFieldDispatchBranch, useFieldNatureOfWork, useFieldNatureOfWorkSubCategory } from './fields/SaleProjectFields';
import {
  useEquipmentSectionIsOptional,
  useFieldCraneSelectorMode,
  useFieldFavoriteConfigurationETag,
} from './fields/SaleCraneSelectorFields';
import { useJobKindRequiresEquipment } from './fields/SaleEquipmentFields';
import { useOutletContext } from 'react-router-dom';
import { SaleDetailsContextValue } from './SaleDetailsContextValue';
import { useHasSatisfiedCostsDependenciesFragment$key } from './__generated__/useHasSatisfiedCostsDependenciesFragment.graphql';
import { useCraneSelectorManualFavorite } from './JobEquipment.CraneSelector.Manual';

export function useHasSatisfiedCostsDependencies($key: useHasSatisfiedCostsDependenciesFragment$key | null | undefined): boolean {
  const { kind } = useOutletContext<SaleDetailsContextValue>();

  const $data = useFragment(
    graphql`
      fragment useHasSatisfiedCostsDependenciesFragment on ISale {
        clientBase {
          ...ClientBaseFields_AssignedClientFragment
          ...ProjectBaseFields_WorksiteOverrides_ClientLocationFragment
        }
        projectBase {
          ...ProjectBaseFields_ArrivalDateFragment @arguments(isCopy: false)
          assignedWorksiteInfo {
            ...ProjectBaseFields_WorksiteOverrides_NameFragment
            ...ProjectBaseFields_WorksiteOverrides_LocationFragment
          }
        }
        project {
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_NatureOfWorkSubCategoryFragment
          natureOfWork {
            ...SaleProjectFields_NatureOfWorkSubCategoriesFragment
          }
          ...SaleProjectFields_DispatchBranchFragment
        }
        equipmentBase {
          craneSelector {
            ...SaleCraneSelectorFields_CraneSelectorModeFragment
            ...SaleCraneSelectorFields_FavoriteConfigurationFragment
            manualConfiguration {
              ...JobEquipment_useCraneSelectorManualFavoriteFragment
            }
          }
        }
      }
    `,
    $key,
  );

  const { assignedClient } = useFieldAssignedClient($data?.clientBase);
  const { worksiteOverridesName } = useFieldWorksiteOverridesName($data?.projectBase.assignedWorksiteInfo, true, true);
  const { arrivalDate } = useFieldArrivalDate($data?.projectBase, true, true);
  const { worksiteOverridesLocation } = useFieldWorksiteOverridesLocation(
    $data?.projectBase.assignedWorksiteInfo,
    $data?.clientBase,
    true,
    true,
  );
  const { natureOfWork } = useFieldNatureOfWork($data?.project, true);
  const { natureOfWorkSubCategory } = useFieldNatureOfWorkSubCategory($data?.project, $data?.project.natureOfWork, true);
  const { craneSelectorMode } = useFieldCraneSelectorMode($data?.equipmentBase.craneSelector, true);
  const isEquipmentSectionRequired = !useEquipmentSectionIsOptional(kind);
  const { manualFavorite } = useCraneSelectorManualFavorite(
    $data?.equipmentBase.craneSelector.manualConfiguration,
    $data?.equipmentBase.craneSelector,
    true,
    isEquipmentSectionRequired,
  );
  const { favoriteConfigurationETag } = useFieldFavoriteConfigurationETag($data?.equipmentBase.craneSelector);
  const { dispatchBranch } = useFieldDispatchBranch($data?.project, true);

  const kindRequiresEquipment = useJobKindRequiresEquipment(kind);
  const equipmentHasRequiredValues =
    !kindRequiresEquipment ||
    (craneSelectorMode === 'manual' && manualFavorite != null) ||
    (craneSelectorMode === 'lifts' && !!favoriteConfigurationETag);

  return (
    assignedClient != null &&
    arrivalDate != null &&
    !!worksiteOverridesName &&
    !!worksiteOverridesLocation?.address &&
    natureOfWork != null &&
    natureOfWorkSubCategory != null &&
    dispatchBranch != null &&
    equipmentHasRequiredValues
  );
}
