/**
 * @generated SignedSource<<28b1fbe3c92bd0ca0db1179235523ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryGroupAutocompleteListFragmentQuery$variables = {
  accessoryTypeCode?: number | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  dispatchBranchId?: string | null | undefined;
  searchTerm?: string | null | undefined;
};
export type AccessoryGroupAutocompleteListFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryGroupAutocompleteListFragment">;
};
export type AccessoryGroupAutocompleteListFragmentQuery = {
  response: AccessoryGroupAutocompleteListFragmentQuery$data;
  variables: AccessoryGroupAutocompleteListFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessoryTypeCode"
  },
  {
    "defaultValue": 25,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dispatchBranchId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "accessoryTypeCode",
  "variableName": "accessoryTypeCode"
},
v2 = {
  "kind": "Variable",
  "name": "dispatchBranchId",
  "variableName": "dispatchBranchId"
},
v3 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessoryGroupAutocompleteListFragmentQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AccessoryGroupAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccessoryGroupAutocompleteListFragmentQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v4/*: any*/),
        "concreteType": "SearchAccessoryGroupsConnection",
        "kind": "LinkedField",
        "name": "searchAccessoryGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchAccessoryGroupsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccessoryGroup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        }
                      ],
                      "type": "AccessoryGroup",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Branch",
                    "kind": "LinkedField",
                    "name": "branch",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v4/*: any*/),
        "filters": [
          "searchTerm",
          "accessoryTypeCode",
          "dispatchBranchId"
        ],
        "handle": "connection",
        "key": "AccessoryGroupAutocompleteListFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchAccessoryGroups"
      }
    ]
  },
  "params": {
    "cacheID": "1e47f1008865810fca3d687f94dc95c7",
    "id": null,
    "metadata": {},
    "name": "AccessoryGroupAutocompleteListFragmentQuery",
    "operationKind": "query",
    "text": "query AccessoryGroupAutocompleteListFragmentQuery(\n  $accessoryTypeCode: Int\n  $count: Int = 25\n  $cursor: String\n  $dispatchBranchId: ID\n  $searchTerm: String\n) {\n  ...AccessoryGroupAutocompleteListFragment_28m6IP\n}\n\nfragment AccessoryGroupAutocompleteListFragment_28m6IP on Query {\n  searchResults: searchAccessoryGroups(searchTerm: $searchTerm, accessoryTypeCode: $accessoryTypeCode, dispatchBranchId: $dispatchBranchId, after: $cursor, first: $count) {\n    edges {\n      node {\n        id\n        ...AccessoryGroupLabelResolver\n        branch {\n          name\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AccessoryGroupLabelResolver on AccessoryGroup {\n  description\n}\n"
  }
};
})();

(node as any).hash = "2603017f323a288b7f43e337c4f5e6e8";

export default node;
