import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { roundDateTimeToMinuteStep } from '../utils/dateTimeUtils';
import { ForwardedRef, forwardRef, useCallback } from 'react';

export const RoundedDateTimePicker = forwardRef(function RoundedDateTimePicker(
  { value, onChange: handleChange, minutesStep, slotProps: { textField, ...otherSlotProps } = {}, ...props }: DateTimePickerProps<DateTime>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const handleRound = useCallback(() => {
    if (!value || !minutesStep) {
      return;
    }

    const rounded = roundDateTimeToMinuteStep(value, minutesStep);
    if (!rounded.equals(value)) {
      handleChange?.(rounded, { validationError: null });
    }
  }, [value, handleChange, minutesStep]);

  return (
    <DateTimePicker<DateTime>
      {...props}
      ref={ref}
      value={value}
      onChange={handleChange}
      slotProps={{
        ...otherSlotProps,
        textField: {
          ...(textField ?? {}),
          onBlur: handleRound,
        },
      }}
    />
  );
});
