/**
 * @generated SignedSource<<0cd459561e626374ba66c145701e2aac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleListPageQuery$variables = Record<PropertyKey, never>;
export type BillingCodeRuleListPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationAdminFragment" | "LayoutsListLayoutFragment" | "ListPageRootErrorBoundaryFragment" | "SidebarDrawerFragment">;
};
export type BillingCodeRuleListPageQuery = {
  response: BillingCodeRuleListPageQuery$data;
  variables: BillingCodeRuleListPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isINotification"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRead",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permalink",
      "storageKey": null
    }
  ],
  "type": "PermalinkNotification",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingCodeRuleListPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuthorizationAdminFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SidebarDrawerFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LayoutsListLayoutFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ListPageRootErrorBoundaryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BillingCodeRuleListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Employee",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessLevels",
            "kind": "LinkedField",
            "name": "accessLevels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasAdmin",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "createdAt": "DESC"
            }
          }
        ],
        "concreteType": "NotificationsConnection",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "notifications(first:100,order:{\"createdAt\":\"DESC\"})"
      },
      {
        "alias": "unreadNotifications",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "isRead": {
                "eq": false
              }
            }
          }
        ],
        "concreteType": "NotificationsConnection",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v0/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "notifications(first:100,where:{\"isRead\":{\"eq\":false}})"
      }
    ]
  },
  "params": {
    "cacheID": "aae8fa1565e2b2b730391c507bf624b7",
    "id": null,
    "metadata": {},
    "name": "BillingCodeRuleListPageQuery",
    "operationKind": "query",
    "text": "query BillingCodeRuleListPageQuery {\n  ...AuthorizationAdminFragment\n  ...SidebarDrawerFragment\n  ...LayoutsListLayoutFragment\n  ...ListPageRootErrorBoundaryFragment\n}\n\nfragment AuthorizationAdminFragment on Query {\n  me {\n    accessLevels {\n      hasAdmin\n    }\n    id\n  }\n}\n\nfragment LayoutsListLayoutFragment on Query {\n  ...LayoutsListLayoutToolbarFragment\n}\n\nfragment LayoutsListLayoutToolbarFragment on Query {\n  ...LayoutsToolbarMainFragment\n}\n\nfragment LayoutsToolbarMainFragment on Query {\n  ...NotificationMenuFragment\n}\n\nfragment ListPageRootErrorBoundaryFragment on Query {\n  ...SidebarDrawerFragment\n  ...LayoutsListLayoutFragment\n}\n\nfragment NotificationMenuFragment on Query {\n  notifications(order: {createdAt: DESC}, first: 100) {\n    nodes {\n      __typename\n      id\n      ...NotificationRowFragment\n    }\n  }\n  unreadNotifications: notifications(where: {isRead: {eq: false}}, first: 100) {\n    nodes {\n      __typename\n      ...NotificationRowFragment\n      id\n    }\n  }\n}\n\nfragment NotificationRowFragment on INotification {\n  __isINotification: __typename\n  __typename\n  ...NotificationRow_BaseFragment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_BaseFragment on INotification {\n  __isINotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotification {\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n\nfragment SidebarDrawerFragment on Query {\n  ...AuthorizationAdminFragment\n}\n"
  }
};
})();

(node as any).hash = "9696e4e163bde628986154af73381ede";

export default node;
