/**
 * @generated SignedSource<<88a1570c5d5fdd9a761e2ac0d992921f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateFieldsSaleKindsFragment$data = {
  readonly conditions: {
    readonly saleKinds: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "AddendumTemplateFieldsSaleKindsFragment";
};
export type AddendumTemplateFieldsSaleKindsFragment$key = {
  readonly " $data"?: AddendumTemplateFieldsSaleKindsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFieldsSaleKindsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateFieldsSaleKindsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AddendumTemplateConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "saleKinds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "ed3135887d6de96e98c7a8cd9fcc5174";

export default node;
