/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const syncableEntities = [

  /**
  * Stand-alone entities
  */
  "accessoryGroup",

  /**
  * Lookups not included in DescriptorType
  */
  "accessoryType",
  "billing",

  /**
  * Depends on CraneConfiguration
  */
  "boomConfiguration",

  /**
  * Depends on BoomConfiguration
  */
  "boomConfigurationAdditional",
  "branch",

  /**
  * Depends on Representative, Requirement
  */
  "client",
  "configurationType",

  /**
  * Depends on Equipment, ConfigurationType
  */
  "craneConfiguration",

  /**
  * Depends on CraneConfiguration
  */
  "craneConfigurationFlatbed",

  /**
  * Pseudo-entity used to sync EquipmentGroup, EquipmentType, WorkKind and WorkType
  */
  "descriptorType",
  "employee",

  /**
  * Depends on Branch, DescriptorType, AccessoryType
Also include the emulated Crane entity.
  */
  "equipment",
  "representative",
  "requirement",
  "surcharge",
  "workForceType",

  /**
  * Depends on Requirement
  */
  "workSite"
] as const;
export type SyncableEntity = typeof syncableEntities[number];

export function isSyncableEntity(value: string): value is SyncableEntity {
  return syncableEntities.includes(value)
}

export function asSyncableEntity(value: string): SyncableEntity | null {
  return isSyncableEntity(value) ? value : null;
}

export function castSyncableEntity(value: string): SyncableEntity {
  if(!isSyncableEntity(value)) {
    throw new Error(`Invalid Enum value for type "SyncableEntity": "${value}"`);
  }
  
  return value;
}
