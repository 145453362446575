import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { RateStrategyLookupLabelResolver$key } from './__generated__/RateStrategyLookupLabelResolver.graphql';

/**
 * @RelayResolver RateStrategyLookup.label: Localized
 * @rootFragment RateStrategyLookupLabelResolver
 *
 * An object that contains a label for every language for RateStrategyLookup which includes description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(rateStrategy$key: RateStrategyLookupLabelResolver$key): Record<string, string> {
  const rateStrategy$data = readFragment(
    graphql`
      fragment RateStrategyLookupLabelResolver on RateStrategyLookup {
        description
      }
    `,
    rateStrategy$key,
  );
  return rateStrategy$data.description;
}
