import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  groupBySymbol,
  Queryable,
} from './PaginatedAutocomplete';
import { convertToTsQuery } from '../utils/stringUtils';
import { emptySuggestionPromptInput } from './Suggestions';
import { DispatchBranchAutocompleteQuery, SuggestionPromptInput } from './__generated__/DispatchBranchAutocompleteQuery.graphql';
import { DispatchBranchAutocompleteListFragment$data } from './__generated__/DispatchBranchAutocompleteListFragment.graphql';
import { SelectPickerContentSuggestible } from './SelectPicker';

interface DispatchBranchAutocompleteProps {
  suggestionPromptInput?: SuggestionPromptInput | null;
  suggestible?: boolean;
}

export type ForwardDispatchBranchAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<DispatchBranchAutocompleteListFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const DispatchBranchAutocomplete = forwardRef<
  HTMLInputElement,
  DispatchBranchAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<DispatchBranchAutocompleteListFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      boolean,
      boolean,
      ElementType
    >
>(function DispatchBranchAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    suggestionPromptInput,
    suggestible,
    multiple,
    ...paginatedAutocompleteProps
  }: DispatchBranchAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<DispatchBranchAutocompleteListFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const env = useRelayEnvironment();

  const handleQuery = useCallback(
    (searchTerm: string | null, suggest: boolean) =>
      fetchQuery<DispatchBranchAutocompleteQuery>(
        env,
        graphql`
          query DispatchBranchAutocompleteQuery(
            $searchTerm: String
            $isDispatchBranch: Boolean
            $suggestionPrompt: SuggestionPromptInput!
            $suggestionCount: Int!
            $suggestionScoreThreshold: Float!
            $skipSuggestion: Boolean!
          ) {
            ...DispatchBranchAutocompleteListFragment
              @arguments(
                searchTerm: $searchTerm
                isDispatchBranch: $isDispatchBranch
                suggestionPrompt: $suggestionPrompt
                suggestionCount: $suggestionCount
                suggestionScoreThreshold: $suggestionScoreThreshold
                skipSuggestion: $skipSuggestion
              )
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
          isDispatchBranch: true,
          suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
          suggestionCount: 5,
          suggestionScoreThreshold: 0,
          skipSuggestion: !suggest || !suggestible,
        },
      ),
    [env, suggestible, suggestionPromptInput],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<DispatchBranchAutocompleteListFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      groupBy={(o) => o[groupBySymbol]}
      renderListItemContent={(params) => <SelectPickerContentSuggestible {...params} />}
      {...paginatedAutocompleteProps}
      ref={ref as Ref<HTMLInputElement & Queryable>}
      fragment={graphql`
        fragment DispatchBranchAutocompleteListFragment on Query
        @refetchable(queryName: "dispatchBranchAutocomplete2ListFragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          isDispatchBranch: { type: "Boolean" }
          searchCursor: { type: "String" }
          searchCount: { type: "Int", defaultValue: 25 }
          suggestionPrompt: { type: "SuggestionPromptInput!" }
          suggestionCount: { type: "Int!" }
          suggestionScoreThreshold: { type: "Float!" }
          skipSuggestion: { type: "Boolean!" }
        ) {
          searchResults: searchBranches(
            searchTerm: $searchTerm
            isDispatchBranch: $isDispatchBranch
            after: $searchCursor
            first: $searchCount
          ) @connection(key: "dispatchBranchAutocomplete2ListFragment_searchResults") {
            edges {
              node {
                id
                label
                deletedAt
              }
            }
          }
          suggestions: suggestedDispatchBranches(
            prompt: $suggestionPrompt
            count: $suggestionCount
            scoreThreshold: $suggestionScoreThreshold
          ) @skip(if: $skipSuggestion) {
            score
            value {
              id
              label
              deletedAt
            }
          }
        }
      `}
      onQuery={handleQuery}
      multiple={multiple}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: DispatchBranchAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<DispatchBranchAutocompleteListFragment$data>,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
