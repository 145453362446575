/**
 * @generated SignedSource<<8ad1193717cb199214b9cf965bc5bf0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type PatchOperationAction = "delete" | "insert" | "skip" | "update" | "%future added value";
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
export type UpdateNatureOfWorksListInput = {
  natureOfWorks: ReadonlyArray<PatchOperationOfSaveNatureOfWorkInput | null | undefined>;
};
export type PatchOperationOfSaveNatureOfWorkInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveNatureOfWorkInput | null | undefined;
};
export type SaveNatureOfWorkInput = {
  defaultWorkSchedule: WorkScheduleEnum;
  natureOfWorkSubCategories: ReadonlyArray<NatureOfWorkSubCategoryEnum>;
  workSchedules: ReadonlyArray<WorkScheduleEnum>;
};
export type NatureOfWorkFormSaveMutation$variables = {
  data: UpdateNatureOfWorksListInput;
};
export type NatureOfWorkFormSaveMutation$data = {
  readonly updateNatureOfWorks: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    }> | null | undefined;
    readonly natureOfWorkLookups: ReadonlyArray<{
      readonly code: number;
      readonly defaultWorkSchedule: WorkScheduleEnum;
      readonly natureOfWorkSubCategories: ReadonlyArray<NatureOfWorkSubCategoryEnum>;
      readonly workSchedules: ReadonlyArray<WorkScheduleEnum>;
    }> | null | undefined;
  };
};
export type NatureOfWorkFormSaveMutation = {
  response: NatureOfWorkFormSaveMutation$data;
  variables: NatureOfWorkFormSaveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "updateNatureOfWorksList",
        "variableName": "data"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultWorkSchedule",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workSchedules",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "natureOfWorkSubCategories",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NatureOfWorkFormSaveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNatureOfWorksPayload",
        "kind": "LinkedField",
        "name": "updateNatureOfWorks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NatureOfWorkLookup",
            "kind": "LinkedField",
            "name": "natureOfWorkLookups",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NatureOfWorkFormSaveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNatureOfWorksPayload",
        "kind": "LinkedField",
        "name": "updateNatureOfWorks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NatureOfWorkLookup",
            "kind": "LinkedField",
            "name": "natureOfWorkLookups",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48db714cd9f331ebf3d9fc76d7250407",
    "id": null,
    "metadata": {},
    "name": "NatureOfWorkFormSaveMutation",
    "operationKind": "mutation",
    "text": "mutation NatureOfWorkFormSaveMutation(\n  $data: UpdateNatureOfWorksListInput!\n) {\n  updateNatureOfWorks(input: {updateNatureOfWorksList: $data}) {\n    natureOfWorkLookups {\n      code\n      defaultWorkSchedule\n      workSchedules\n      natureOfWorkSubCategories\n      id\n    }\n    errors {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4845db14d4c122a09377f4d9274082c3";

export default node;
