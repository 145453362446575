import * as Sentry from '@sentry/react';
import { Theme, useTheme } from '@mui/material';
import getMatchedMedia from './common/utils/getMatchedMedia';
import ErrorFallback from './layout/ErrorFallback';
import { EmptyLayout } from './layout/Layouts';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultLogger, Logger } from './common/utils/logging';

const logger = new Logger(defaultLogger, 'AppErrorBoundary', () => new Date().toISOString());

export const errorBoundaryBeforeCaptureFn = (scope: Sentry.Scope, theme: Theme) => {
  const media = getMatchedMedia(theme);
  scope.setTag('media', media);
};

export function AppErrorBoundary({ children }: { children: ReactNode }) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <EmptyLayout>
          <ErrorFallback
            error={error}
            subtitleMessage={t('requestSupport')}
            onButtonClick={() => window.location.assign('/')}
            buttonMessage={t('button.goHome')}
          />
        </EmptyLayout>
      )}
      beforeCapture={(scope) => errorBoundaryBeforeCaptureFn(scope, theme)}
      onError={(...args) => {
        logger.error(...args);
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
