/**
 * @generated SignedSource<<307bea62edf258ea6998320a3ab908f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageInformationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsDescriptionFragment">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageInformationFragment";
};
export type BillingCodeRuleDetailsPageInformationFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageInformationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageInformationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageInformationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsDescriptionFragment"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "b143b4bb1ccb90cc5851e33765749735";

export default node;
