import { Component } from 'react';
import { ElementNotFoundError } from '../common/exceptions/ElementNotFoundError';
import { useTranslation } from 'react-i18next';
import { DetailsLayout, SidebarContentProps } from './Layouts';
import { SidebarDrawerFragment$key } from './__generated__/SidebarDrawerFragment.graphql';
import { NavigationMenu } from './SidebarDrawer';
import ErrorFallback from './ErrorFallback';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { LayoutsDetailsLayoutFragment$key } from './__generated__/LayoutsDetailsLayoutFragment.graphql';
import { toCamelCase } from '../common/utils/stringUtils';

export class ElementNotFoundErrorBoundary extends Component<
  {
    sidebar$key: SidebarDrawerFragment$key | null | undefined;
    detailsLayout$key: LayoutsDetailsLayoutFragment$key | null | undefined;
    heading: string;
    children?: JSX.Element;
  },
  { hasError: boolean; error?: Error }
> {
  constructor(props: {
    sidebar$key: SidebarDrawerFragment$key | null | undefined;
    detailsLayout$key: LayoutsDetailsLayoutFragment$key | null | undefined;
    heading: string;
    children?: JSX.Element;
  }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    if (error instanceof ElementNotFoundError) {
      Sentry.captureException(error, { tags: { entityType: error.entity }, extra: { entityId: error.id }, level: 'warning' });
    } else {
      throw error;
    }
  }

  render() {
    const { hasError, error } = this.state;
    const { sidebar$key, detailsLayout$key, heading } = this.props;
    const sidebar = (props: SidebarContentProps) => <NavigationMenu {...props} $key={sidebar$key} />;

    if (hasError) {
      if (error instanceof ElementNotFoundError) {
        return (
          <DetailsLayout heading={heading} sidebarProvider={sidebar} $key={detailsLayout$key}>
            <ElementNotFoundErrorFallback error={error} resetError={() => this.setState({ hasError: false })} />
          </DetailsLayout>
        );
      }

      throw error;
    }
    const { children } = this.props;
    return children;
  }
}

function ElementNotFoundErrorFallback({ error, resetError }: { error: ElementNotFoundError; resetError: () => void }) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <ErrorFallback
      error={error}
      titleMessage={t('errorMessages.elementNotFound.title')}
      subtitleMessage={t('errorMessages.elementNotFound.body', { element: toCamelCase(error.entity) })}
      buttonMessage={t('button.goBack')}
      onButtonClick={() => {
        resetError();
        navigate(error.previousPath);
      }}
    />
  );
}
