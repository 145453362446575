import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { capitalize } from '../utils/stringUtils';
import { defaultLogger, Logger } from '../utils/logging';

const silentUpdateStallingTimeout = 300;
const hardUpdateCheckTimeout = 5 * 1000;
const interactiveUpdateInterval = 3 * 60 * 1000;

const logger = new Logger(defaultLogger, 'AppUpdate', () => new Date().toISOString());

const serviceWorker = (function () {
  function isSupported(): boolean {
    return 'serviceWorker' in navigator;
  }
  function isInstalled(): boolean {
    return isSupported() && !!navigator.serviceWorker.controller;
  }
  function getRequiredServiceWorker() {
    if (!isSupported()) {
      throw new Error('Unsupported operation. This browser does not support service workers.');
    }

    return navigator.serviceWorker;
  }
  function getController() {
    const controller = getRequiredServiceWorker().controller;
    if (!controller) {
      throw new Error('Unsupported operation. A ServiceWorkerController is required.');
    }

    return controller;
  }
  async function getRegistrations() {
    const registrations = await getRequiredServiceWorker().getRegistrations();
    if (!registrations.length) {
      logger.warn('Empty ServiceWorker registrations.');
    }

    return registrations;
  }

  return {
    isInstalled,
    getController,
    getRegistrations,
  };
})();

type AppUpdateEvent = CustomEvent<{ state: 'updating' | 'updated' }>;

/**
 * Exposes the necessary mechanisms for detecting updates with a serviceworker managed by Google Workbox.
 */
export const workboxIntegration = (function () {
  /**
   * Stores the last dispatched state so that it can be replayed if the event is missed.
   */
  let lastUpdateState: AppUpdateEvent['detail']['state'] | null = null;

  const appUpdateEventType = 'app_serviceworker';
  function createAppUpdateEvent(state: AppUpdateEvent['detail']['state']) {
    return new CustomEvent(appUpdateEventType, { detail: { state } });
  }
  function isAppUpdateEvent(event: Event | AppUpdateEvent): event is AppUpdateEvent {
    return (event.type === appUpdateEventType && 'detail' in event && 'state' in event.detail) || false;
  }

  function onServiceWorkerRedundant(listener: () => void) {
    const oldServiceWorker = serviceWorker.getController();

    const redundantListener = () => {
      if (oldServiceWorker.state === 'redundant') {
        oldServiceWorker.removeEventListener('statechange', redundantListener);

        listener();
      }
    };
    oldServiceWorker.addEventListener('statechange', redundantListener);
  }

  return {
    handleUpdate() {
      logger.info('Update detected through service worker content mismatch.');

      lastUpdateState = 'updating';
      window.dispatchEvent(createAppUpdateEvent(lastUpdateState));

      // The old service worker will be marked as redundant once the update process is complete.
      onServiceWorkerRedundant(() => {
        lastUpdateState = 'updated';
        window.dispatchEvent(createAppUpdateEvent(lastUpdateState));
      });
    },
    onAppUpdateEvent(listener: (event: AppUpdateEvent) => void) {
      const filteredListener = (e: Event) => {
        if (!isAppUpdateEvent(e)) {
          logger.error('Received an invalid event while listening to', appUpdateEventType);
          return;
        }

        // Eat the last update state when processing events. Prevents state transitions from re-triggering.
        lastUpdateState = null;
        listener(e);
      };

      window.addEventListener(appUpdateEventType, filteredListener);

      // Catch up to missed update states.
      if (lastUpdateState === 'updating') {
        logger.log('Update requested during startup phase');
        filteredListener(createAppUpdateEvent('updating'));
      }
      if (lastUpdateState === 'updated') {
        logger.log('Update installed during startup phase');
        filteredListener(createAppUpdateEvent('updated'));
      }

      return () => window.removeEventListener(appUpdateEventType, filteredListener);
    },
  };
})();

export type UpdateStage = 'stalling' | 'requested' | 'silent' | 'updated' | 'idle';
function updateStageInitializer(): UpdateStage {
  if (!serviceWorker.isInstalled()) {
    logger.warn('No service worker detected. Update checks will not be performed.');
    return 'idle';
  }

  return 'stalling';
}
function getTransitionToStage(stage: UpdateStage) {
  return (old: UpdateStage) => {
    switch (`${old} => ${stage}` as `${UpdateStage} => ${UpdateStage}`) {
      case 'stalling => requested':
        return 'silent';
      // Cannot undo update reception
      case 'silent => stalling':
      case 'requested => idle':
      case 'requested => silent':
      case 'requested => stalling':
        return old;
      // Must stay on the update page even if we get a new update
      case 'updated => idle':
      case 'updated => silent':
      case 'updated => stalling':
      case 'updated => requested':
        return old;
      default:
        return stage;
    }
  };
}

type Transitions = {
  toRequested: () => void;
  toUpdated: () => void;
  toIdle: () => void;
  from: (fn: (stage: UpdateStage) => UpdateStage) => void;
};
function useStateMachine() {
  const [updateStage, setUpdateStage] = useState<UpdateStage>(updateStageInitializer);

  const toRequested = useCallback(() => setUpdateStage(getTransitionToStage('requested')), []);
  const toUpdated = useCallback(() => setUpdateStage(getTransitionToStage('updated')), []);
  const toIdle = useCallback(() => setUpdateStage(getTransitionToStage('idle')), []);
  const from = useCallback((fn: (stage: UpdateStage) => UpdateStage) => setUpdateStage((s) => getTransitionToStage(fn(s))(s)), []);

  const transitions: Transitions = useMemo(
    () => ({
      toRequested,
      toUpdated,
      toIdle,
      from,
    }),
    [toRequested, toUpdated, toIdle, from],
  );

  return [updateStage, transitions] as const;
}

export type UpdateMode = 'hard' | 'soft';

/**
 * A function that trigger's a check for updates.
 */
type UpdateCheckStrategy = (transitions: Transitions) => Promise<void>;
const getUpdateCheckStrategy = (function () {
  /**
   * Trigger a hard update check.
   * Manually check for updates using the APP_VERSION with a no-cache query and unregister the serviceworker.
   * This forces a complete app uninstallation, and will reinstall from network on the next reload.
   *
   * transitions.toRequested() is explicit.
   * @param transitions
   */
  const hardUpdateCheckStrategy: UpdateCheckStrategy = (transitions) =>
    fetch(process.env.PUBLIC_URL + '/config.js', { signal: AbortSignal.timeout(hardUpdateCheckTimeout) })
      .then((response) => response.text())
      .then((textResponse) => {
        const regEx = /["']?APP_VERSION["']?:( )*["'](.+)["']/;
        const app_version = regEx[Symbol.match](textResponse);

        // Read app version straight from the environment variables instead of the config module.
        // This enables an easy bypass by manually setting env.APP_VERSION in the devtools.
        const currentVersion = (window as unknown as Window & { env: Record<string, string> }).env?.APP_VERSION;
        const detectedVersion = app_version?.[2];

        if (detectedVersion == null) {
          return Promise.reject(new Error('[AppUpdate] Failed to read APP_VERSION from config file.'));
        }

        return currentVersion !== detectedVersion;
      })
      .catch((err) => {
        if (err instanceof DOMException && err.message === 'AbortError') {
          logger.warn('Hard update timeout after', hardUpdateCheckTimeout);
          return false;
        }

        throw err;
      })
      .then(async (updateNeeded) => {
        if (updateNeeded) {
          logger.info('Update detected through config file version mismatch.');
          transitions.toRequested();
        } else {
          transitions.toIdle();
        }
      });

  /**
   * Triggers a soft update check.
   * Browsers will bypass cache if it's older than 24hrs, else no update will be detected.
   *
   * transitions.toRequested() is normally handled via the 'window.app_serviceworker[state=updated]' event.
   * transitions.toRequested() is explicit when catching up to pending updates.
   */
  const softUpdateCheckStrategy: UpdateCheckStrategy = async (transitions) => {
    const registrations = await serviceWorker.getRegistrations();
    const waitingServiceWorkers = registrations.map((r) => r.waiting).filter((r): r is ServiceWorker => !!r);

    if (waitingServiceWorkers.length > 0) {
      logger.info('Update detected through waiting service workers.');
      transitions.toRequested();
      return;
    }

    for (const registration of registrations) {
      registration.update();
    }
  };

  return function (updateMode: UpdateMode): UpdateCheckStrategy {
    switch (updateMode) {
      case 'hard':
        return hardUpdateCheckStrategy;
      case 'soft':
        return softUpdateCheckStrategy;
    }
  };
})();

/**
 * A function that flags an update as ready to use.
 */
type UpdateConfirmationStrategy = (transitions: Transitions) => Promise<void>;
const getUpdateConfirmationStrategy = (function () {
  /**
   * Force a complete reset of all service workers.
   * The page should be reloaded as soon as possible to reinstall the service workers.
   *
   * transitions.toUpdated() is explicit.
   * @param transitions
   */
  const hardUpdateConfirmationStrategy: UpdateConfirmationStrategy = async (transitions) => {
    const registrations = await serviceWorker.getRegistrations();

    for (const registration of registrations) {
      await registration.unregister();
    }

    transitions.toUpdated();
  };

  /**
   * Skip waiting on all waiting service workers.
   *
   * transitions.toUpdated() is handled via the 'controller.statechange[state=redundant]' event.
   */
  const softUpdateConfirmationStrategy: UpdateConfirmationStrategy = async () => {
    const registrations = await serviceWorker.getRegistrations();
    const waitingServiceWorkers = registrations.map((r) => r.waiting).filter((r): r is ServiceWorker => !!r);

    for (const sw of waitingServiceWorkers) {
      sw.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  return function (updateMode: UpdateMode): UpdateConfirmationStrategy {
    switch (updateMode) {
      case 'hard':
        return hardUpdateConfirmationStrategy;
      case 'soft':
        return softUpdateConfirmationStrategy;
    }
  };
})();

export type UseAppUpdateEvents = Partial<Record<`on${Capitalize<UpdateStage>}`, () => void>>;
export function useAppUpdate(events: UseAppUpdateEvents, updateMode: UpdateMode): [UpdateStage, () => void] {
  const [updateStage, transitions] = useStateMachine();

  // Trigger events on state changes
  useEffect(() => {
    logger.log('Transitioned to', updateStage);
    events[`on${capitalize(updateStage)}`]?.();
  }, [events, updateStage]);

  // Converts Service Worker events into state machine transitions.
  useEffect(() => {
    if (!serviceWorker.isInstalled()) {
      return;
    }

    return workboxIntegration.onAppUpdateEvent((event: AppUpdateEvent) => {
      switch (event.detail.state) {
        case 'updating':
          return transitions.toRequested();
        case 'updated':
          return transitions.toUpdated();
        default:
          return logger.error('Unsupported Operation. Unexpected state', event.detail.state);
      }
    });
  }, [transitions]);

  const handleFailure = useCallback(
    (err: Error) => {
      Sentry.captureException(err);
      logger.error(err);

      // Go back to a neutral state.
      transitions.toIdle();
    },
    [transitions],
  );

  // State machine triggers
  useEffect(() => {
    // Setup a deadline for the initial update process and a safety in case we stall without any service worker.
    setTimeout(() => {
      transitions.from((s) => {
        if (s === 'idle') {
          return s;
        }

        logger.info('Stalling deadline reached');
        return 'idle';
      });
    }, silentUpdateStallingTimeout);

    if (!serviceWorker.isInstalled()) {
      return;
    }

    const strategy = getUpdateCheckStrategy(updateMode);

    const trigger = () => {
      logger.log('Checking for update with mode', updateMode);

      // transitions.toRequested() varies depending on the strategy.
      return strategy(transitions).catch(handleFailure);
    };

    trigger();
    const interval = setInterval(trigger, interactiveUpdateInterval);
    return () => clearInterval(interval);
  }, [updateMode, transitions, handleFailure]);

  // External actions
  const confirmUpdate = useCallback(async () => {
    const strategy = getUpdateConfirmationStrategy(updateMode);

    logger.log('Confirming update with mode', updateMode);

    // transitions.toUpdated() varies depending on the strategy.
    return strategy(transitions).catch(handleFailure);
  }, [updateMode, transitions, handleFailure]);

  // Automatically confirm silent updates
  useEffect(() => {
    if (updateStage === 'silent') {
      logger.info('Silently confirming update');
      confirmUpdate();
    }
  }, [updateStage, confirmUpdate]);

  return [updateStage, confirmUpdate];
}
