import { Alert, AlertProps, AlertTitle, Collapse, IconButton, useTheme } from '@mui/material';
import { ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type Props = {
  title?: string;
  actions?: ReactNode[];
  collapsedSize?: string | number;
} & Omit<AlertProps, 'action'>;
export function AlertBanner({ title, actions, collapsedSize, ...alertProps }: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleCollapseToggle = useCallback(() => setOpen((prev) => !prev), []);

  const handleClose = useCallback((e: SyntheticEvent) => alertProps.onClose?.(e), [alertProps]);

  return (
    <Alert
      {...alertProps}
      action={
        <>
          <IconButton onClick={handleCollapseToggle} color='inherit'>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {alertProps.onClose && (
            <IconButton onClick={handleClose} color='inherit' {...alertProps.componentsProps?.closeButton}>
              <CloseIcon {...alertProps.componentsProps?.closeIcon} />
            </IconButton>
          )}
          {actions?.map((a) => a)}
        </>
      }>
      {title && (
        <AlertTitle
          sx={{
            [theme.breakpoints.down('sm')]: { fontSize: '0.9rem' },
          }}>
          {title}
        </AlertTitle>
      )}
      <Collapse
        orientation='vertical'
        in={open}
        collapsedSize={collapsedSize ?? '1lh'}
        sx={{
          '& .MuiCollapse-wrapperInner': {
            whiteSpace: 'pre-wrap',
          },
          ...(open
            ? {}
            : {
                mask: 'linear-gradient(to bottom, rgba(0,0,0,1) calc(100% - 1lh), rgba(0,0,0,0)) 0 0 / 100% 100% no-repeat',
              }),
        }}>
        {alertProps.children}
      </Collapse>
    </Alert>
  );
}
