import { createFieldKey, createFormContext, SetValueFn, useField } from '../common/utils/forms';
import { ForwardCostBillingCodeAutocompleteProps } from '../common/components/CostBillingCodeAutocomplete';
import { DataID, useLazyLoadQuery } from 'react-relay';
import { SelectPicker } from '../common/components/SelectPicker';
import { ForwardWorkForceTypeAutocomplete, WorkForceTypeAutocomplete } from '../common/components/WorkForceTypeAutocomplete';
import graphql from 'babel-plugin-relay/macro';
import { BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery } from './__generated__/BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery.graphql';
import { Suspense } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useTranslation } from 'react-i18next';

export type BillingCodeRulesAutomaticRow = {
  id: DataID;
  billingCode: FieldBillingCodesAutomatic_BillingCodeInputType | undefined;
  workForceType: FieldBillingCodesAutomatic_WorkForceTypeInputType | undefined | null;
};
export const billingCodeRuleAutomatic_SubFormContext = createFormContext<{ sync: BillingCodeRulesAutomaticRow }>();

type FieldBillingCodesAutomatic_IdInputType = DataID;
const fieldBillingCodesAutomatic_IdKey = createFieldKey<FieldBillingCodesAutomatic_IdInputType>();

export function useFieldBillingCodesAutomatic_Id(initialValue: FieldBillingCodesAutomatic_IdInputType) {
  const [billingCodesAutomatic_Id, setBillingCodesAutomatic_Id] = useField(
    billingCodeRuleAutomatic_SubFormContext,
    fieldBillingCodesAutomatic_IdKey,
    initialValue,
  );

  // Note: I Skipped the mappers since there's only 2 fields and I set the value on change

  return { billingCodesAutomatic_Id, setBillingCodesAutomatic_Id };
}

export type FieldBillingCodesAutomatic_BillingCodeInputType = ForwardCostBillingCodeAutocompleteProps<false, true>['value'];
const fieldBillingCodesAutomatic_BillingCodeKey = createFieldKey<FieldBillingCodesAutomatic_BillingCodeInputType>();

export function useFieldBillingCodesAutomatic_BillingCode(initialValue: FieldBillingCodesAutomatic_BillingCodeInputType) {
  const [billingCodesAutomatic_BillingCode, setBillingCodesAutomatic_BillingCode] = useField(
    billingCodeRuleAutomatic_SubFormContext,
    fieldBillingCodesAutomatic_BillingCodeKey,
    initialValue,
  );

  // Note: I Skipped the mappers since there's only 2 fields and I set the value on change

  return { billingCodesAutomatic_BillingCode, setBillingCodesAutomatic_BillingCode };
}

export function BillingCodesAutomatic_BillingCodeInput({
  billingCodesVisible,
  value,
  setValue,
  onChange,
}: {
  billingCodesVisible: ForwardCostBillingCodeAutocompleteProps<true>['value'];
  value: FieldBillingCodesAutomatic_BillingCodeInputType;
  setValue: SetValueFn<FieldBillingCodesAutomatic_BillingCodeInputType>;
  onChange?: (value: FieldBillingCodesAutomatic_BillingCodeInputType) => void;
}) {
  const { t } = useAmbientTranslation();

  return (
    <SelectPicker
      value={value}
      disableClearable
      options={billingCodesVisible ?? []}
      onChange={(_, newValue) => {
        setValue(newValue);
        onChange?.(newValue);
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionKey={(o) => o.id}
      textFieldProps={() => ({
        placeholder: t('button.select', { ns: 'common' }),
        className: 'borderless',
      })}
    />
  );
}

export type FieldBillingCodesAutomatic_WorkForceTypeInputType = ForwardWorkForceTypeAutocomplete['value'];
const fieldBillingCodesAutomatic_WorkForceTypeKey = createFieldKey<FieldBillingCodesAutomatic_WorkForceTypeInputType>();

export function useFieldBillingCodesAutomatic_WorkForceType(initialValue: FieldBillingCodesAutomatic_WorkForceTypeInputType) {
  const [billingCodesAutomatic_WorkForceType, setBillingCodesAutomatic_WorkForceType] = useField(
    billingCodeRuleAutomatic_SubFormContext,
    fieldBillingCodesAutomatic_WorkForceTypeKey,
    initialValue ?? null,
  );

  // Note: I Skipped the mappers since there's only 2 fields and I set the value on change

  return {
    billingCodesAutomatic_WorkForceType,
    setBillingCodesAutomatic_WorkForceType,
  };
}

export function BillingCodesAutomatic_WorkForceTypeInput({
  billingCodeId,
  ...rest
}: {
  billingCodeId: DataID | null | undefined;
  value: FieldBillingCodesAutomatic_WorkForceTypeInputType;
  setValue: SetValueFn<FieldBillingCodesAutomatic_WorkForceTypeInputType>;
  onChange?: (value: FieldBillingCodesAutomatic_WorkForceTypeInputType) => void;
}) {
  if (billingCodeId) {
    return (
      <Suspense fallback={<BillingCodesAutomatic_WorkForceTypeInput_Content {...rest} disabled={true} loading={true} />}>
        <BillingCodesAutomatic_WorkForceTypeInput_Queried {...rest} billingCodeId={billingCodeId} />
      </Suspense>
    );
  }

  return <BillingCodesAutomatic_WorkForceTypeInput_Content {...rest} disabled={true} />;
}

function BillingCodesAutomatic_WorkForceTypeInput_Queried({
  billingCodeId,
  ...rest
}: {
  billingCodeId: DataID;
  value: FieldBillingCodesAutomatic_WorkForceTypeInputType;
  setValue: SetValueFn<FieldBillingCodesAutomatic_WorkForceTypeInputType>;
  onChange?: (value: FieldBillingCodesAutomatic_WorkForceTypeInputType) => void;
}) {
  const $data = useLazyLoadQuery<BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery>(
    graphql`
      query BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery($billingCodeId: ID!) {
        node(id: $billingCodeId) {
          ... on BillingCode {
            billingCodeCategory
          }
        }
      }
    `,
    { billingCodeId },
  );

  return <BillingCodesAutomatic_WorkForceTypeInput_Content {...rest} disabled={$data.node?.billingCodeCategory !== 'labor'} />;
}

function BillingCodesAutomatic_WorkForceTypeInput_Content({
  value,
  setValue,
  disabled,
  loading = false,
  onChange,
}: {
  value: FieldBillingCodesAutomatic_WorkForceTypeInputType;
  setValue: SetValueFn<FieldBillingCodesAutomatic_WorkForceTypeInputType>;
  disabled: boolean;
  loading?: boolean;
  onChange?: (value: FieldBillingCodesAutomatic_WorkForceTypeInputType) => void;
}) {
  const { t } = useTranslation('common');

  return (
    <WorkForceTypeAutocomplete
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
        onChange?.(newValue);
      }}
      textFieldProps={() => ({ className: 'borderless', placeholder: !disabled ? t('button.select') : undefined })}
      disabled={disabled || loading}
    />
  );
}
