import { pairwise } from './arrayUtils';

export const clamp = (val: number, min: number, max: number): number => Math.max(min, Math.min(max, val));

export function parseOrNull<T extends number>(val: string | null | undefined, parseFn: (v: string) => T): number | null {
  let retVal = val !== undefined && val !== null ? parseFn(val) : null;
  if (retVal != null && isNaN(retVal)) {
    retVal = null;
  }

  return retVal;
}

export interface IsDecimalNumberReturn {
  // The integer value of the number that was parsed - note no rounding was applied.
  intValue?: number;

  // True if the number was considered a decimal (AKA not an integer)
  isDecimal: boolean;
}

/**
 * Checks if the number is a decimal value, and returns an instance of @see IsDecimalNumberReturn
 * @param val The value (string, number or null) to parse
 */
export function isDecimalNumber(val: string | number | null): IsDecimalNumberReturn {
  if (!val) return { isDecimal: false };

  // Check if the parsed value as float is an integer
  const floatValue = typeof val === 'string' ? parseFloat(val.replaceAll(',', '.')) : val;
  if (Number.isInteger(floatValue) && !Number.isNaN(floatValue)) {
    // Special case where the parsed float is an integer higher than the MAX_SAFE_INTERGER
    if (floatValue > Number.MAX_SAFE_INTEGER) return { isDecimal: false, intValue: Number.MAX_SAFE_INTEGER };

    // Return the parsed value (it IS an integer)
    return { isDecimal: false, intValue: floatValue };
  }

  if (Number.isNaN(floatValue)) {
    return {
      intValue: 0,
      isDecimal: false,
    };
  }

  // The float value is a float higher than the MAX_SAFE_INTEGER, simply return MAX_SAFE_INTEGER
  if (floatValue > Number.MAX_SAFE_INTEGER) {
    return {
      intValue: Number.MAX_SAFE_INTEGER,
      isDecimal: true,
    };
  }

  // Return the integer part of the string / value (NO ROUNDING)
  return {
    intValue: parseInt(Math.floor(floatValue).toFixed(0)),
    isDecimal: true,
  };
}

export type NumberFormatDescriptor = {
  group: string;
  groupSize: number;
  decimal: string;
  numeral: string;
  index: Map<string, number>;
};

const ALL_PUNCTUATION = /(\p{P})/u;
const All_DECIMAL_SEPARATORS = /[,.\u066B\u2396]/u;
const ALL_NON_NUMERALS = /\P{N}/gu;

/**
 * Format and parse numbers in a given locale.
 *
 * Adapted to TS and improved from: https://observablehq.com/@mbostock/localized-number-parsing#NumberParser
 */
export class LocalizedNumberFormat {
  private readonly _format: NumberFormatDescriptor;
  private readonly _group: RegExp;
  private readonly _decimal: RegExp;
  private readonly _numeral: RegExp;
  private readonly _index: (d: string) => string;

  /**
   * Returns a bare-bone descriptor configured for machine-readable numbers.
   */
  static getUnspecifiedDescriptor(): NumberFormatDescriptor {
    return {
      ...LocalizedNumberFormat.getDescriptor('en-US'),
      // Disable grouping
      group: '',
      groupSize: Number.MAX_SAFE_INTEGER,
      // Standardize to dot notation
      decimal: '.',
    };
  }

  /**
   * Returns a descriptor configured for the provided locale.
   * @param locale
   */
  static getDescriptor(locale: string): NumberFormatDescriptor {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));

    const group = parts.find((d) => d.type === 'group')?.value ?? '';
    const groupSize = group ? parts[parts.findIndex((d) => d.type === 'group') + 1].value.length : 0;
    const decimal = parts.find((d) => d.type === 'decimal')?.value ?? '';
    const numeral = numerals.join('');

    return { group, groupSize, decimal, numeral, index };
  }

  constructor(format: NumberFormatDescriptor) {
    this._format = format;
    this._group = new RegExp(`[${this._format.group}]`, 'g');
    this._decimal = new RegExp(`[${this._format.decimal}]`);
    this._numeral = new RegExp(`[${this._format.numeral}]`, 'g');
    this._index = (d) => {
      const idx = this._format.index.get(d);
      return idx == null ? '' : `${idx}`;
    };
  }

  /**
   * Parse a number string that strictly matches the configured format descriptor into a number.
   * @param string
   */
  parse(string: string): number {
    const parsed = string.trim().replace(this._group, '').replace(this._decimal, '.').replace(this._numeral, this._index);
    return parsed ? +parsed : NaN;
  }

  /**
   * Parse a number string that loosely matches the configured format descriptor (ignores all non numerals and finds the
   * first decimal separator of any locale) and then reformat the number to match the configured format descriptor and
   * given decimal portion size.
   *
   * TODO: Add support for signed values.
   * @param string
   * @param decimalSize
   */
  reformatFixedPoint(string: string, decimalSize: number): string {
    const tokenPairs = pairwise(string.split(ALL_PUNCTUATION));
    if (tokenPairs.length === 0 || (tokenPairs[0][0] === '' && tokenPairs[0][1] === undefined)) {
      return '';
    }

    return this.formatFixedPoint_FormatParts(...this.formatFixedPoint_ParseParts(tokenPairs), decimalSize);
  }

  private formatFixedPoint_FormatParts(integer: string, decimal: string, decimalSize: number): string {
    if (integer === '') {
      return `0${this.formatFixedPoint_FormatDecimalPart(decimal, decimalSize)}`;
    }

    return `${this.formatFixedPoint_FormatIntegerPart(integer)}${this.formatFixedPoint_FormatDecimalPart(decimal, decimalSize)}`;
  }

  private formatFixedPoint_FormatIntegerPart(value: string): string {
    return (
      value
        // Split to chars
        .split('')
        // Group in groupSize with group string
        .reduceRight((acc, cur, index, array) => {
          const indexRight = array.length - index;
          if (indexRight % this._format.groupSize === 0 && index > 0) {
            return `${this._format.group}${cur}${acc}`;
          }

          return `${cur}${acc}`;
        }, '')
    );
  }

  private formatFixedPoint_FormatDecimalPart(value: string, decimalSize: number): string {
    if (decimalSize === 0) {
      return '';
    }

    const candidate = this.formatFixedPoint_RestrictToken(value);
    const fraction = candidate === '' ? '0'.repeat(decimalSize) : `${candidate}`.padEnd(decimalSize, '0').slice(0, decimalSize);
    return `${this._format.decimal}${fraction}`;
  }

  private formatFixedPoint_ParseParts(tokenPairs: [string, string | undefined][]): readonly [string, string] {
    let integerValue = '';
    let foundDecimalSeparator = false;

    // TODO: Apply index to map numerals to locale
    for (const [token, punctuation] of tokenPairs) {
      if (foundDecimalSeparator) {
        return [integerValue, this.formatFixedPoint_RestrictToken(token)];
      }

      integerValue += this.formatFixedPoint_RestrictToken(token);

      if (punctuation?.match(All_DECIMAL_SEPARATORS)) {
        foundDecimalSeparator = true;
      }
    }
    return [integerValue, ''];
  }

  private formatFixedPoint_RestrictToken(token: string): string {
    return token.replaceAll(ALL_NON_NUMERALS, '');
  }
}
