import { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataID, useFragment } from 'react-relay';
import { nanoid } from 'nanoid';
import { IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { useFormContext } from '../common/utils/formUtils';
import { FormGrid, FormTextFieldCell, useFormGridElementFactory } from '../common/components/FormGrid';
import { ResponsiveGridColumnDefinition, ResponsiveGridColumnOrderer } from '../common/components/ResponsiveGrid';

import graphql from 'babel-plugin-relay/macro';
import { CompetitorsListFragment$key } from './__generated__/CompetitorsListFragment.graphql';

const competitorsListFragment = graphql`
  fragment CompetitorsListFragment on Query {
    competitors(order: { name: ASC }) {
      id
      name
    }
  }
`;

export type CompetitorsFormValues = {
  competitors: CompetitorFieldValues[];
  deletedCompetitors: CompetitorFieldValues[];
};

export type CompetitorFieldValues = {
  name: string;
  id: string;
  dataId: DataID;
};

export function CompetitorsList({ fragmentKey }: { fragmentKey: CompetitorsListFragment$key }) {
  const { t } = useTranslation('configuration');

  const { reset, getValues, control, useFieldArray } = useFormContext<CompetitorsFormValues>();
  const { append, remove } = useFieldArray({ control, name: 'competitors' });
  const { append: appendDeleted } = useFieldArray({ control, name: 'deletedCompetitors' });

  const competitors = useFragment(competitorsListFragment, fragmentKey);

  useEffect(() => {
    if (competitors && competitors.competitors) {
      reset({
        competitors: competitors.competitors
          .map((c) => ({
            ...c,
            dataId: c.id,
          }))
          .concat({ name: '', id: nanoid(), dataId: 'new' }),
        deletedCompetitors: [],
      });
    }
  }, [competitors, competitors.competitors, reset]);

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'name', label: t('competitors.name'), size: 'minmax(6rem, 1.5fr)', sx: { fontWeight: 'bold' } },
    { id: 'actions', label: '', size: 'minmax(6rem, auto)' },
  ];

  const handleDeleteClick = useCallback(
    (index: number) => {
      const values = getValues('competitors');
      if (index < 0 || index > values.length) return;

      const node = values[index];
      appendDeleted(node);
      remove(index);
    },
    [appendDeleted, getValues, remove],
  );

  const handleChange = useCallback(
    (index: number) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value;

      if (value && index === getValues('competitors').length - 1) {
        append({
          id: nanoid(),
          dataId: 'new',
          name: '',
        });
      }
    },
    [append, getValues],
  );

  const rowElementFactory = useFormGridElementFactory(getValues('competitors'), (node, orderByColumns, index) => (
    <CompetitorsRow
      isLastRow={getValues('competitors').length - 1 === index}
      onChange={handleChange}
      onDelete={handleDeleteClick}
      index={index}
      orderByColumns={orderByColumns}
    />
  ));

  return (
    <FormGrid<CompetitorFieldValues>
      columnDefinitions={columns}
      data={getValues('competitors')}
      listElementFactory={rowElementFactory}
      rowElementFactory={rowElementFactory}
    />
  );
}

function CompetitorsRow({
  index,
  orderByColumns,
  onDelete: handleDelete,
  onChange: handleChange,
  isLastRow,
}: {
  index: number;
  isLastRow: boolean;
  orderByColumns: ResponsiveGridColumnOrderer;
  onDelete: (index: number) => void;
  onChange: (index: number) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}) {
  const { t } = useTranslation('configuration');

  return (
    <>
      {orderByColumns([
        <FormTextFieldCell
          onChange={handleChange(index)}
          key='name'
          type='string'
          name={`competitors.${index}.name`}
          inputProps={{ min: 1 }}
          placeholder={t('competitors.placeholder')}
          borderless
        />,
        <Stack key='actions'>
          {!isLastRow && (
            <IconButton onClick={() => handleDelete(index)} color='secondary' sx={{ opacity: 'var(--hover-highlight)' }}>
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>,
      ])}
    </>
  );
}
