import { Chip, ChipProps } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import { CheckCircle, CheckCircleOutline, CloudUpload } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { BlobStatus } from '../../__enums__/BlobStatus';

interface Props {
  status: BlobStatus;
}

type ChipIconAndColor = Pick<ChipProps, 'icon' | 'color'>;

const getChipIconAndColor = (status: BlobStatus): ChipIconAndColor => {
  switch (status) {
    case 'error':
      return {
        icon: <CancelIcon fontSize='inherit' />,
        color: 'cancel',
      };
    case 'processing':
      return {
        icon: <SettingsIcon fontSize='inherit' />,
        color: 'draft',
      };
    case 'received':
      return {
        icon: <CheckCircleOutline fontSize='inherit' />,
        color: 'advanced',
      };
    case 'success':
      return {
        icon: <CheckCircle fontSize='inherit' />,
        color: 'done',
      };
    case 'uploading':
      return {
        icon: <CloudUpload fontSize='inherit' />,
        color: 'pushback',
      };
    default:
      return {
        icon: undefined,
        color: 'default',
      };
  }
};

export function FileUploadStatusChip({ status }: Props) {
  const { t } = useTranslation('common');
  return <Chip key={status} {...getChipIconAndColor(status)} label={t(`fileUpload.status.${status}`)} size='small' />;
}
