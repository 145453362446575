import graphql from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import { ConfigurationKindLookupLabelResolver$key } from './__generated__/ConfigurationKindLookupLabelResolver.graphql';

/**
 * @RelayResolver ConfigurationKindLookup.label: Localized
 * @rootFragment ConfigurationKindLookupLabelResolver
 *
 * An object that contains the label for every language for ConfigurationKindLookup which includes description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(configurationKindLookup$key: ConfigurationKindLookupLabelResolver$key): Record<string, string> {
  const configurationKindLookup$data = readFragment(
    graphql`
      fragment ConfigurationKindLookupLabelResolver on ConfigurationKindLookup {
        description
      }
    `,
    configurationKindLookup$key,
  );

  return configurationKindLookup$data.description;
}
