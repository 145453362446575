/**
 * @generated SignedSource<<0376bd323d0f00d03f56c8012c221e85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as billingCodeLabelResolverType } from "../../__resolvers__/BillingCodeLabelResolver";
import { label as workForceTypeLookupLabelResolverType } from "../../__resolvers__/WorkForceTypeLabelResolver";
export type BillingCodeRuleFields_BillingCodeCollectionFragment$data = {
  readonly effects: {
    readonly billingCodes: {
      readonly automatic: ReadonlyArray<{
        readonly billingCode: {
          readonly code: number;
          readonly id: string;
          readonly label: Record<string, string> | null | undefined;
        } | null | undefined;
        readonly workForceType: {
          readonly code: number;
          readonly id: string;
          readonly label: Record<string, string> | null | undefined;
        } | null | undefined;
      }>;
    };
  };
  readonly " $fragmentType": "BillingCodeRuleFields_BillingCodeCollectionFragment";
};
export type BillingCodeRuleFields_BillingCodeCollectionFragment$key = {
  readonly " $data"?: BillingCodeRuleFields_BillingCodeCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFields_BillingCodeCollectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFields_BillingCodeCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EffectsInternal",
      "kind": "LinkedField",
      "name": "effects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingCodeEffects",
          "kind": "LinkedField",
          "name": "billingCodes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomaticBillingCodeEffectResponse",
              "kind": "LinkedField",
              "name": "automatic",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BillingCode",
                  "kind": "LinkedField",
                  "name": "billingCode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "fragment": {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "BillingCodeLabelResolver"
                      },
                      "kind": "RelayResolver",
                      "name": "label",
                      "resolverModule": require('./../../__resolvers__/BillingCodeLabelResolver').label,
                      "path": "effects.billingCodes.automatic.billingCode.label"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WorkForceTypeLookup",
                  "kind": "LinkedField",
                  "name": "workForceType",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "fragment": {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "WorkForceTypeLabelResolver"
                      },
                      "kind": "RelayResolver",
                      "name": "label",
                      "resolverModule": require('./../../__resolvers__/WorkForceTypeLabelResolver').label,
                      "path": "effects.billingCodes.automatic.workForceType.label"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};
})();

(node as any).hash = "ec39d27095e8d2faff5a367604a432dc";

export default node;
