import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { RequirementLabelResolver$key } from './__generated__/RequirementLabelResolver.graphql';

/**
 * @RelayResolver Requirement.label: String
 * @rootFragment RequirementLabelResolver
 *
 * A label for Requirement which includes name and name.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(requirement$key: RequirementLabelResolver$key): string {
  const requirement$data = readFragment(
    graphql`
      fragment RequirementLabelResolver on Requirement {
        code
        name
      }
    `,
    requirement$key,
  );
  return `${requirement$data.code} - ${requirement$data.name}`;
}
