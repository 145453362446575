import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useState } from 'react';
import { ConfirmationDialog } from '../../common/dialogs/ConfirmationDialog';
import { DataID } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import { RateStrategyDeleteButtonMutation } from './__generated__/RateStrategyDeleteButtonMutation.graphql';
import { useNavigate } from 'react-router-dom';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useErrorBanner } from '../../common/components/ErrorBanner';

export function RateStrategyDeleteButton({ id }: { id: DataID }) {
  const { t } = useAmbientTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { reportUnexpectedError } = useErrorBanner();

  const [commit, isLoading] = usePromiseMutation<RateStrategyDeleteButtonMutation>(graphql`
    mutation RateStrategyDeleteButtonMutation($id: ID!) {
      deleteRateStrategy(input: { id: $id }) {
        rateStrategyLookup {
          id @deleteRecord
        }
      }
    }
  `);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      await commit({ variables: { id } });
    } catch {
      reportUnexpectedError(() => t('error.errorDuringDelete'));
    } finally {
      setOpen(false);
      navigate('../rate-strategies');
    }
  }, [commit, id, navigate, reportUnexpectedError, t]);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button variant='text' color='cancel' onClick={handleOpen}>
        <DeleteIcon color='error' fontSize='small' sx={{ mr: '0.5rem' }} />
        {t('button.delete', { ns: 'common' })}
      </Button>
      <ConfirmationDialog
        title={t('rateStrategies.delete')}
        message={t('rateStrategies.deleteMessage')}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        confirmText={t('button.delete', { ns: 'common' })}
        isOpen={open}
        isLoading={isLoading}
      />
    </>
  );
}
