import { Disposable, GraphQLTaggedNode, IEnvironment, MutationConfig, MutationParameters, PayloadError } from 'relay-runtime';
import { UseMutationConfig } from 'react-relay/relay-hooks/useMutation';
import { useMutation } from 'react-relay';
import { useCallback, useMemo } from 'react';

export type MutationPromiseValue<TMutation extends MutationParameters> = {
  response: TMutation['response'] | null;
  errors: PayloadError[] | null;
};

export function usePromiseMutation<TMutation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  commitMutationFn?: (environment: IEnvironment, config: MutationConfig<TMutation>) => Disposable,
): [
  (config: Omit<UseMutationConfig<TMutation>, 'onError' | 'onCompleted' | 'onUnsubscribe'>) => Promise<MutationPromiseValue<TMutation>>,
  boolean,
] {
  const [origCommit, isInFlight] = useMutation<TMutation>(mutation, commitMutationFn);

  const commit = useCallback(
    (config: UseMutationConfig<TMutation>) =>
      new Promise<MutationPromiseValue<TMutation>>((resolve, reject) => {
        origCommit({
          ...config,
          onError: (error) => {
            reject(error);
          },
          onCompleted: (response, errors) => {
            resolve({ response, errors });
          },
          onUnsubscribe: () => {
            resolve({ response: null, errors: null });
          },
        });
      }),
    [origCommit],
  );

  return useMemo(() => [commit, isInFlight], [commit, isInFlight]);
}
