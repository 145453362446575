/**
 * @generated SignedSource<<f20f0c93c42e17d94e3955ee9a648e48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TabDataSyncSeedAllMutation$variables = Record<PropertyKey, never>;
export type TabDataSyncSeedAllMutation$data = {
  readonly seedAllData: {
    readonly boolean: boolean | null | undefined;
  };
};
export type TabDataSyncSeedAllMutation = {
  response: TabDataSyncSeedAllMutation$data;
  variables: TabDataSyncSeedAllMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "safety": true
        }
      }
    ],
    "concreteType": "SeedAllDataPayload",
    "kind": "LinkedField",
    "name": "seedAllData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": "seedAllData(input:{\"safety\":true})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabDataSyncSeedAllMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabDataSyncSeedAllMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "74c209ac935b31c63a5628caab8df2f3",
    "id": null,
    "metadata": {},
    "name": "TabDataSyncSeedAllMutation",
    "operationKind": "mutation",
    "text": "mutation TabDataSyncSeedAllMutation {\n  seedAllData(input: {safety: true}) {\n    boolean\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ed7682e59c97c1b396cb286c48c6fb7";

export default node;
