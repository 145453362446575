import { MutableRefObject, useRef } from 'react';

/**
 * Synchronizes a value with a ref. Useful to expose props as refs.
 *
 * They will still have to be added to dependency lists for other hooks, but the ref will not change unless
 * the component is recreated, so those hooks will not be "triggered" due to changes in value of synchronized refs.
 * @param value The value to use for the current value of the ref.
 */
export function useRefSync<T>(value: T): MutableRefObject<T> {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}
