/**
 * @generated SignedSource<<99d582fcb80755fd3e5df8e4acde4900>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as requirementLabelResolverType } from "../../../__resolvers__/RequirementLabelResolver";
export type ProjectBaseFields_RequirementsFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly label: string | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_RequirementsFragment";
}>;
export type ProjectBaseFields_RequirementsFragment$key = ReadonlyArray<{
  readonly " $data"?: ProjectBaseFields_RequirementsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectBaseFields_RequirementsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RequirementLabelResolver"
      },
      "kind": "RelayResolver",
      "name": "label",
      "resolverModule": require('./../../../__resolvers__/RequirementLabelResolver').label,
      "path": "label"
    }
  ],
  "type": "Requirement",
  "abstractKey": null
};

(node as any).hash = "55e9907b4d2baa6d7324fda035a1cb14";

export default node;
