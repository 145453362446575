import { useTheme } from '@mui/material';

export function Asterisk({ top, disabled }: { top: string; disabled?: boolean }) {
  const theme = useTheme();
  return (
    <span
      style={{
        fontWeight: 'bolder',
        visibility: 'visible',
        position: 'relative',
        top: top,
        marginLeft: '0.2rem',
        fontSize: '1.15rem',
        color: disabled ? 'inherit' : theme.palette.error.main,
        maxHeight: 0,
        display: 'inline',
      }}>
      {'\u2217'}
    </span>
  );
}
