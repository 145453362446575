/**
 * @generated SignedSource<<5ee410e45659b3bece6b6d4946a1aef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_ClientOverrides_IsDirectSalesFragment$data = {
  readonly isDirectSales: boolean;
  readonly " $fragmentType": "ClientBaseFields_ClientOverrides_IsDirectSalesFragment";
};
export type ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key = {
  readonly " $data"?: ClientBaseFields_ClientOverrides_IsDirectSalesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverrides_IsDirectSalesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_ClientOverrides_IsDirectSalesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDirectSales",
      "storageKey": null
    }
  ],
  "type": "IOverridableClient",
  "abstractKey": "__isIOverridableClient"
};

(node as any).hash = "8f2d4c3854c2a5726b32fb3c2f57a229";

export default node;
