/**
 * @generated SignedSource<<5669dbb97162db16ec211d4c46df513a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageEffectsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsBillingCodesVisibleFragment" | "BillingCodeRuleFields_AutomaticGridFragment">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageEffectsFragment";
};
export type BillingCodeRuleDetailsPageEffectsFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageEffectsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageEffectsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageEffectsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsBillingCodesVisibleFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_AutomaticGridFragment"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "ab564b2ef48c978db278346c25131272";

export default node;
