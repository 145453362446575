/**
 * @generated SignedSource<<afcd91bbcba9edcd7d8fd4dceff6471e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accessoryBillingCodeAutocompleteListFragmentQuery$variables = {
  searchCount?: number | null | undefined;
  searchCursor?: string | null | undefined;
  searchTerm?: string | null | undefined;
};
export type accessoryBillingCodeAutocompleteListFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryBillingCodeAutocompleteListFragment">;
};
export type accessoryBillingCodeAutocompleteListFragmentQuery = {
  response: accessoryBillingCodeAutocompleteListFragmentQuery$data;
  variables: accessoryBillingCodeAutocompleteListFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 25,
    "kind": "LocalArgument",
    "name": "searchCount"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchCursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "searchCursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "searchCount"
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accessoryBillingCodeAutocompleteListFragmentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "searchCount",
            "variableName": "searchCount"
          },
          {
            "kind": "Variable",
            "name": "searchCursor",
            "variableName": "searchCursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AccessoryBillingCodeAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accessoryBillingCodeAutocompleteListFragmentQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "concreteType": "SearchAccessoryBillingCodesConnection",
        "kind": "LinkedField",
        "name": "searchAccessoryBillingCodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchAccessoryBillingCodesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingCode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "microDescription",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        }
                      ],
                      "type": "BillingCode",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "accessoryBillingCodeAutocompleteListFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchAccessoryBillingCodes"
      }
    ]
  },
  "params": {
    "cacheID": "61b9b6178a7f5482c453f61d09cee648",
    "id": null,
    "metadata": {},
    "name": "accessoryBillingCodeAutocompleteListFragmentQuery",
    "operationKind": "query",
    "text": "query accessoryBillingCodeAutocompleteListFragmentQuery(\n  $searchCount: Int = 25\n  $searchCursor: String\n  $searchTerm: String\n) {\n  ...AccessoryBillingCodeAutocompleteListFragment_wtMSG\n}\n\nfragment AccessoryBillingCodeAutocompleteListFragment_wtMSG on Query {\n  searchResults: searchAccessoryBillingCodes(searchTerm: $searchTerm, after: $searchCursor, first: $searchCount) {\n    edges {\n      node {\n        id\n        ...BillingCodeLabelResolver\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment BillingCodeLabelResolver on BillingCode {\n  microDescription\n  description\n}\n"
  }
};
})();

(node as any).hash = "399d424b1cf0f05d8560c342eceb9164";

export default node;
