import WarningIcon from '@mui/icons-material/Warning';
import { PaletteColor, SxProps, Theme, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { resolvedLanguage } from '../i18n';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { CreditRisk } from '../__enums__/CreditRisk';
import { _never } from '../common/utils/_never';
import { CreditAlertIndicatorFragment$key } from './__generated__/CreditAlertIndicatorFragment.graphql';

export interface CreditAlertIndicatorProps {
  fragmentKey: CreditAlertIndicatorFragment$key;
  sx?: SxProps<Theme> | undefined;
}

function getCreditRiskPalette(creditRisk: CreditRisk, theme: Theme): PaletteColor {
  switch (creditRisk) {
    case 'warning':
      return theme.palette.success;
    case 'danger':
      return theme.palette.warning;
    case 'extreme':
      return theme.palette.error;
    default:
      return _never(creditRisk);
  }
}

/**
 * Displays a credit card alert icon given the credit risk.
 * @param fragmentKey Used to fetch the creditRisk & creditRiskDescription data.
 * @param sx
 * @constructor
 */
export function CreditAlertIndicator({ fragmentKey, sx }: CreditAlertIndicatorProps) {
  const { t, i18n } = useTranslation('jobs');
  const data = useFragment(
    graphql`
      fragment CreditAlertIndicatorFragment on Client {
        creditRisk
        creditRiskDescription
      }
    `,
    fragmentKey,
  );
  const creditRisk = data.creditRisk as CreditRisk | null;
  const creditRiskDescription = data.creditRiskDescription;

  return (
    creditRisk && (
      <Tooltip title={t('alert.creditMessage', { description: creditRiskDescription?.[resolvedLanguage(i18n)] })} placement='top'>
        <WarningIcon
          sx={([(theme) => ({ color: getCreditRiskPalette(creditRisk, theme).main }), sx ?? null] satisfies SxProps<Theme>[]).flat()}
        />
      </Tooltip>
    )
  );
}
