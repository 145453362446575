/**
 * @generated SignedSource<<832f1512ac2dc325748cbd5118efe0c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoomConfigurationLabelResolver$data = {
  readonly boomLength: string;
  readonly counterweight: string;
  readonly jibLength: string;
  readonly sequence: number;
  readonly " $fragmentType": "BoomConfigurationLabelResolver";
};
export type BoomConfigurationLabelResolver$key = {
  readonly " $data"?: BoomConfigurationLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoomConfigurationLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoomConfigurationLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    }
  ],
  "type": "BoomConfiguration",
  "abstractKey": null
};

(node as any).hash = "60bf828fe40987841e52fa3b96148d77";

export default node;
