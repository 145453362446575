/**
 * @generated SignedSource<<485b739b3089ffb70068b6ce0da01434>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetailsPageRootErrorBoundaryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsDetailsLayoutFragment" | "SidebarDrawerFragment">;
  readonly " $fragmentType": "DetailsPageRootErrorBoundaryFragment";
};
export type DetailsPageRootErrorBoundaryFragment$key = {
  readonly " $data"?: DetailsPageRootErrorBoundaryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailsPageRootErrorBoundaryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailsPageRootErrorBoundaryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarDrawerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsDetailsLayoutFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7ec94ecb63a498e982cc5e0e44743b5a";

export default node;
