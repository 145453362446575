/**
 * @generated SignedSource<<de5c5c1c4ac8f737af1ecc8bfec9a385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorAutomaticFavoriteFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment">;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorAutomaticFavoriteFragment";
};
export type JobEquipment_useCraneSelectorAutomaticFavoriteFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorAutomaticFavoriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorAutomaticFavoriteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorAutomaticFavoriteFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "18526a00207f744fb29c500d8504d102";

export default node;
