/**
 * @generated SignedSource<<60c5a8addb223945f98741d76069a49f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as craneCapacityLabelResolverType } from "../../../__resolvers__/CraneCapacityLabelResolver";
export type SaleCraneSelectorFields_CraneCapacityFragment$data = {
  readonly capacity: {
    readonly capacity: number;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_CraneCapacityFragment";
};
export type SaleCraneSelectorFields_CraneCapacityFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_CraneCapacityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneCapacityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_CraneCapacityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CraneCapacity",
      "kind": "LinkedField",
      "name": "capacity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CraneCapacityLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/CraneCapacityLabelResolver').label,
          "path": "capacity.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "55b8d22a76a2da525d6bc5b2c6dfbc35";

export default node;
