import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback, useEffect, useRef } from 'react';
import { ChipTypeMap } from '@mui/material';
import { ForwardOffsetPaginatedAutocompleteProps, OffsetNode, OffsetPaginatedAutocomplete, Queryable } from './PaginatedAutocomplete';
import { convertToTsQuery } from '../utils/stringUtils';
import { CraneVehicleIdAutocompleteFragment$data } from './__generated__/CraneVehicleIdAutocompleteFragment.graphql';
import { CraneVehicleIdAutocompleteQuery } from './__generated__/CraneVehicleIdAutocompleteQuery.graphql';

export interface CraneVehicleIdAutocompleteProps {
  capacity: number | null;
  equipmentKindCode: number | null;
  configurationKindCode: number | null;
}

export type ForwardCraneVehicleIdAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardOffsetPaginatedAutocompleteProps<
  OffsetNode<CraneVehicleIdAutocompleteFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel' | 'getOptionKey',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const CraneVehicleIdAutocomplete = forwardRef<
  HTMLInputElement,
  CraneVehicleIdAutocompleteProps &
    ForwardOffsetPaginatedAutocompleteProps<
      OffsetNode<CraneVehicleIdAutocompleteFragment$data>,
      'fragment' | 'onQuery' | 'getOptionLabel' | 'getOptionKey',
      boolean,
      boolean,
      ElementType
    >
>(function CraneVehicleIdAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    capacity,
    equipmentKindCode,
    configurationKindCode,
    ...paginatedAutocompleteProps
  }: CraneVehicleIdAutocompleteProps &
    ForwardOffsetPaginatedAutocompleteProps<
      OffsetNode<CraneVehicleIdAutocompleteFragment$data>,
      'fragment' | 'onQuery' | 'getOptionLabel' | 'getOptionKey',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const env = useRelayEnvironment();
  const queryRef = useRef<Queryable>(null);

  useEffect(() => {
    queryRef.current?.reset();
  }, [capacity, configurationKindCode, equipmentKindCode]);

  const handleQuery = useCallback(
    (searchTerm: string | null) =>
      fetchQuery<CraneVehicleIdAutocompleteQuery>(
        env,
        graphql`
          query CraneVehicleIdAutocompleteQuery($searchTerm: String, $capacity: Int, $equipmentKindCode: Int, $configurationKindCode: Int) {
            ...CraneVehicleIdAutocompleteFragment
              @arguments(
                searchTerm: $searchTerm
                capacity: $capacity
                equipmentKindCode: $equipmentKindCode
                configurationKindCode: $configurationKindCode
              )
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
          capacity,
          equipmentKindCode,
          configurationKindCode,
        },
      ),
    [env, capacity, equipmentKindCode, configurationKindCode],
  );

  return (
    <OffsetPaginatedAutocomplete<OffsetNode<CraneVehicleIdAutocompleteFragment$data>, Multiple, DisableClearable, ChipComponent>
      ref={ref}
      queryRef={queryRef}
      fragment={graphql`
        fragment CraneVehicleIdAutocompleteFragment on Query
        @refetchable(queryName: "craneVehicleIdAutocomplete2FragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          capacity: { type: "Int" }
          equipmentKindCode: { type: "Int" }
          configurationKindCode: { type: "Int" }
          skip: { type: "Int", defaultValue: 0 }
        ) {
          searchResults: searchCraneVehicleIds(
            searchTerm: $searchTerm
            capacity: $capacity
            equipmentKindCode: $equipmentKindCode
            configurationKindCode: $configurationKindCode
            skip: $skip
            take: 25
          ) {
            items {
              result {
                key
                label
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      `}
      onQuery={handleQuery}
      getOptionKey={(o) => o.key}
      isOptionEqualToValue={(option, v) => option.key === v.key}
      {...paginatedAutocompleteProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: CraneVehicleIdAutocompleteProps &
    ForwardCraneVehicleIdAutocompleteProps<Multiple, DisableClearable, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
