/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const quoteStatuses = ["accepted", "awaitingClient", "canceled", "declined", "inApproval", "inWriting", "locked", "revising"] as const;
export type QuoteStatus = typeof quoteStatuses[number];

export function isQuoteStatus(value: string): value is QuoteStatus {
  return quoteStatuses.includes(value)
}

export function asQuoteStatus(value: string): QuoteStatus | null {
  return isQuoteStatus(value) ? value : null;
}

export function castQuoteStatus(value: string): QuoteStatus {
  if(!isQuoteStatus(value)) {
    throw new Error(`Invalid Enum value for type "QuoteStatus": "${value}"`);
  }
  
  return value;
}
