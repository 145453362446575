import { useCallback, useMemo, useState } from 'react';

/**
 * Holds the state of a long-running process.
 *
 * The process can be in:
 *  - an initial state, where its completion or running state is irrelevant.
 *  - an active state, where the details of the state is defined by task provided to the start function.
 *  - a completed state, where the task is flagged as done.
 *
 * @param initialState - The initial state of the task.
 */
export function useTaskState(initialState: 'initial' | unknown | 'completed' = 'initial'): {
  /**
   * Set the task to an active state defined by the provided task value.
   */
  readonly start: (task: unknown) => void;
  /**
   * Set the task to a completed state.
   */
  readonly complete: () => void;
  /**
   * True when the task is completed, false otherwise.
   */
  readonly done: boolean;
  /**
   * The current state of the task.
   */
  readonly state: 'initial' | unknown | 'completed';
} {
  const [state, setState] = useState<unknown>(initialState);
  const done = state === 'completed';
  const start = useCallback((task: unknown) => setState(task), []);
  const complete = useCallback(() => setState((prev: unknown) => (prev !== 'initial' ? 'completed' : prev)), []);

  return useMemo<ReturnType<typeof useTaskState>>(() => Object.freeze({ start, complete, done, state }), [complete, done, start, state]);
}
