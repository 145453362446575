/**
 * @generated SignedSource<<8e9235fc7cd3256012bde254928cd859>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneConfigurationsFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneSelectorModeFragment" | "SaleCraneSelectorFields_FavoriteConfigurationFragment">;
  readonly " $fragmentType": "SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment";
};
export type SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneSelectorModeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_FavoriteConfigurationFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_CraneConfigurationsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "1fed34a562258d426ea759005b73b51f";

export default node;
