/**
 * @generated SignedSource<<ebdd14a3b477dcdce40caa3152c060db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useSyncAdditionalCranesManualFragment$data = {
  readonly manualConfiguration: {
    readonly userInput: {
      readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_Manual_BoomConfigurationFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesManualCollectionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneSelectorModeFragment">;
  readonly " $fragmentType": "JobEquipment_useSyncAdditionalCranesManualFragment";
};
export type JobEquipment_useSyncAdditionalCranesManualFragment$key = {
  readonly " $data"?: JobEquipment_useSyncAdditionalCranesManualFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useSyncAdditionalCranesManualFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useSyncAdditionalCranesManualFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneSelectorModeFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInternal",
      "kind": "LinkedField",
      "name": "manualConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ManualConfigurationInfo",
          "kind": "LinkedField",
          "name": "userInput",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SaleCraneSelectorFields_Manual_BoomConfigurationFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "8478a3c4b77fe888ff51e499824ed91d";

export default node;
