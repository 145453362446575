/**
 * @generated SignedSource<<cc291d291d3d72f3d4097dbdac703957>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleClientFields_InTheCareOfFragment$data = {
  readonly inTheCareOf: string | null | undefined;
  readonly " $fragmentType": "SaleClientFields_InTheCareOfFragment";
};
export type SaleClientFields_InTheCareOfFragment$key = {
  readonly " $data"?: SaleClientFields_InTheCareOfFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleClientFields_InTheCareOfFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleClientFields_InTheCareOfFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inTheCareOf",
      "storageKey": null
    }
  ],
  "type": "ISaleClient",
  "abstractKey": "__isISaleClient"
};

(node as any).hash = "46ff5cb79807c1cbe13e2d0a0bdd8562";

export default node;
