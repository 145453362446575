import { PatchOperationAction } from '../../__enums__/PatchOperationAction';
import { TFunction } from 'i18next';

/* eslint-disable no-restricted-imports */
import {
  Control,
  ControllerFieldState,
  DeepPartialSkipArrayKey,
  FieldArrayPath,
  FieldPath,
  FieldPathValue,
  FieldPathValues,
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayReturn,
  useForm as useFormOrig,
  useFormContext as useFormContextOrig,
  UseFormProps,
  UseFormReturn as UseFormReturnOrig,
  useWatch,
} from 'react-hook-form';
/* eslint-enable no-restricted-imports */

export const getPatchOperationAction = (isDeleted: boolean, isNew: boolean): PatchOperationAction => {
  if (isDeleted) {
    return 'delete';
  }
  if (isNew) {
    return 'insert';
  }
  return 'update';
};

/** Returns an actual {@link Array} from an array-like object produced by {@link useFieldArray}. */
export const fieldArray = <T>(obj: T[] | Record<number, T>): T[] =>
  Array.from({
    // Spread all properties, which include "array" elements
    ...obj,
    // Recompute length as the maximum integer property name + 1, with a minimum value of 0
    // for when the object has no integer property names (as `Math.max()` would return `-Infinity` otherwise).
    length:
      Math.max(
        -1,
        ...Object.keys(obj)
          .filter((k) => k.match(/^\d+$/))
          .map(Number),
      ) + 1,
  });

export function undefinedIfEmpty<T extends ArrayLike<unknown>>(value: T | undefined): T | undefined;
export function undefinedIfEmpty<T extends ArrayLike<unknown>>(value: T | null | undefined): T | null | undefined;
export function undefinedIfEmpty<T extends ArrayLike<unknown>>(value: T | null | undefined): T | null | undefined {
  return value?.length === 0 ? undefined : value;
}

export function nullIfEmpty<T extends ArrayLike<unknown>>(value: T | null): T | null;
export function nullIfEmpty<T extends ArrayLike<unknown>>(value: T | null | undefined): T | null | undefined;
export function nullIfEmpty<T extends ArrayLike<unknown>>(value: T | null | undefined): T | null | undefined {
  return value?.length === 0 ? null : value;
}

export function muiErrorPropsFromFieldStateFactory<T extends TFunction>(
  t: T,
): (fieldState: ControllerFieldState, options?: Record<string, unknown>) => { error: boolean; helperText: string | undefined } {
  return (fs, o) => ({
    error: !!fs.error,
    helperText: t(fs.error?.message ?? '', o) ?? undefined,
  });
}

/** Evaluates to `true` when `T` contains properties of type `Function`, or `false` otherwise. */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export type HasFunctionProperties<T> = [Extract<NonNullable<T>[keyof NonNullable<T>], Function>] extends [never] ? false : true;

/**
 * A type similar to `react-hook-form`'s {@link import('react-hook-form').DeepPartial} allowing the use of arbitrary class instances in form
 * values.
 *
 * Apart from primitive types, {@link import('react-hook-form').DeepPartial} only allows the use of {@link Date}, {@link FileList}, and {@link
 * File} objects in form values. When given any other object type with some function properties, it not only makes these
 * function properties optional, but also transforms them into the empty object type `{}`, which makes them un-callable.
 * This can make complex types (i.e. class instances) unusable once fed through {@link import('react-hook-form').DeepPartial}. By extending {@link
 * import('react-hook-form').DeepPartial}'s exclusion to any type containing function properties, this type allows the use of most complex types
 * in form values. Just don't store raw functions in form values :)
 */
export type PartialFormValues<T> = HasFunctionProperties<T> extends true ? T : { [K in keyof T]?: PartialFormValues<T[K]> };

/** Returns a partial form values object only containing dirty values. */
export const dirtyFormValues = <TFieldValues extends FieldValues>(
  formValues: TFieldValues,
  dirtyFields: UseFormReturnOrig<TFieldValues>['formState']['dirtyFields'],
): PartialFormValues<TFieldValues> => {
  const dirtyValues: Record<string, unknown> = {};

  const traverse = (df: Record<string, unknown>, path: string[] = []) => {
    Object.entries(df).forEach(([key, isDirty]) => {
      const value = [...path, key].reduce((o, k) => o[k], formValues);
      if (
        // If the field is explicitly marked as dirty, or contains a class instance, include it in the output
        isDirty === true ||
        (typeof value === 'object' && value != null && Object.getPrototypeOf(value) !== Object.prototype)
      ) {
        // Recursively get dirty value parent in output object, creating missing intermediate objects
        const dirtyValueParent = path.reduce((o, k) => (o[k] || (o[k] = {})) as Record<string, unknown>, dirtyValues);
        // Assign the dirty value to the parent under the correct key
        dirtyValueParent[key] = value;
      } else if (typeof isDirty === 'object' && isDirty != null) {
        traverse(isDirty as Record<string, unknown>, [...path, key]);
      }
    });
  };

  traverse(dirtyFields);

  return dirtyValues as PartialFormValues<TFieldValues>;
};

/** A WeakMap-based cache to allow returning a constant-reference wrapped methods object from {@link useForm} and
 * {@link useFormContext}. */
const formMethodsCache = new WeakMap<object, object>();

/** Wrap form methods objects as returned by `react-hook-form`'s `useForm()` or `useFormContext()` in a way that
 * always returns the same output object for a given input object. This is necessary because returning a new object
 * on every call can lead to infinite `useEffect()` loops when the methods object itself is specified as one of
 * `useEffect()`'s dependencies, since the objet's reference changes for every call. Returning a constant reference
 * fixes that issue. */
const wrapMethods = <
  TFieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  methods: TFieldValues extends FieldValues ? UseFormReturnOrig<TFieldValues, TContext, TTransformedValues> : unknown,
): UseFormReturn<TFieldValues, TContext, TTransformedValues> => {
  // `methods` can be null when calling useFormContext() without a parent <FormProvider />. In this case, return null
  // early to avoid using it as a WeakMap key later, which would throw an exception.
  if (methods == null) return methods as UseFormReturn<TFieldValues, TContext, TTransformedValues>;

  let wrapped = formMethodsCache.get(methods);
  if (!wrapped) {
    wrapped = {};
    formMethodsCache.set(methods, wrapped);
  }

  // `react-hook-form'`s `useFormContext()` seems to sometimes return a constant-reference object while still changing
  // the methods inside of it, so reassigning the methods on every call is required for all use-cases to work as intended.
  return Object.assign(wrapped, methods, { useController, useFieldArray, useWatch }) as UseFormReturn<
    TFieldValues,
    TContext,
    TTransformedValues
  >;
};

/**
 * A version of `react-hook-form`'s `useForm()` hook which also exposes `useController()`, `useFieldArray()` and
 * `useWatch()` to allow specifying the type of form values only once per component.
 */
export const useForm = <
  TFieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props?: TFieldValues extends FieldValues ? UseFormProps<TFieldValues, TContext> : unknown,
): UseFormReturn<TFieldValues, TContext, TTransformedValues> =>
  wrapMethods<TFieldValues, TContext, TTransformedValues>(
    useFormOrig(props as UseFormProps) as TFieldValues extends FieldValues
      ? UseFormReturnOrig<TFieldValues, TContext, TTransformedValues>
      : unknown,
  );

/**
 * A version of `react-hook-form`'s `useFormContext()` hook which also exposes `useController()`, `useFieldArray()` and
 * `useWatch()` to allow specifying the type of form values only once per component.
 */
export const useFormContext = <
  TFieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(): UseFormReturn<TFieldValues, TContext, TTransformedValues> =>
  wrapMethods<TFieldValues, TContext, TTransformedValues>(
    useFormContextOrig() as TFieldValues extends FieldValues ? UseFormReturnOrig<TFieldValues, TContext, TTransformedValues> : unknown,
  );

type MissingTFieldValuesError = 'Error: specifying TFieldValues type parameter is mandatory';

/**
 * A version of `react-hook-form`'s `UseFormReturn` type which also exposes `useController()`, `useFieldArray()` and
 * `useWatch()` to allow specifying the type of form values only once per component.
 */
export type UseFormReturn<
  TFieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = TFieldValues extends FieldValues
  ? UseFormReturnOrig<TFieldValues, TContext, TTransformedValues> & {
      useController: UseController<TFieldValues>;
      useFieldArray: UseFieldArray<TFieldValues>;
      useWatch: UseWatch<TFieldValues>;
    }
  : MissingTFieldValuesError;

export type UseController<TFieldValues extends FieldValues> = {
  <TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: UseControllerProps<TFieldValues, TName>,
  ): UseControllerReturn<TFieldValues, TName>;
};

export type UseFieldArray<TFieldValues extends FieldValues> = {
  <TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>, TKeyName extends string = 'id'>(
    props: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>,
  ): UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>;
};

export type UseWatch<TFieldValues extends FieldValues> = {
  (props: {
    defaultValue?: DeepPartialSkipArrayKey<TFieldValues>;
    control?: Control<TFieldValues>;
    disabled?: boolean;
    exact?: boolean;
  }): DeepPartialSkipArrayKey<TFieldValues>;
  <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(props: {
    name: TFieldName;
    defaultValue?: FieldPathValue<TFieldValues, TFieldName>;
    control?: Control<TFieldValues>;
    disabled?: boolean;
    exact?: boolean;
  }): FieldPathValue<TFieldValues, TFieldName>;
  <TFieldNames extends readonly FieldPath<TFieldValues>[] = readonly FieldPath<TFieldValues>[]>(props: {
    name: readonly [...TFieldNames];
    defaultValue?: DeepPartialSkipArrayKey<TFieldValues>;
    control?: Control<TFieldValues>;
    disabled?: boolean;
    exact?: boolean;
  }): FieldPathValues<TFieldValues, TFieldNames>;
  (): DeepPartialSkipArrayKey<TFieldValues>;
};
