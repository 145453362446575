import { useLayoutEffect, useState } from 'react';

const isBrowser = typeof window !== `undefined`;
export type position = { x: number; y: number };
function getScrollPosition({ element = undefined, useWindow = true }: { element?: HTMLElement | undefined; useWindow?: boolean }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element : document.body;
  const position = target.getBoundingClientRect();

  return useWindow ? { x: window.scrollX, y: window.scrollY } : { x: position.left, y: position.top };
}

export function useScrollPosition(element?: HTMLElement): position {
  const [pos, setPos] = useState<position>(getScrollPosition({ useWindow: !element }));

  useLayoutEffect(() => {
    const handleScroll = () => {
      setPos(getScrollPosition({ element, useWindow: !element }));
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [element]);
  return pos;
}
