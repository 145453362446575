import { Box, Theme, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';

export function EmptyList({ placeholder, sx }: { placeholder?: string; sx?: SxProps<Theme> }) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  return (
    <Box display='flex' alignItems='center' justifyContent='center' p={2} bgcolor={theme.palette.background.paper} sx={sx}>
      <Typography variant='body2' textTransform='uppercase' sx={{ color: theme.palette.text.secondary }}>
        {placeholder || t('placeholder.noValue')}
      </Typography>
    </Box>
  );
}
