/**
 * @generated SignedSource<<a3bd92082096fa3eff3ef158978aae7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteLocationInput_ClientFragment$data = {
  readonly assignedClientInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverrides_IsDirectSalesFragment" | "ProjectBaseFields_CopyClientAddressButtonFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_AssignedClientFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteLocationInput_ClientFragment";
};
export type ProjectBaseFields_WorksiteLocationInput_ClientFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteLocationInput_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteLocationInput_ClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteLocationInput_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientBaseFields_AssignedClientFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInfoInternal",
      "kind": "LinkedField",
      "name": "assignedClientInfo",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_CopyClientAddressButtonFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_ClientOverrides_IsDirectSalesFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "8555ebf44bca79ee025cf0ca966ad050";

export default node;
