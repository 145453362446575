import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback, useEffect, useRef } from 'react';
import { ChipTypeMap } from '@mui/material';
import { CraneCapacityAutocompleteFragment$data } from './__generated__/CraneCapacityAutocompleteFragment.graphql';
import { CraneCapacityAutocompleteQuery } from './__generated__/CraneCapacityAutocompleteQuery.graphql';
import { ForwardOffsetPaginatedAutocompleteProps, OffsetNode, OffsetPaginatedAutocomplete, Queryable } from './PaginatedAutocomplete';

export interface CraneCapacityAutocompleteProps {
  vehicleIds: string[] | null;
  equipmentKindCode: number | null;
  configurationKindCode: number | null;
}

export type ForwardCraneCapacityAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardOffsetPaginatedAutocompleteProps<
  OffsetNode<CraneCapacityAutocompleteFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel' | 'getOptionKey',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const CraneCapacityAutocomplete = forwardRef<
  HTMLInputElement,
  CraneCapacityAutocompleteProps & ForwardCraneCapacityAutocompleteProps<boolean, boolean, ElementType>
>(function CraneCapacityAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    vehicleIds,
    equipmentKindCode,
    configurationKindCode,
    ...paginatedAutocompleteProps
  }: CraneCapacityAutocompleteProps & ForwardCraneCapacityAutocompleteProps<Multiple, DisableClearable, ChipComponent>,
  ref: Ref<HTMLInputElement>,
) {
  const env = useRelayEnvironment();
  const queryRef = useRef<Queryable>(null);

  useEffect(() => {
    queryRef.current?.reset();
  }, [vehicleIds, configurationKindCode, equipmentKindCode]);

  const handleQuery = useCallback(
    (searchTerm: string | null) => {
      return fetchQuery<CraneCapacityAutocompleteQuery>(
        env,
        graphql`
          query CraneCapacityAutocompleteQuery(
            $searchTerm: String
            $vehicleIds: [String!]
            $equipmentKindCode: Int
            $configurationKindCode: Int
          ) {
            ...CraneCapacityAutocompleteFragment
              @arguments(
                searchTerm: $searchTerm
                vehicleIds: $vehicleIds
                equipmentKindCode: $equipmentKindCode
                configurationKindCode: $configurationKindCode
              )
          }
        `,
        {
          searchTerm,
          vehicleIds,
          equipmentKindCode,
          configurationKindCode,
        },
      );
    },
    [configurationKindCode, env, equipmentKindCode, vehicleIds],
  );

  return (
    <OffsetPaginatedAutocomplete<OffsetNode<CraneCapacityAutocompleteFragment$data>, Multiple, DisableClearable, ChipComponent>
      ref={ref}
      queryRef={queryRef}
      fragment={graphql`
        fragment CraneCapacityAutocompleteFragment on Query
        @refetchable(queryName: "craneCapacityAutocompleteFragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          vehicleIds: { type: "[String!]" }
          equipmentKindCode: { type: "Int" }
          configurationKindCode: { type: "Int" }
          skip: { type: "Int", defaultValue: 0 }
        ) {
          searchResults: searchCraneCapacities(
            searchTerm: $searchTerm
            vehicleIds: $vehicleIds
            equipmentKindCode: $equipmentKindCode
            configurationKindCode: $configurationKindCode
            skip: $skip
            take: 25
          ) {
            items {
              result {
                capacity
                label
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      `}
      onQuery={handleQuery}
      getOptionKey={(o) => o.capacity}
      isOptionEqualToValue={(option, value) => option.capacity === value.capacity}
      {...paginatedAutocompleteProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: CraneCapacityAutocompleteProps &
    ForwardCraneCapacityAutocompleteProps<Multiple, DisableClearable, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
