/**
 * @generated SignedSource<<672598eb05490b31e23fb10fa3ab8e75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type equipmentKindAutocomplete2FragmentQuery$variables = {
  capacity?: number | null | undefined;
  configurationKindCode?: number | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  searchTerm?: string | null | undefined;
  vehicleIds?: ReadonlyArray<string> | null | undefined;
};
export type equipmentKindAutocomplete2FragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentKindAutocompleteFragment">;
};
export type equipmentKindAutocomplete2FragmentQuery = {
  response: equipmentKindAutocomplete2FragmentQuery$data;
  variables: equipmentKindAutocomplete2FragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "capacity"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "configurationKindCode"
  },
  {
    "defaultValue": 25,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vehicleIds"
  }
],
v1 = {
  "kind": "Variable",
  "name": "capacity",
  "variableName": "capacity"
},
v2 = {
  "kind": "Variable",
  "name": "configurationKindCode",
  "variableName": "configurationKindCode"
},
v3 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v4 = {
  "kind": "Variable",
  "name": "vehicleIds",
  "variableName": "vehicleIds"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "equipmentKindAutocomplete2FragmentQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "EquipmentKindAutocompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "equipmentKindAutocomplete2FragmentQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v5/*: any*/),
        "concreteType": "SearchCraneEquipmentKindsConnection",
        "kind": "LinkedField",
        "name": "searchCraneEquipmentKinds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchCraneEquipmentKindsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EquipmentKindLookup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        }
                      ],
                      "type": "EquipmentKindLookup",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v5/*: any*/),
        "filters": [
          "searchTerm",
          "capacity",
          "vehicleIds",
          "configurationKindCode"
        ],
        "handle": "connection",
        "key": "equipmentKindAutocomplete2Fragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchCraneEquipmentKinds"
      }
    ]
  },
  "params": {
    "cacheID": "c38e37d264c35f689241cc67eef451ef",
    "id": null,
    "metadata": {},
    "name": "equipmentKindAutocomplete2FragmentQuery",
    "operationKind": "query",
    "text": "query equipmentKindAutocomplete2FragmentQuery(\n  $capacity: Int\n  $configurationKindCode: Int\n  $count: Int = 25\n  $cursor: String\n  $searchTerm: String\n  $vehicleIds: [String!]\n) {\n  ...EquipmentKindAutocompleteFragment_h4IoG\n}\n\nfragment EquipmentKindAutocompleteFragment_h4IoG on Query {\n  searchResults: searchCraneEquipmentKinds(searchTerm: $searchTerm, capacity: $capacity, vehicleIds: $vehicleIds, configurationKindCode: $configurationKindCode, after: $cursor, first: $count) {\n    edges {\n      node {\n        id\n        code\n        ...EquipmentKindLookupLabelResolver\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EquipmentKindLookupLabelResolver on EquipmentKindLookup {\n  description\n}\n"
  }
};
})();

(node as any).hash = "379bec5d80bb40a15a05af1ed1d25e4d";

export default node;
