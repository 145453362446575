import {
  Breakpoint,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { LoadingButton } from '../components/LoadingButton';

interface Props {
  title: string;
  message: string;
  onCancel?: () => void;
  onConfirm: () => void | Promise<void>;
  isOpen: boolean;
  maxWidth?: Breakpoint | false;
  confirmText?: string | null;
  cancelText?: string | null;
  confirmColor?: ButtonProps['color'];
  confirmationButtonIcon?: JSX.Element;
  isLoading?: boolean;
  showCancel?: boolean;
  showClose?: boolean;
}

export function ConfirmationDialog({
  title,
  message,
  onConfirm: handleConfirm,
  onCancel: handleCancel,
  isOpen,
  confirmText,
  cancelText,
  maxWidth = 'xs',
  confirmColor = 'primary',
  isLoading = false,
  confirmationButtonIcon,
  showCancel = true,
  showClose = true,
}: Props) {
  const { t } = useTranslation('common');
  const [loadButton, setLoadButton] = useState(isLoading);

  useEffect(() => {
    setLoadButton(isLoading);
  }, [isLoading]);

  return (
    <Dialog data-testid='confirmationDialog' open={isOpen} maxWidth={maxWidth} fullWidth onClose={handleCancel}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {showClose && (
          <IconButton color='inherit' data-testid='closeButton' onClick={handleCancel} sx={{ ml: -1.5 }}>
            <CloseIcon />
          </IconButton>
        )}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <Button variant='text' onClick={handleCancel}>
            {cancelText ? cancelText : t('button.cancel')}
          </Button>
        )}
        <LoadingButton isLoading={loadButton} color={confirmColor} onClick={handleConfirm} icon={confirmationButtonIcon}>
          {confirmText ? confirmText : t('button.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
