import { WorkPlanningStatus } from '../../__enums__/WorkPlanningStatus';

const dateTime = {
  sevenDays: '7 days',
  fourteenDays: '14 days',
  thirtyDays: '30 days',
  sixtyDays: '60 days',
};

const details = {
  section: {
    attachments: 'Attachments',
    client: 'Client',
    equipmentBase: 'Equipment',
    project: 'Project',
  },
  subsection: {
    date: 'Date',
  },
};

const list = {
  column: {
    friendlyId: 'ID',
    client: 'Client',
    worksite: 'Worksite',
    status: 'Status',
    date: 'Date',
  },
};

export const workPlanning = {
  dateTime,
  details,
  dialog: {
    upgrade: {
      quote: {
        title: 'Create a quote',
        kind: 'Quote kind',
      },
      serviceCall: {
        title: 'Create a service call',
        kind: 'Service call kind',
      },
      confirmButton: 'Ok',
    },
  },
  error: {
    dateInThePast: 'Date cannot be in the past',
    worksiteIsRequired: 'Please select a worksite',
    errorDuringUpgradeToServiceCall: 'An error occurred while creating the service call',
    errorDuringUpgradeToQuote: 'An error occurred while creating the quote',
  },
  field: {
    equipment: {
      kind: 'Equipment Kind',
      type: 'Type',
      boomConfiguration: 'Boom Configuration',
      craneConfiguration: 'Crane Configuration',
      craneSelectionMode: 'Crane Selection Mode',
      lifts: 'Lifts',
      configurationList: 'Configurations',
      configuration: 'Configuration',
      otherInformation: 'Other Information',
    },
    project: {
      date: {
        arrival: 'Arrival Time',
        date: 'Date',
        duration: 'Duration of the job',
        durationPerDay: 'Duration per day',
        notes: 'Notes and additional information',
      },
    },
  },
  list,
  pages: {
    client: {
      title: 'Select Client',
      requirementDescriptionsTitle: 'Client requirements',
    },
    details: {
      copyTitle: 'Copy Planning',
      copyTitleShort: 'Copy',
      shortTitle: 'Edit Planning',
      title: 'Edit Planning',
      newTitle: 'New Planning',
    },
    list: {
      title: 'Work Planning',
    },
    project: {
      title: 'Project Information',
      requirementDescriptionsTitle: 'Worksite requirements',
      subsection: {
        branches: 'Branches',
        dateAndTime: 'Dates and time',
        work: 'Work',
        worksite: 'Worksite',
      },
    },
    equipment: {
      title: 'Equipment',
      crane: {
        title: 'Crane selector',
      },
      subsection: {
        craneSelector: 'Crane Selector',
      },
    },
  },
  placeholder: {
    clientInfo: 'Enter client information',
    noAttachment: 'No attachment',
    noConfiguration: 'No configuration',
    projectInfo: 'Enter project information',
    equipmentInfo: 'Enter equipment information',
    selectConfiguration: 'Select a configuration',
  },
  section: {
    client: 'Client',
    project: 'Projet',
    equipment: 'Equipement',
  },
  status: {
    canceled: 'Canceled',
    inProgress: 'In progress',
    inWriting: 'In writing',
    locked: 'Locked',
    worksiteSelected: 'Worksite selected',
  } satisfies Record<WorkPlanningStatus, string>,
};
