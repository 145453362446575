import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { AccessoryGroupLabelResolver$key } from './__generated__/AccessoryGroupLabelResolver.graphql';

/**
 * @RelayResolver AccessoryGroup.label: String
 * @rootFragment AccessoryGroupLabelResolver
 *
 * A label for Accessory which includes code and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(accessoryGroupLabel$key: AccessoryGroupLabelResolver$key): string {
  const accessoryLabel$data = readFragment(
    graphql`
      fragment AccessoryGroupLabelResolver on AccessoryGroup {
        description
      }
    `,
    accessoryGroupLabel$key,
  );

  return accessoryLabel$data.description;
}
