import { createContext, useContext } from 'react';
import { FallbackNs, useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';
import { KeyPrefix, Namespace } from 'i18next';

export const TranslationNamespaceContext = createContext<Namespace | undefined>(undefined);

function safeArray(ns: Namespace) {
  return Array.isArray(ns) ? ns : [ns];
}

/*
 * TODO: doc
 */
export function useAmbientTranslation<SubNs extends Namespace, KPrefix extends KeyPrefix<FallbackNs<SubNs>> = undefined>(
  subNs?: Namespace,
  options?: UseTranslationOptions<KPrefix>,
): UseTranslationResponse<SubNs, KPrefix> {
  const context = useContext(TranslationNamespaceContext);
  const ns = context && subNs ? [...safeArray(context), ...safeArray(subNs)] : subNs ? subNs : context;
  return useTranslation(ns, options);
}
