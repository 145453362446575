/**
 * @generated SignedSource<<b398416e8b21ece906ef7d3ee3f368fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as requirementLabelResolverType } from "../../../__resolvers__/RequirementLabelResolver";
export type ClientBaseFields_RequirementsFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly label: string | null | undefined;
  readonly " $fragmentType": "ClientBaseFields_RequirementsFragment";
}>;
export type ClientBaseFields_RequirementsFragment$key = ReadonlyArray<{
  readonly " $data"?: ClientBaseFields_RequirementsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_RequirementsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientBaseFields_RequirementsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RequirementLabelResolver"
      },
      "kind": "RelayResolver",
      "name": "label",
      "resolverModule": require('./../../../__resolvers__/RequirementLabelResolver').label,
      "path": "label"
    }
  ],
  "type": "Requirement",
  "abstractKey": null
};

(node as any).hash = "82e66117503ef1de4f2d3f0ce2aa1eab";

export default node;
