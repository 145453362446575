/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const serviceCallKinds = ["accessoriesRental", "bare", "laborRental", "liftingPlan", "liftingTest", "operatedHourly", "operatedMonthly", "rollingEquipment"] as const;
export type ServiceCallKind = typeof serviceCallKinds[number];

export function isServiceCallKind(value: string): value is ServiceCallKind {
  return serviceCallKinds.includes(value)
}

export function asServiceCallKind(value: string): ServiceCallKind | null {
  return isServiceCallKind(value) ? value : null;
}

export function castServiceCallKind(value: string): ServiceCallKind {
  if(!isServiceCallKind(value)) {
    throw new Error(`Invalid Enum value for type "ServiceCallKind": "${value}"`);
  }
  
  return value;
}
