import { Suspense, useCallback, useMemo, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Skeleton, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { deserializeURLParams, QuoteList, QuoteListProps, QuoteSearchParamFilters, serializeFilters } from './QuoteList';
import graphql from 'babel-plugin-relay/macro';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { QuoteListPageContentQuery } from './__generated__/QuoteListPageContentQuery.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import { NavigationMenu } from '../layout/SidebarDrawer';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ConstructionIcon from '@mui/icons-material/Construction';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import QuizIcon from '@mui/icons-material/Quiz';
import { QuoteKind } from '../__enums__/QuoteKind';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { DateTime } from 'luxon';
import { EmployeeNotFoundError } from '../auth/EmployeeNotFoundErrorBoundary';
import { QuoteListPageContentDefaultFiltersFragment$key } from './__generated__/QuoteListPageContentDefaultFiltersFragment.graphql';
import { QuoteListPageRootQuery } from './__generated__/QuoteListPageRootQuery.graphql';
import { QuoteStatus } from '../__enums__/QuoteStatus';
import { useJobFiltersSearchParams } from '../jobs/JobFilters';

export function QuoteListPage() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('quotes')}>
      <Suspense fallback={<QuoteListPageSkeleton />}>
        <QuoteListPageRoot />
      </Suspense>
    </ListPageErrorBoundary>
  );
}

function QuoteListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function QuoteListPageRoot() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useLazyLoadQuery<QuoteListPageRootQuery>(
    graphql`
      query QuoteListPageRootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...QuoteListPageContentDefaultFiltersFragment
        }
      }
    `,
    {},
  );

  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('quotes'));

  const navigate = useNavigate();

  const handleItemCreate = useCallback(
    (kind: QuoteKind) =>
      navigate({
        pathname: `/quotes/new`,
        search: createSearchParams({ kind }).toString(),
      }),
    [navigate],
  );

  const handleItemClick = useCallback((id: DataID) => navigate(`/quotes/${id}`), [navigate]);

  const theme = useTheme();
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = t('quotes');

  if (!$data.me) throw EmployeeNotFoundError;

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ListLayoutActions
                mainAction={{
                  label: t('createQuoteAction.main'),
                  icon: <PersonIcon />,
                  onClick: () => handleItemCreate('operatedHourly'),
                }}
                otherActions={{
                  operatedMonthly: {
                    label: t('createQuoteAction.options.operatedMonthly'),
                    icon: <PersonIcon />,
                    onClick: () => handleItemCreate('operatedMonthly'),
                  },
                  bare: {
                    label: t('createQuoteAction.options.bare'),
                    icon: <PersonOffIcon />,
                    onClick: () => handleItemCreate('bare'),
                  },
                  accessoriesRental: {
                    label: t('createQuoteAction.options.accessoriesRental'),
                    icon: <ConstructionIcon />,
                    onClick: () => handleItemCreate('accessoriesRental'),
                  },
                  laborRental: {
                    label: t('createQuoteAction.options.laborRental'),
                    icon: <EngineeringIcon />,
                    onClick: () => handleItemCreate('laborRental'),
                  },
                  rollingEquipment: {
                    label: t('createQuoteAction.options.rollingEquipment'),
                    icon: <LocalShippingIcon />,
                    onClick: () => handleItemCreate('rollingEquipment'),
                  },
                  liftingPlan: {
                    label: t('createQuoteAction.options.liftingPlan'),
                    icon: <NoteAltIcon />,
                    onClick: () => handleItemCreate('liftingPlan'),
                  },
                  liftingTest: {
                    label: t('createQuoteAction.options.liftingTest'),
                    icon: <QuizIcon />,
                    onClick: () => handleItemCreate('liftingTest'),
                  },
                }}
                componentProps={{
                  autoSplitButton: {
                    root: { 'aria-label': t('createQuoteAction.ariaLabels.root') },
                    buttonMain: { 'aria-label': t('createQuoteAction.ariaLabels.main') },
                    buttonMenu: { 'aria-label': t('createQuoteAction.ariaLabels.more') },
                    menuItemOption: ([, a]) => ({ 'aria-label': a.label }),
                  },
                  autoSpeedDial: {
                    root: {
                      'aria-label': 'createQuoteAction.menu',
                      ariaLabel: t('createQuoteAction.ariaLabels.more'),
                    },
                    speedDialActionOption: ([, a]) => ({ 'aria-label': a.label }),
                  },
                }}
              />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            <QuoteListPageContent
              defaultFilters$key={$data.me}
              write$key={$data}
              compact={compact}
              paginationSx={{ [theme.breakpoints.down('sm')]: { mb: '6rem' } }}
              onItemClick={handleItemClick}
            />
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

function QuoteListPageContent({
  defaultFilters$key,
  ...props
}: {
  defaultFilters$key: QuoteListPageContentDefaultFiltersFragment$key;
} & Omit<QuoteListProps, '$key' | 'filters$key' | 'filters' | 'onApplyFilters'>) {
  const [, setSearchParams] = useSearchParams();

  const today = DateTime.now();
  const [filtersDirty, setFiltersDirty] = useState<boolean>(false);

  const $data = useFragment(
    graphql`
      fragment QuoteListPageContentDefaultFiltersFragment on Employee {
        representativeId
        roles
        ...JobFilters_useJobFiltersSearchParamsFragment
      }
    `,
    defaultFilters$key,
  );

  const isSalesRepresentative = $data.roles.includes('salesRepresentative');

  const { effectiveSearchParams } = useJobFiltersSearchParams(
    $data,
    useMemo(
      () =>
        new URLSearchParams([
          ['status', 'inWriting' satisfies QuoteStatus],
          ['status', 'revising' satisfies QuoteStatus],
          ['status', 'inApproval' satisfies QuoteStatus],
          ['arrivalDateStart', today.toFormat('yyyy-MM-dd')],
          ['arrivalDateEnd', today.plus({ day: 7 }).toFormat('yyyy-MM-dd')],
          ...(isSalesRepresentative && !!$data.representativeId ? [['projectManagerId', $data.representativeId]] : []),
        ]),
      [$data.representativeId, isSalesRepresentative, today],
    ),
    filtersDirty,
  );

  const searchParamFilters = useMemo(() => {
    return deserializeURLParams(effectiveSearchParams);
  }, [effectiveSearchParams]);

  // eslint-disable-next-line react/hook-use-state
  const [initialQueriedFilters] = useState(() => ({
    searchTerm: searchParamFilters.searchTerm,
    where: serializeFilters(searchParamFilters),
    projectManagerIds: searchParamFilters.projectManager.map((pm) => pm.id),
    dispatchBranchIds: searchParamFilters.dispatchBranch.map((b) => b.id),
    equipmentKindCodes: searchParamFilters.equipmentKind.map((e) => e.code),
    representativeIds: searchParamFilters.representative.map((rep) => rep.id),
  }));

  const quotes = useLazyLoadQuery<QuoteListPageContentQuery>(
    graphql`
      query QuoteListPageContentQuery(
        $first: Int
        $where: QuoteJobRevisionFilterType
        $dispatchBranchIds: [ID!]!
        $equipmentKindCodes: [Int!]!
        $projectManagerIds: [ID!]!
        $representativeIds: [ID!]!
        $searchTerm: String
      ) {
        ...QuoteListFragment @arguments(searchTerm: $searchTerm, representativeIds: $representativeIds, first: $first, where: $where)
        ...QuoteListFiltersFragment
          @arguments(
            dispatchBranchIds: $dispatchBranchIds
            equipmentKindCodes: $equipmentKindCodes
            projectManagerIds: $projectManagerIds
            representativeIds: $representativeIds
          )
      }
    `,
    {
      searchTerm: convertToTsQuery(initialQueriedFilters.searchTerm),
      where: initialQueriedFilters.where,
      dispatchBranchIds: initialQueriedFilters.dispatchBranchIds,
      projectManagerIds: initialQueriedFilters.projectManagerIds,
      equipmentKindCodes: initialQueriedFilters.equipmentKindCodes,
      representativeIds: initialQueriedFilters.representativeIds,
      first: 25,
    },
    { fetchPolicy: 'store-and-network' },
  );

  const handleApplyFilters = useCallback(
    (values: QuoteSearchParamFilters) => {
      if (!filtersDirty) setFiltersDirty(true);
      setSearchParams({
        ...(values.searchTerm && { searchTerm: values.searchTerm }),
        capacity: values.capacity.map(String),
        ...(values.arrivalDate && {
          arrivalDateStart: values.arrivalDate.start,
          arrivalDateEnd: values.arrivalDate.end,
        }),
        dispatchBranchId: values.dispatchBranch.map((d) => d.id),
        equipmentKindCode: values.equipmentKind.map((e) => `${e.code}`),
        kind: values.kind.map(String),
        projectManagerId: values.projectManager.map((pm) => pm.id),
        representativeId: values.representative.map((rep) => rep.id),
        status: values.status.map(String),
      });
    },
    [filtersDirty, setSearchParams],
  );

  return <QuoteList {...props} $key={quotes} filters$key={quotes} filters={searchParamFilters} onApplyFilters={handleApplyFilters} />;
}

function GridSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Skeleton variant='rounded' height='40rem' />
    </Stack>
  );
}

function ListSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Stack gap={0.5}>
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
      </Stack>
    </Stack>
  );
}
