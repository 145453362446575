import graphql from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import { EquipmentKindLookupLabelResolver$key } from './__generated__/EquipmentKindLookupLabelResolver.graphql';

/**
 * @RelayResolver EquipmentKindLookup.label: Localized
 * @rootFragment EquipmentKindLookupLabelResolver
 *
 * An object that contains the label for every language for EquipmentKindLookup which includes description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(equipmentKindLookup$key: EquipmentKindLookupLabelResolver$key): Record<string, string> {
  const equipmentKindLookup$data = readFragment(
    graphql`
      fragment EquipmentKindLookupLabelResolver on EquipmentKindLookup {
        description
      }
    `,
    equipmentKindLookup$key,
  );

  return equipmentKindLookup$data.description;
}
