import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { ConfirmationDialog } from '../common/dialogs/ConfirmationDialog';
import { useOperations } from '../AppSharedState';
import { DELAY_OPERATION_KEY } from './ServiceCallDelayButton';

export type ServiceCallDelayDialogRef = {
  readonly open: (onConfirm?: () => void) => void;
  readonly close: () => void;
};

export const ServiceCallDelayDialog = forwardRef(function ServiceCallDelayDialog(
  {
    loading,
  }: {
    loading?: boolean;
  },
  ref: ForwardedRef<ServiceCallDelayDialogRef>,
) {
  const { t } = useAmbientTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<(() => void) | undefined>();
  const { hasOperationInFlightForKey } = useOperations(DELAY_OPERATION_KEY);

  useImperativeHandle(
    ref,
    () => ({
      open: (confirmFn) => {
        setIsOpen(true);
        setOnConfirm(() => confirmFn);
      },
      close: () => setIsOpen(false),
    }),
    [],
  );

  const handleConfirm = useCallback(() => onConfirm?.(), [onConfirm]);

  return (
    <ConfirmationDialog
      title={t('dialog.delay.title')}
      message={t('dialog.delay.body')}
      onCancel={() => setIsOpen(false)}
      onConfirm={handleConfirm}
      isOpen={isOpen}
      isLoading={hasOperationInFlightForKey || loading}
      confirmText={t('dialog.delay.confirmButton')}
      showCancel={false}
    />
  );
});
