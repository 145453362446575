/**
 * @generated SignedSource<<81fa0afb6e342b8308acd660bfad67b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarkNotificationReadInput = {
  id: string;
};
export type NotificationRow_MarkNotificationReadMutation$variables = {
  input: MarkNotificationReadInput;
};
export type NotificationRow_MarkNotificationReadMutation$data = {
  readonly markNotificationRead: {
    readonly notification: {
      readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
    } | null | undefined;
  };
};
export type NotificationRow_MarkNotificationReadMutation = {
  response: NotificationRow_MarkNotificationReadMutation$data;
  variables: NotificationRow_MarkNotificationReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationRow_MarkNotificationReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkNotificationReadPayload",
        "kind": "LinkedField",
        "name": "markNotificationRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationRowFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationRow_MarkNotificationReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkNotificationReadPayload",
        "kind": "LinkedField",
        "name": "markNotificationRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isINotification"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRead",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "permalink",
                    "storageKey": null
                  }
                ],
                "type": "PermalinkNotification",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "708b6aba941c15c6451697f59617d52e",
    "id": null,
    "metadata": {},
    "name": "NotificationRow_MarkNotificationReadMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationRow_MarkNotificationReadMutation(\n  $input: MarkNotificationReadInput!\n) {\n  markNotificationRead(input: $input) {\n    notification {\n      __typename\n      ...NotificationRowFragment\n      id\n    }\n  }\n}\n\nfragment NotificationRowFragment on INotification {\n  __isINotification: __typename\n  __typename\n  ...NotificationRow_BaseFragment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_BaseFragment on INotification {\n  __isINotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotification {\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n"
  }
};
})();

(node as any).hash = "8873afb402e0c83d1303cbfc5f87a341";

export default node;
