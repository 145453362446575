/**
 * @generated SignedSource<<3c6ba7a29ba335927a134311c140be6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_CopyClientAddressButtonFragment$data = {
  readonly location: {
    readonly address: string;
    readonly placeToken: {
      readonly id: string;
      readonly placeId: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverrides_IsDirectSalesFragment" | "ClientBaseFields_ClientOverrides_LocationFragment">;
  readonly " $fragmentType": "ProjectBaseFields_CopyClientAddressButtonFragment";
};
export type ProjectBaseFields_CopyClientAddressButtonFragment$key = {
  readonly " $data"?: ProjectBaseFields_CopyClientAddressButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_CopyClientAddressButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_CopyClientAddressButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PlaceToken",
          "kind": "LinkedField",
          "name": "placeToken",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "placeId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientBaseFields_ClientOverrides_IsDirectSalesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientBaseFields_ClientOverrides_LocationFragment"
    }
  ],
  "type": "IOverridableClient",
  "abstractKey": "__isIOverridableClient"
};

(node as any).hash = "7e2f9f0b735ceae4bc7aa4d786f6ddf8";

export default node;
