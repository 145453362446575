/**
 * @generated SignedSource<<99c52c8ad32c46e7a40d123ae4a3a5c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_WorkScheduleFragment$data = {
  readonly workSchedule: WorkScheduleEnum | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_WorkScheduleFragment";
};
export type SaleProjectFields_WorkScheduleFragment$key = {
  readonly " $data"?: SaleProjectFields_WorkScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_WorkScheduleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_WorkScheduleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workSchedule",
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "93cfdf6c6f3bea3e5f503391b1b623af";

export default node;
