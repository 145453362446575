/** A read-only map that lazily computes its keys and values. */
export class LazyMap<K, V> implements ReadonlyMap<K, V> {
  private readonly _getKeys: () => Iterable<K>;
  private readonly _getValue: (key: K) => V;

  private _keysCache: Set<K> | undefined;
  private readonly _valuesCache: Map<K, V> = new Map();

  constructor(getKeys: () => Iterable<K>, getValue: (key: K) => V) {
    this._getKeys = getKeys;
    this._getValue = getValue;
  }

  private _readKeys(): Set<K> {
    return (this._keysCache ||= new Set(this._getKeys()));
  }

  /** @inheritDoc ReadonlyMap.size */
  get size(): number {
    return this._readKeys().size;
  }

  /** @inheritDoc ReadonlyMap.get */
  get(key: K): V | undefined {
    if (this._valuesCache.has(key)) {
      return this._valuesCache.get(key);
    } else if (this._readKeys().has(key)) {
      const value = this._getValue(key);
      this._valuesCache.set(key, value);
      return value;
    }
    return undefined;
  }

  /** @inheritDoc ReadonlyMap.has */
  has(key: K): boolean {
    return this._readKeys().has(key);
  }

  /** @inheritDoc ReadonlyMap.keys */
  keys(): IterableIterator<K> {
    // While returning `this.readKeys()` would work, it would allow the caller to modify the contents of
    // `this.keysCache` and corrupt the map. Returning `this.readKeys().values()` instead prevents this.
    return this._readKeys().values();
  }

  /** @inheritDoc ReadonlyMap.values */
  *values(): IterableIterator<V> {
    // Use a generator to potentially avoid loading some values into the map if iteration is stopped before the end.
    for (const key of this._readKeys()) {
      yield this.get(key) as V;
    }
  }

  /** @inheritDoc ReadonlyMap.entries */
  *entries(): IterableIterator<[K, V]> {
    // Use a generator to potentially avoid loading some values into the map if iteration is stopped before the end.
    for (const key of this._readKeys()) {
      yield [key, this.get(key) as V];
    }
  }

  /** @inheritDoc ReadonlyMap[Symbol.iterator] */
  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.entries();
  }

  /** @inheritDoc ReadonlyMap.forEach */
  forEach(callbackfn: (value: V, key: K, map: ReadonlyMap<K, V>) => void, thisArg?: unknown): void {
    for (const [key, value] of this) {
      callbackfn.call(thisArg, value, key, this);
    }
  }
}
