/**
 * @generated SignedSource<<cb20c87d7d33e7f8e6a3c4074f20350e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_ReadyDateFragment$data = {
  readonly readyDate?: string | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_ReadyDateFragment";
};
export type SaleProjectFields_ReadyDateFragment$key = {
  readonly " $data"?: SaleProjectFields_ReadyDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_ReadyDateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCopy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_ReadyDateFragment",
  "selections": [
    {
      "condition": "isCopy",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readyDate",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "0c6517218a76cc67e13b8b5fd0b3cb5f";

export default node;
