import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  groupBySymbol,
} from './PaginatedAutocomplete';
import { useTranslation } from 'react-i18next';
import { convertToTsQuery } from '../utils/stringUtils';
import { emptySuggestionPromptInput } from './Suggestions';
import { SelectPickerContentSuggestible } from './SelectPicker';
import { AccessoryTypeAutocompleteFragment$data } from './__generated__/AccessoryTypeAutocompleteFragment.graphql';
import { AccessoryTypeAutocompleteQuery, SuggestionPromptInput } from './__generated__/AccessoryTypeAutocompleteQuery.graphql';

interface AccessoryTypeAutocompleteProps {
  suggestionPromptInput?: SuggestionPromptInput | null;
  suggestible?: boolean;
}

export type ForwardAccessoryTypeAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<AccessoryTypeAutocompleteFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const AccessoryTypeAutocomplete = forwardRef<
  HTMLInputElement,
  ForwardPaginatedAutocompleteProps<
    ConnectionNode<AccessoryTypeAutocompleteFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    boolean,
    boolean,
    ElementType
  >
>(function AccessoryTypeAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    suggestionPromptInput,
    suggestible,
    multiple,
    textFieldProps,
    ...paginatedAutocompleteProps
  }: AccessoryTypeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<AccessoryTypeAutocompleteFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const { t } = useTranslation('serviceCall');
  const env = useRelayEnvironment();

  const handleQuery = useCallback(
    (searchTerm: string | null, suggest: boolean) =>
      fetchQuery<AccessoryTypeAutocompleteQuery>(
        env,
        graphql`
          query AccessoryTypeAutocompleteQuery(
            $searchTerm: String
            $suggestionPrompt: SuggestionPromptInput!
            $suggestionCount: Int!
            $suggestionScoreThreshold: Float!
            $skipSuggestion: Boolean!
          ) {
            ...AccessoryTypeAutocompleteFragment
              @arguments(
                searchTerm: $searchTerm
                suggestionPrompt: $suggestionPrompt
                suggestionCount: $suggestionCount
                suggestionScoreThreshold: $suggestionScoreThreshold
                skipSuggestion: $skipSuggestion
              )
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
          suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
          suggestionCount: 5,
          suggestionScoreThreshold: 0,
          skipSuggestion: !suggest || !suggestible,
        },
      ),
    [env, suggestible, suggestionPromptInput],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<AccessoryTypeAutocompleteFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      groupBy={(o) => o[groupBySymbol]}
      renderListItemContent={(params) => <SelectPickerContentSuggestible {...params} />}
      {...paginatedAutocompleteProps}
      ref={ref}
      fragment={graphql`
        fragment AccessoryTypeAutocompleteFragment on Query
        @refetchable(queryName: "AccessoryTypeAutocompleteFragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          searchCursor: { type: "String" }
          searchCount: { type: "Int", defaultValue: 25 }
          suggestionPrompt: { type: "SuggestionPromptInput!" }
          suggestionCount: { type: "Int!" }
          suggestionScoreThreshold: { type: "Float!" }
          skipSuggestion: { type: "Boolean!" }
        ) {
          searchResults: searchAccessoryTypes(searchTerm: $searchTerm, after: $searchCursor, first: $searchCount)
            @connection(key: "AccessoryTypeAutocompleteFragment_searchResults") {
            edges {
              node {
                id
                label
                code
              }
            }
          }
          suggestions: suggestedAccessoryTypes(
            prompt: $suggestionPrompt
            count: $suggestionCount
            scoreThreshold: $suggestionScoreThreshold
          ) @skip(if: $skipSuggestion) {
            score
            value {
              id
              label
              code
            }
          }
        }
      `}
      onQuery={handleQuery}
      multiple={multiple}
      textFieldProps={(params) => {
        const p = textFieldProps?.(params);
        return {
          ...p,
          placeholder: t('button.select', { ns: 'common' }),
        };
      }}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AccessoryTypeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<AccessoryTypeAutocompleteFragment$data>,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
