import { styled, TextField } from '@mui/material';

export const BorderlessTextField = styled(TextField, { shouldForwardProp: () => true })(({ theme }) => ({
  '& fieldset': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    height: '100%',
  },
  '& .Mui-error': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 0,
    borderColor: theme.palette.error.main,
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
