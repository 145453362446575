/**
 * @generated SignedSource<<207947d31af97214002bf04f874daa5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenuFragmentQuery$variables = Record<PropertyKey, never>;
export type NotificationMenuFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NotificationMenuFragment">;
};
export type NotificationMenuFragmentQuery = {
  response: NotificationMenuFragmentQuery$data;
  variables: NotificationMenuFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isINotification"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRead",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permalink",
      "storageKey": null
    }
  ],
  "type": "PermalinkNotification",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationMenuFragmentQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NotificationMenuFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationMenuFragmentQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v0/*: any*/),
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "createdAt": "DESC"
            }
          }
        ],
        "concreteType": "NotificationsConnection",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "notifications(first:100,order:{\"createdAt\":\"DESC\"})"
      },
      {
        "alias": "unreadNotifications",
        "args": [
          (v0/*: any*/),
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "isRead": {
                "eq": false
              }
            }
          }
        ],
        "concreteType": "NotificationsConnection",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "notifications(first:100,where:{\"isRead\":{\"eq\":false}})"
      }
    ]
  },
  "params": {
    "cacheID": "e9add3ce921fba1805e044a373f556f6",
    "id": null,
    "metadata": {},
    "name": "NotificationMenuFragmentQuery",
    "operationKind": "query",
    "text": "query NotificationMenuFragmentQuery {\n  ...NotificationMenuFragment\n}\n\nfragment NotificationMenuFragment on Query {\n  notifications(order: {createdAt: DESC}, first: 100) {\n    nodes {\n      __typename\n      id\n      ...NotificationRowFragment\n    }\n  }\n  unreadNotifications: notifications(where: {isRead: {eq: false}}, first: 100) {\n    nodes {\n      __typename\n      ...NotificationRowFragment\n      id\n    }\n  }\n}\n\nfragment NotificationRowFragment on INotification {\n  __isINotification: __typename\n  __typename\n  ...NotificationRow_BaseFragment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_BaseFragment on INotification {\n  __isINotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotification {\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n"
  }
};
})();

(node as any).hash = "c1fc623da203c4d4cb4b09dfd815530b";

export default node;
