/**
 * @generated SignedSource<<96413b6e14ffee97ba74f21beeae5da6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as craneCapacityLabelResolverType } from "../../../__resolvers__/CraneCapacityLabelResolver";
export type CraneCapacityAutocompleteFragment$data = {
  readonly searchResults: {
    readonly items: ReadonlyArray<{
      readonly result: {
        readonly capacity: number;
        readonly label: string | null | undefined;
      };
    }> | null | undefined;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null | undefined;
  readonly " $fragmentType": "CraneCapacityAutocompleteFragment";
};
export type CraneCapacityAutocompleteFragment$key = {
  readonly " $data"?: CraneCapacityAutocompleteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneCapacityAutocompleteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationKindCode"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "equipmentKindCode"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "skip"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "vehicleIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./craneCapacityAutocompleteFragmentQuery.graphql')
    }
  },
  "name": "CraneCapacityAutocompleteFragment",
  "selections": [
    {
      "alias": "searchResults",
      "args": [
        {
          "kind": "Variable",
          "name": "configurationKindCode",
          "variableName": "configurationKindCode"
        },
        {
          "kind": "Variable",
          "name": "equipmentKindCode",
          "variableName": "equipmentKindCode"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "skip",
          "variableName": "skip"
        },
        {
          "kind": "Literal",
          "name": "take",
          "value": 25
        },
        {
          "kind": "Variable",
          "name": "vehicleIds",
          "variableName": "vehicleIds"
        }
      ],
      "concreteType": "SearchCraneCapacitiesCollectionSegment",
      "kind": "LinkedField",
      "name": "searchCraneCapacities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FullTextSearchResultOfCraneCapacity",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CraneCapacity",
              "kind": "LinkedField",
              "name": "result",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "capacity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CraneCapacityLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/CraneCapacityLabelResolver').label,
                  "path": "searchResults.items.result.label"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionSegmentInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "e97d300e6193ed325eea619b923ba44c";

export default node;
