import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { ServiceCallStatus } from '../../__enums__/ServiceCallStatus';

const dateTime = {
  sevenDays: '7 jours',
  fourteenDays: '14 jours',
  thirtyDays: '30 jours',
  sixtyDays: '60 jours',
};

const field = {
  accessory: {
    accessoryLine_one: "{{ count }} ligne d'accessoire",
    accessoryLine_other: "{{ count }} lignes d'accessoire",
    accessory: 'Accessoire',
    billable: 'Facturable',
    billed: 'Facturé',
    billingCode: 'Nom du code de facturation',
    noAccessory: 'Aucun accessoire',
    overline: 'Accessoires',
    quantity: 'Quantité',
    qty: 'Qté.',
    rate: 'Taux',
    type: 'Type d’accessoire',
  },
  addenda: {
    addenda_one: '{{ count }} addenda',
    addenda_other: '{{ count }} addendas',
    title: 'Titre',
    kind: 'Type',
    description: 'Description',
  },
  attachment: {
    attachment_one: '{{ count }} pièce jointe',
    attachment_other: '{{ count }} pièces jointes',
  },
  client: {
    inTheCareOf: 'A/S',
    orderNumber: '# Commande',
    projectManager: 'Chargé de projet',
    requirement: 'Exigences',
    representative: 'Représentant',
  },
  cost: {
    costLine_one: '{{ count }} ligne de coût',
    costLine_other: '{{ count }} lignes de coût',
    overline: 'Coûts',
    billingCode: 'Nom du code de facturation',
    billable: 'Facturable',
    crane: 'Grue',
    labor: 'Main d’oeuvre',
    noLine: 'Aucune ligne',
    quantity: 'Quantité',
    qty: 'Qté.',
    rate: 'Taux',
  },
  equipment: {
    addConfiguration: 'Ajouter une configuration',
    boom: 'Flèche',
    capacity: 'Capacité',
    configuration: 'Configuration',
    boomConfiguration: 'Configuration de flèche',
    configurationOption: 'Option de configuration',
    counterWeight: 'Contrepoids',
    craneConfiguration: 'Configuration de grue',
    jib: 'Fléchette',
    kind: 'Genre d’équipement',
    maxWeight: 'Poids de la charge',
    model: 'Modèle',
    noReplacement: 'Aucun remplacement',
    noSubcontractor: 'Aucun sous-traitant',
    offset: 'Offset',
    otherInformation: 'Autres informations',
    radius: 'Rayon',
    type: 'Genre',
    lifts: 'Levages',
    configurationList: 'Liste de configurations',
    craneSelectionMode: 'Sélection de la grue',
  },
  project: {
    branch: {
      dispatchBranch: 'Succursale de répartition',
      overline: 'Succursales',
      salesBranch: 'Succursale de ventes',
    },
    date: {
      arrival: 'Arrivée',
      date: 'Date',
      departure: 'Départ',
      duration: 'Durée des travaux',
      durationPerDay: 'Durée par jour',
      isSecondServing: '2e tablée',
      includeWeekendsAndHolidays: 'Inclure fin de semaine et fériés',
      notes: 'Notes et informations complémentaires',
      ready: 'Prête',
    },
    work: {
      isEstablishedSchedule: 'Horaire établi',
      natureOfWork: 'Nature du travail',
      natureOfWorkSubCategory: 'Catégorie',
      overline: 'Travail',
      workDescription: 'Description du Travail',
      workSchedule: 'Horaire de travail',
    },
  },
  questions: {
    hasParticularSituation_one: '{{ count }} situation particulière',
    hasParticularSituation_other: '{{ count }} situations particulières',
    hasNoParticularSituation: 'Aucune situation particulière',
    worksiteVisited: 'Chantier visité',
    worksiteNotVisited: 'Chantier non visité',
  },
};

const details = {
  section: {
    accessories: 'Accessoires',
    addenda: 'Addenda',
    client: 'Client',
    costLines: 'Lignes de coût',
    equipment: 'Équipement',
    project: 'Projet',
    questions: 'Questions',
    selectedCrane: 'Grue sélectionnée',
  },
  subsection: {
    branches: 'Succursales',
    dateTime: 'Date & Heures',
    otherInformation: 'Autres informations',
    work: 'Travail',
  },
};

const placeholder = {
  accessoryInfo: 'Sélectionnez des accessoires (facultatif)',
  accessoryOutOfInventory: 'Écrire l’accessoire',
  addAddenda: 'Ajouter un addenda',
  addendaInfo: 'Ajouter des addenda (facultatif)',
  clientInfo: 'Entrez les informations du client',
  equipmentInfo: 'Entrez les informations de l’équipement',
  projectInfo: 'Entrez les informations du projet',
  questionsInfo: 'Répondre aux questions de base',
  costsInfo: 'Entrez les informations des coûts',
  selectConfiguration: 'Sélectionner une configuration',
};

const status: Record<ServiceCallStatus, string> = {
  archived: 'Archivé',
  canceled: 'Annulé',
  delayed: 'Reporté',
  dispatched: 'Réparti',
  inWriting: 'En saisie',
  reserved: 'Réservé',
  inModification: 'En modification',
  transferred: 'Transféré',
  locked: 'Verrouillé',
};

const list = {
  column: {
    friendlyId: 'ID',
    client: 'Client',
    worksite: 'Chantier',
    kind: 'Type',
    crane: 'Grue',
    status: 'Statut',
    date: 'Date',
    poNumber: '# Commande',
  },
};

const kind: Record<ServiceCallKind, string> = {
  accessoriesRental: 'Location d’accessoires',
  bare: 'Grue non-opérée',
  laborRental: 'Location de main d’oeuvre',
  liftingPlan: 'Plan de levage',
  liftingTest: 'Test de levage',
  operatedHourly: 'Grue opérée (à l’heure)',
  operatedMonthly: 'Grue opérée (mensuelle)',
  rollingEquipment: 'Matériel roulant',
};

const kindShort: Record<ServiceCallKind, string> = {
  accessoriesRental: 'Location d’accessoires',
  bare: 'Non-opérée',
  laborRental: 'Location de main d’oeuvre',
  liftingPlan: 'Plan de levage',
  liftingTest: 'Test de levage',
  operatedHourly: 'Opérée (à l’heure)',
  operatedMonthly: 'Opérée (mensuelle)',
  rollingEquipment: 'Matériel roulant',
};

const kindAria: Record<ServiceCallKind, string> = {
  accessoriesRental: 'Créer un appel de service au type location d’accessoires',
  bare: 'Créer un appel de service au type grue non-opérée',
  laborRental: 'Créer un appel de service au type location de main d’oeuvre',
  liftingPlan: 'Créer un appel de service au type plan de levage',
  liftingTest: 'Créer un appel de service au type test de levage',
  operatedHourly: 'Créer un appel de service au type grue opérée (à l’heure)',
  operatedMonthly: 'Créer un appel de service au type grue opérée (mensuelle)',
  rollingEquipment: 'Créer un appel de service au type matériel roulant',
};

const kindCapitalized: Record<ServiceCallKind, string> = {
  accessoriesRental: 'LOCATION D’ACCESSOIRES',
  bare: 'GRUE NON-OPÉRÉE',
  laborRental: 'LOCATION DE MAIN D’OEUVRE',
  liftingPlan: 'PLAN DE LEVAGE',
  liftingTest: 'TEST DE LEVAGE',
  operatedHourly: 'GRUE OPÉRÉE (À L’HEURE)',
  operatedMonthly: 'GRUE OPÉRÉE (MENSUELLE)',
  rollingEquipment: 'MATÉRIEL ROULANT',
};

const createServiceCallAction = {
  main: kindCapitalized.operatedHourly,
  options: kindCapitalized,
  ariaLabels: {
    main: kindAria.operatedHourly,
    more: 'Montrer les options',
    options: kindAria,
  },
};

export const serviceCall = {
  addenda: 'Addenda',
  button: {
    createLinkedServiceCall: 'Créer un appel lié',
    saveLinked: 'Enregistrer et lier',
    linkServiceCall: 'Lier un appel existant',
    unlink: 'Dissocier',
  },
  costs: 'Coûts',
  createServiceCallAction,
  dateTime,
  details,
  dialog: {
    delay: {
      title: 'Reporter l’appel de service',
      body: 'Êtes-vous certain de vouloir reporter cet appel de service\u00a0?',
      confirmButton: 'Ok',
    },
    transfer: {
      allTransferred: 'Attention, tous les appels de services liés seront envoyés en répartition suite au transfert.',
      confirmTransfer: 'Transfert des appels liés',
    },
    notLinkedServiceCalls: 'Disponibles pour liaison',
    alreadyLinkedServiceCalls: 'Déjà liés',
    unlinkCurrentServiceCall: 'Êtes-vous certain de vouloir dissocier l’appel de service courant\u00a0?',
    unlinkServiceCall: 'Êtes-vous certain de vouloir dissocier cet appel de service\u00a0?',
    unlink: 'Dissocier l’appel de service',
  },
  error: {
    arrivalDateBeforeDepartureDate: 'La date d’arrivée ne peut pas être avant la date de départ',
    cannotTransfer: 'Impossible de transférer',
    clientIsRequired: 'Veuillez sélectionner un client',
    dateInThePast: 'La date ne peut pas être dans le passé',
    errorDuringDelay: 'Une erreur s’est produite pendant le report',
    errorDuringLink: 'Une erreur s’est produite pendant la liaison',
    errorDuringTransfer: 'Une erreur s’est produite pendant le transfert',
    errorDuringUnlink: 'Une erreur s’est produite pendant la dissociation',

    // TODO: Maybe move in common and use a "type" provider using a placeholder ?
    fieldCannotBeEmpty: 'Ce champ ne peut pas être vide',
    readyDateBeforeArrivalDate: 'La date prête ne peut pas être avant la date d’arrivée',
    valueBetweenMinAndMax: 'La valeur doit être entre {{ min }} et {{ max }}',
    worksiteIsRequired: 'Veuillez sélectionner un chantier',
  },
  field,
  search: 'Rechercher un Id, client ou chantier…',
  info: 'Infos',
  pages: {
    accessories: {
      title: 'Accessoires',
    },
    addenda: {
      title: 'Addenda',
    },
    addendaAndAttachments: {
      title: 'Addenda et pièces jointes',
    },
    client: {
      title: 'Informations du client',
      requirementDescriptionsTitle: 'Exigences client',
    },
    costLine: {
      title: 'Lignes de coût',
    },
    costs: {
      title: 'Lignes de coût',
      subsection: {
        additionalCranes: 'Grues additionnelles',
        mainCrane: 'Grue principale',
        emptyCraneConfiguration: 'Aucune configuration sélectionnée',
      },
    },
    details: {
      copyTitle: 'Copier l’appel de service',
      copyTitleShort: 'Copier',
      linkTitle: 'Nouvel appel lié',
      newTitle: 'Nouvel appel',
      shortTitle: 'Modifier',
      title: 'Modifier l’appel',
    },
    equipment: {
      title: 'Informations de l’équipement',
      crane: {
        title: 'Sélecteur de grue',
      },
      subsection: {
        craneSelector: 'Sélecteur de grue',
      },
    },
    linked: {
      title: 'Appels liés',
    },
    list: {
      title: 'Appels de service',
    },
    project: {
      title: 'Informations du projet',
      requirementDescriptionsTitle: 'Exigences du chantier',
      subsection: {
        branches: 'Succursales',
        dateAndTime: 'Dates et heures',
        work: 'Travail',
        worksite: 'Chantier',
      },
    },
    questions: {
      title: 'Questions',
    },
  },
  section: {
    accessories: 'Accessoires',
    addenda: 'Addenda',
    addendaAndAttachments: 'Addendas et pièces jointes',
    client: 'Client',
    costLines: 'Lignes de coût',
    costs: 'Coûts',
    equipment: 'Équipement',
    linked: 'Appels de service',
    project: 'Projet',
    questions: 'Questions',
    transport: 'Transport',
  },
  kind,
  kindShort,
  kindAria,
  kindCapitalized,
  placeholder,
  status,
  list,
};
