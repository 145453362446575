import { Select, SelectProps, styled } from '@mui/material';

const AdornmentSelectInternal = styled(Select)(({ theme }) => ({
  borderRadius: '0.25rem',
  paddingLeft: '0.5rem',
  '::before': { content: 'none' },
  '::after': { content: 'none' },
  ':hover': {
    backgroundColor: theme.palette.grey.A100,
  },
  '.MuiInput-input:focus': {
    backgroundColor: 'unset',
  },
}));

export function AdornmentSelect<T extends string>(props: SelectProps<T>) {
  return <AdornmentSelectInternal {...(props as SelectProps)} />;
}
