import * as Sentry from '@sentry/react';

export interface ILogger {
  trace(message?: unknown, ...optionalParams: unknown[]): void;
  debug(message?: unknown, ...optionalParams: unknown[]): void;
  log(message?: unknown, ...optionalParams: unknown[]): void;
  info(message?: unknown, ...optionalParams: unknown[]): void;
  warn(message?: unknown, ...optionalParams: unknown[]): void;
  error(message?: unknown, ...optionalParams: unknown[]): void;

  group(...label: unknown[]): void;
  groupEnd(): void;
}

export const logLevels = ['trace', 'debug', 'log', 'info', 'warn', 'error'] as const;
export type LogLevel = (typeof logLevels)[number];

export type GlobalLogLevel = { LOG_LEVEL?: LogLevel };

export type MarkerFn = (target: LogLevel | 'group', message: unknown, ...params: unknown[]) => string | null;
export class Logger implements ILogger {
  private _minLevel: LogLevel | undefined;
  public get minLevel(): LogLevel {
    if (this._minLevel == null) {
      return (window as GlobalLogLevel)?.LOG_LEVEL ?? (process.env.NODE_ENV === 'development' ? 'trace' : 'info');
    }
    return this._minLevel;
  }
  public set minLevel(value: LogLevel) {
    this._minLevel = value;
  }

  private readonly markers: unknown[];

  constructor(
    private readonly sink: ILogger,
    ...markers: (unknown | MarkerFn)[]
  ) {
    this.markers = markers;
  }

  trace(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('trace', message, ...optionalParams);
  }
  debug(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('debug', message, ...optionalParams);
  }
  log(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('log', message, ...optionalParams);
  }
  info(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('info', message, ...optionalParams);
  }
  warn(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('warn', message, ...optionalParams);
  }
  error(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('error', message, ...optionalParams);
  }

  private forward(target: LogLevel, message: unknown, ...params: unknown[]) {
    if (logLevels.indexOf(target) < logLevels.indexOf(this.minLevel)) {
      return;
    }

    const effectiveParams = [...this.buildTags(target, message, ...params), message, ...params];
    this.sink[target](...effectiveParams);
  }

  group(...label: unknown[]): void {
    const [lbl, ...rest] = label;
    this.sink.group(...this.buildTags('group', lbl, ...rest), ...label);
  }
  groupEnd(): void {
    this.sink.groupEnd();
  }

  private buildTags(target: LogLevel | 'group', message: unknown, ...params: unknown[]) {
    return this.markers
      .map((m) => (typeof m === 'function' ? m(target, message, ...params) : m))
      .filter((m) => m != null)
      .map((m) => `[${m}]`);
  }
}

export const defaultLogger = new Logger(console);

export class WrapSink implements ILogger {
  constructor(private readonly sinks: ILogger[]) {}

  trace(message?: unknown, ...optionalParams: unknown[]): void {
    for (const sink of this.sinks) {
      sink.trace(message, ...optionalParams);
    }
  }
  debug(message?: unknown, ...optionalParams: unknown[]): void {
    for (const sink of this.sinks) {
      sink.debug(message, ...optionalParams);
    }
  }
  log(message?: unknown, ...optionalParams: unknown[]): void {
    for (const sink of this.sinks) {
      sink.log(message, ...optionalParams);
    }
  }
  info(message?: unknown, ...optionalParams: unknown[]): void {
    for (const sink of this.sinks) {
      sink.info(message, ...optionalParams);
    }
  }
  warn(message?: unknown, ...optionalParams: unknown[]): void {
    for (const sink of this.sinks) {
      sink.warn(message, ...optionalParams);
    }
  }
  error(message?: unknown, ...optionalParams: unknown[]): void {
    for (const sink of this.sinks) {
      sink.error(message, ...optionalParams);
    }
  }
  group(...label: unknown[]): void {
    for (const sink of this.sinks) {
      sink.group(...label);
    }
  }
  groupEnd(): void {
    for (const sink of this.sinks) {
      sink.groupEnd();
    }
  }
}

export class SentrySink implements ILogger {
  trace(message?: unknown, ...optionalParams: unknown[]): void {
    this.capture('debug', message, optionalParams);
  }
  debug(message?: unknown, ...optionalParams: unknown[]): void {
    this.capture('debug', message, optionalParams);
  }
  log(message?: unknown, ...optionalParams: unknown[]): void {
    this.capture('log', message, optionalParams);
  }
  info(message?: unknown, ...optionalParams: unknown[]): void {
    this.capture('info', message, optionalParams);
  }
  warn(message?: unknown, ...optionalParams: unknown[]): void {
    this.capture('warning', message, optionalParams);
  }
  error(message?: unknown, ...optionalParams: unknown[]): void {
    this.capture('error', message, optionalParams);
  }
  group(): void {
    // Not implemented for now
  }
  groupEnd(): void {
    // Not implemented for now
  }

  private capture(logLevel: Sentry.SeverityLevel, message: unknown, optionalParams: unknown[]) {
    Sentry.captureMessage(`${message}`, (scope) => {
      scope.setLevel(logLevel);
      for (const key in optionalParams) {
        scope.setExtra(key, optionalParams[key]);
      }
      return scope;
    });
  }
}

export const sentryLogger = new SentrySink();
