/**
 * @generated SignedSource<<2e0e4467a6d2a73133924fbf009b6876>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlobStatus = "error" | "processing" | "received" | "success" | "uploading" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CraneChartListItemFragment$data = {
  readonly byteCount: any;
  readonly capacity: number | null | undefined;
  readonly craneChart: {
    readonly id: string;
  } | null | undefined;
  readonly craneChartId: string | null | undefined;
  readonly equipmentKind: {
    readonly abbreviation: string;
  } | null | undefined;
  readonly id: string;
  readonly make: string | null | undefined;
  readonly model: string | null | undefined;
  readonly name: string;
  readonly status: BlobStatus;
  readonly updatedAt: string;
  readonly " $fragmentSpreads": FragmentRefs<"CraneChartListActionsFragment">;
  readonly " $fragmentType": "CraneChartListItemFragment";
};
export type CraneChartListItemFragment$key = {
  readonly " $data"?: CraneChartListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneChartListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneChartListItemFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneChartListActionsFragment"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "byteCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "craneChartId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CraneChart",
      "kind": "LinkedField",
      "name": "craneChart",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "make",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentKindLookup",
      "kind": "LinkedField",
      "name": "equipmentKind",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "abbreviation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    }
  ],
  "type": "CraneChartBlobMetadata",
  "abstractKey": null
};
})();

(node as any).hash = "149a7c8dab55096dda72a6404520d6a5";

export default node;
