import { useRefSync } from './useRefSync';
import { useEffect, useState } from 'react';

/**
 * Delay the change of a value by a certain amount.
 * @param value The value to delay.
 * @param delay How long should the value be delayed before being returned.
 * @param bypassPredicate An optional predicate for values that should bypass the stickiness delay.
 */
export function useSticky<T>(value: T, delay: number, bypassPredicate: (v: T) => boolean = () => false): T {
  const delayRef = useRefSync(delay);

  const [activeValue, setActiveValue] = useState(value);

  const bypassRef = useRefSync(bypassPredicate(value));

  // When bypassing, skip the useEffect so that we produce the new state in the same render cycle.
  if (!Object.is(value, activeValue) && bypassRef.current) {
    setActiveValue(value);
  }

  useEffect(() => {
    // No need to trigger an update since we already updated the value in the render cycle.
    if (bypassRef.current) {
      return;
    }

    const timer = setTimeout(() => setActiveValue(value), delayRef.current);
    return () => clearTimeout(timer);
  }, [bypassRef, delayRef, value]);

  return activeValue;
}
