/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const powerlineProximities = ["from126KVTo250KV", "from251KVTo550KV", "lessThan126KV", "moreThan550KV"] as const;
export type PowerlineProximity = typeof powerlineProximities[number];

export function isPowerlineProximity(value: string): value is PowerlineProximity {
  return powerlineProximities.includes(value)
}

export function asPowerlineProximity(value: string): PowerlineProximity | null {
  return isPowerlineProximity(value) ? value : null;
}

export function castPowerlineProximity(value: string): PowerlineProximity {
  if(!isPowerlineProximity(value)) {
    throw new Error(`Invalid Enum value for type "PowerlineProximity": "${value}"`);
  }
  
  return value;
}
