import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  groupBySymbol,
  Queryable,
} from './PaginatedAutocomplete';
import { RepresentativeAutocompleteQuery, SuggestionPromptInput } from './__generated__/RepresentativeAutocompleteQuery.graphql';
import { convertToTsQuery } from '../utils/stringUtils';
import { emptySuggestionPromptInput } from './Suggestions';
import { RepresentativeAutocompleteFragment$data } from './__generated__/RepresentativeAutocompleteFragment.graphql';
import { SelectPickerContentSuggestible } from './SelectPicker';

interface RepresentativeAutocompleteProps {
  suggestible?: boolean;
  suggestionPromptInput?: SuggestionPromptInput | null;
}

export type ForwardRepresentativeAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<RepresentativeAutocompleteFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const RepresentativeAutocomplete = forwardRef<
  HTMLInputElement,
  RepresentativeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<RepresentativeAutocompleteFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      boolean,
      boolean,
      ElementType
    >
>(function RepresentativeAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    suggestible,
    suggestionPromptInput,
    multiple,
    ...paginatedAutocompleteProps
  }: RepresentativeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<RepresentativeAutocompleteFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const env = useRelayEnvironment();

  const handleQuery = useCallback(
    (searchTerm: string | null, suggest: boolean) =>
      fetchQuery<RepresentativeAutocompleteQuery>(
        env,
        graphql`
          query RepresentativeAutocompleteQuery(
            $searchTerm: String
            $suggestionPrompt: SuggestionPromptInput!
            $suggestionCount: Int!
            $suggestionScoreThreshold: Float!
            $skipSuggestion: Boolean!
          ) {
            ...RepresentativeAutocompleteFragment
              @arguments(
                searchTerm: $searchTerm
                suggestionPrompt: $suggestionPrompt
                suggestionCount: $suggestionCount
                suggestionScoreThreshold: $suggestionScoreThreshold
                skipSuggestion: $skipSuggestion
              )
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
          suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
          suggestionCount: 5,
          suggestionScoreThreshold: 0,
          skipSuggestion: !suggest || !suggestible,
        },
      ),
    [env, suggestionPromptInput, suggestible],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<RepresentativeAutocompleteFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      groupBy={(o) => o[groupBySymbol]}
      renderListItemContent={(params) => <SelectPickerContentSuggestible {...params} />}
      {...paginatedAutocompleteProps}
      ref={ref as Ref<HTMLInputElement & Queryable>}
      fragment={graphql`
        fragment RepresentativeAutocompleteFragment on Query
        @refetchable(queryName: "RepresentativeAutocompleteFragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          searchCursor: { type: "String" }
          searchCount: { type: "Int", defaultValue: 25 }
          suggestionPrompt: { type: "SuggestionPromptInput!" }
          suggestionCount: { type: "Int!" }
          suggestionScoreThreshold: { type: "Float!" }
          skipSuggestion: { type: "Boolean!" }
        ) {
          searchResults: searchRepresentatives(searchTerm: $searchTerm, after: $searchCursor, first: $searchCount)
            @connection(key: "RepresentativeAutocompleteFragment_searchResults") {
            edges {
              node {
                id
                label
                deletedAt
              }
            }
          }
          suggestions: suggestedRepresentatives(
            prompt: $suggestionPrompt
            count: $suggestionCount
            scoreThreshold: $suggestionScoreThreshold
          ) @skip(if: $skipSuggestion) {
            score
            value {
              id
              label
              deletedAt
            }
          }
        }
      `}
      onQuery={handleQuery}
      multiple={multiple}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: RepresentativeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<RepresentativeAutocompleteFragment$data>,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
