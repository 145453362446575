/**
 * @generated SignedSource<<a8ac3c58d2fc1f4a67c4910207fa1682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TabDataSyncDeleteAllMutation$variables = Record<PropertyKey, never>;
export type TabDataSyncDeleteAllMutation$data = {
  readonly deleteAllData: {
    readonly boolean: boolean | null | undefined;
  };
};
export type TabDataSyncDeleteAllMutation = {
  response: TabDataSyncDeleteAllMutation$data;
  variables: TabDataSyncDeleteAllMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "safety": true
        }
      }
    ],
    "concreteType": "DeleteAllDataPayload",
    "kind": "LinkedField",
    "name": "deleteAllData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": "deleteAllData(input:{\"safety\":true})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabDataSyncDeleteAllMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabDataSyncDeleteAllMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4611746ced44a7f2a142cc82dc4b3e5b",
    "id": null,
    "metadata": {},
    "name": "TabDataSyncDeleteAllMutation",
    "operationKind": "mutation",
    "text": "mutation TabDataSyncDeleteAllMutation {\n  deleteAllData(input: {safety: true}) {\n    boolean\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee3eb4b873ce8ca5c8093db94ea5d079";

export default node;
