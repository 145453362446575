/**
 * @generated SignedSource<<230a26abeeebc4b0ce6af4cdba987a38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleCraneSelectorFields_Manual_OffsetAngleFragment$data = {
  readonly offsetAngle: string | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_Manual_OffsetAngleFragment";
};
export type SaleCraneSelectorFields_Manual_OffsetAngleFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_Manual_OffsetAngleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_Manual_OffsetAngleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_Manual_OffsetAngleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offsetAngle",
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "ee777c60c192dd24dcfd01312aef6692";

export default node;
