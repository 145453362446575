const button = {
  copyFromClient: 'Copy the client address',
  selectWorksite: 'Select a worksite',
};

const dialog = {
  addWorksite: 'New worksite',
  selectWorksite: 'Select a worksite',
  noResult: 'No result found',
  loading: 'Loading...',
  automaticSuggestions: 'Automatic Suggestions',
  allWorksites: 'All worksites',
  clientWorksites: 'Client worksites',
  requirementsTitle: 'Worksite requirements',
};

const error = {
  worksiteIsRequired: 'Please select a worksite',
  errorWhileLoading: 'An error occurred while loading the worksite',
};

const field = {
  address: 'Worksite Address',
  contact: 'Worksite Contact',
  name: 'Worksite Name',
  phoneNumber: 'Phone',
  requirement: 'Worksite Requirement',
};

const placeholder = {
  noWorksiteSelected: 'None',
};

export const worksite = {
  button,
  dialog,
  error,
  field,
  new: 'New',
  placeholder,
  work: 'Worksite',
};
