const addenda = {
  delete: 'Delete Addendum',
  deleteMessage: 'Do you want to delete this addendum?',
  edit: 'Edit Addendum',
  field: {
    templateEn: 'Description EN',
    templateFr: 'Description FR',
    kinds: 'Kinds',
    natureOfWorks: 'Natures of work',
    natureOfWorkSubCategory: 'Nature of work subcategories',
    specialSituation: 'Particular situations',
    specialProjects: 'Special projects',
    kind: 'Type',
    title: 'Title',
    titleEn: 'Title EN',
    titleFr: 'Title FR',
  },
  information: 'Information',
  conditions: 'Conditions',
  new: 'New Addendum',
};

const section = {
  addenda: 'Addenda',
  craneCharts: 'Crane Charts',
  competitors: 'Competitors',
  dataSync: 'Data Sync.',
  enums: 'Enums.',
  rateStrategies: 'Rate Strategies',
};

const dialog = {
  title: 'Deactivate Chart',
  body: 'All the data related to this crane chart will be deleted',
  confirmButton: 'Deactivate',
};

const craneChartList = {
  column: {
    name: 'Name',
    make: 'Make',
    model: 'Model',
    equipmentKind: 'Kind',
    capacity: 'Capacity',
    updatedAt: 'Updated at',
    isActive: 'Active',
    status: 'Status',
  },
};

const competitors = {
  title: 'Competitors',
  name: 'Competitor Name',
  placeholder: 'Enter a competitor name',
};

const seeding = {
  button: 'Seed all data',
  buttonGenerated: 'Seed generated data',
  buttonImported: 'Seed imported data',
  fail: 'Unable to seed data check console for error',
  success: 'Data seeded successfully',
  confirmationText: 'Do you want to seed all the data?',
  confirmationButton: 'Seed',
  title: 'Confirmation',
};

const synchronization = {
  title: 'Confirmation',
  confirmationText: 'Do you want to resync {{entity}}',
  confirmationButton: 'Sync',
  success: 'Data sync request sent successfully',
  fail: 'Unable to send the sync request for {{entity}}',
};

const deleting = {
  title: 'Confirmation',
  confirmationText: 'Do you want to delete all the data',
  confirmButton: 'Delete',
  button: 'Delete all data',
  success: 'Data deleted successfully',
  fail: 'Unable to delete data check console for error',
};

const dataSyncSection = {
  deleteSectionTitle: 'Delete Data',
  seedSectionTitle: 'Seed Data',
  syncSectionTitle: 'Sync Data',
  deleting,
  seeding,
  synchronization,
};

const enums = {
  billingCode: {
    code: 'Code',
    description: 'Description',
    labelEn: 'English Label',
    labelFr: 'French Label',
    title: 'Billing Codes (Accessories)',
  },
  natureOfWork: {
    code: 'Code',
    defaultWorkSchedule: 'Default work schedule',
    description: 'Description',
    title: 'Natures of Work',
    workSchedules: 'Work schedules',
    natureOfWorkSubCategories: 'Categories',
  },
};

const rateStrategies = {
  labelEn: 'English Label',
  labelFr: 'French Label',
  edit: 'Edit Rate Strategy',
  new: 'New Rate Strategy',
  label: 'Label',
  categories: 'Categories',
  delete: 'Delete Rate Strategy',
  deleteMessage: 'Do you want to delete this rate strategy?',
  transportCategoryKinds: 'Transport Categories',
  rateStrategyCategory: {
    mainCrane: 'Main Crane',
    additionalCrane: 'Additional Crane',
    mainBoomCounterweight: 'Main Boom and Counterweight',
    otherBoomCounterweight: 'Other Boom and Counterweight',
  },
};

export const configuration = {
  addenda,
  configuration: 'Configuration',
  craneCharts: {
    list: craneChartList,
    importSuccessMessage_one: '{{count}} file uploaded successfully.',
    importSuccessMessage_other: '{{count}} files uploaded successfully.',
    importErrorMessage: 'An error occurred while uploading the files.',
    unexpectedError: 'An unexpected error occurred. Please try again.',
  },
  competitors,
  dataSyncSection,
  enums,
  error: {
    duringCraneChartDeactivation: 'An error occured while deactivating the craneChart',
    errorDuringDelete: 'An error occurred while deleting the element',
    errorDuringSaveCompetitor: 'An error occurred while updating the list of competitors',
    errorDuringSaveBillingCodes: 'An error occurred while updating the billing codes',
    errorDuringSaveNatureOfWorks: 'An error occurred while updating the nature of works',
    errorDuringUpload: 'An error occurred while uploading the file',
  },
  rateStrategies,
  section,
  dialog,
};
