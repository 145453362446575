/**
 * @generated SignedSource<<b556328eacf762dcd62741a710fc383c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as natureOfWorkLookupLabelResolverType } from "../../__resolvers__/NatureOfWorkLabelResolver";
export type BillingCodeRuleFieldsNatureOfWorkFragment$data = {
  readonly conditions: {
    readonly natureOfWorkLookups: ReadonlyArray<{
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    }>;
  };
  readonly " $fragmentType": "BillingCodeRuleFieldsNatureOfWorkFragment";
};
export type BillingCodeRuleFieldsNatureOfWorkFragment$key = {
  readonly " $data"?: BillingCodeRuleFieldsNatureOfWorkFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsNatureOfWorkFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFieldsNatureOfWorkFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NatureOfWorkLookup",
          "kind": "LinkedField",
          "name": "natureOfWorkLookups",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NatureOfWorkLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/NatureOfWorkLabelResolver').label,
              "path": "conditions.natureOfWorkLookups.label"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "a889ebc1572381d347bdb258843d86c4";

export default node;
