/**
 * @generated SignedSource<<c1602bc9c53a22fef85840a5d63fe08d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as vehicleIdLabelResolverType } from "../../../__resolvers__/VehicleIdLabelResolver";
export type SaleCraneSelectorFields_VehicleIdFragment$data = {
  readonly vehicleIds: ReadonlyArray<{
    readonly key: string;
    readonly label: string | null | undefined;
    readonly make: string | null | undefined;
    readonly model: string | null | undefined;
  }>;
  readonly " $fragmentType": "SaleCraneSelectorFields_VehicleIdFragment";
};
export type SaleCraneSelectorFields_VehicleIdFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_VehicleIdFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_VehicleIdFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_VehicleIdFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VehicleId",
      "kind": "LinkedField",
      "name": "vehicleIds",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VehicleIdLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/VehicleIdLabelResolver').label,
          "path": "vehicleIds.label"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "make",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "dd7c15e5dc84668162328561949335f1";

export default node;
