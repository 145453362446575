/**
 * @generated SignedSource<<e6ebf9a44a78d24d0ef32b723bdeb09c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverrides_ClientLocationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteLocationInput_ClientFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverrides_ClientLocationFragment";
};
export type ProjectBaseFields_WorksiteOverrides_ClientLocationFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverrides_ClientLocationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_ClientLocationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverrides_ClientLocationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteLocationInput_ClientFragment"
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "0df985e2897c350d30f4a80d37ed203a";

export default node;
