/**
 * @generated SignedSource<<82b6a6c5315365058ab5241c056870ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_WorkDescriptionFragment$data = {
  readonly workDescription: string | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_WorkDescriptionFragment";
};
export type SaleProjectFields_WorkDescriptionFragment$key = {
  readonly " $data"?: SaleProjectFields_WorkDescriptionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_WorkDescriptionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_WorkDescriptionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDescription",
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "4f126fa71f5784680c52115090cd421a";

export default node;
