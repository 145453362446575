/**
 * @generated SignedSource<<a2a5bdb6055a3f905634c5c411ed97c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesRatesRequestInput = {
  arrivalDate: string;
  billingInfos: ReadonlyArray<SalesRatesBillingInfoRequestInput>;
  clientId: string;
  dispatchBranchId: string;
  equipments: ReadonlyArray<SalesRatesEquipmentRequestInput | null | undefined>;
  natureOfWorkCode: number;
  worksiteId?: string | null | undefined;
};
export type SalesRatesBillingInfoRequestInput = {
  billingCodeId?: string | null | undefined;
  craneIndex?: number | null | undefined;
  itemTypeCode?: number | null | undefined;
};
export type SalesRatesEquipmentRequestInput = {
  boomConfigurationId?: string | null | undefined;
  vehicleId?: string | null | undefined;
};
export type AccessoryLinesFields_Item_BusinessRulesQuery$variables = {
  accessoryTypeId: string;
  include: boolean;
  salesRateInput: SalesRatesRequestInput;
  skip: boolean;
};
export type AccessoryLinesFields_Item_BusinessRulesQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment" | "AccessoryLinesFields_SyncBillableWithAccessoryType" | "AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment">;
  };
  readonly salesRates?: {
    readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_useQuantityRateFromSalesRateFragment">;
  };
};
export type AccessoryLinesFields_Item_BusinessRulesQuery = {
  response: AccessoryLinesFields_Item_BusinessRulesQuery$data;
  variables: AccessoryLinesFields_Item_BusinessRulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accessoryTypeId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "include"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "salesRateInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "accessoryTypeId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "salesRateInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessoryLinesFields_Item_BusinessRulesQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v4/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccessoryLinesFields_SyncBillableWithAccessoryType"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment"
                }
              ],
              "type": "AccessoryTypeLookup",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "node"
      },
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "condition": "include",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "SalesRatesResponse",
                "kind": "LinkedField",
                "name": "salesRates",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AccessoryLinesFields_useQuantityRateFromSalesRateFragment"
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccessoryLinesFields_Item_BusinessRulesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNumberMandatory",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isBillable",
                "storageKey": null
              }
            ],
            "type": "AccessoryTypeLookup",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "condition": "include",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "SalesRatesResponse",
                "kind": "LinkedField",
                "name": "salesRates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KeyedSalesRatesResults",
                    "kind": "LinkedField",
                    "name": "results",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "craneIndex",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesRateEnvelope",
                        "kind": "LinkedField",
                        "name": "salesRates",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canEditRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesRate",
                            "kind": "LinkedField",
                            "name": "value",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isFlexiblePrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isAnnualContract",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minimumQuantity",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "price",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesRateError",
                            "kind": "LinkedField",
                            "name": "error",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "code",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c53ce78a435b79a85485c77f30c69b9a",
    "id": null,
    "metadata": {},
    "name": "AccessoryLinesFields_Item_BusinessRulesQuery",
    "operationKind": "query",
    "text": "query AccessoryLinesFields_Item_BusinessRulesQuery(\n  $accessoryTypeId: ID!\n  $salesRateInput: SalesRatesRequestInput!\n  $skip: Boolean!\n  $include: Boolean!\n) {\n  node(id: $accessoryTypeId) {\n    __typename\n    ... on AccessoryTypeLookup {\n      ...AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment\n      ...AccessoryLinesFields_SyncBillableWithAccessoryType\n      ...AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment\n    }\n    id\n  }\n  salesRates(input: $salesRateInput) @include(if: $include) @skip(if: $skip) {\n    ...AccessoryLinesFields_useQuantityRateFromSalesRateFragment\n  }\n}\n\nfragment AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment on AccessoryTypeLookup {\n  isNumberMandatory\n}\n\nfragment AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment on AccessoryTypeLookup {\n  ...AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment\n  ...AccessoryLinesFields_SyncBillableWithAccessoryType\n  ...AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment\n}\n\nfragment AccessoryLinesFields_SyncBillableWithAccessoryType on AccessoryTypeLookup {\n  isBillable\n}\n\nfragment AccessoryLinesFields_useQuantityRateFromSalesRateFragment on SalesRatesResponse {\n  results {\n    craneIndex\n    salesRates {\n      canEditRate\n      value {\n        isFlexiblePrice\n        isAnnualContract\n        minimumQuantity\n        price\n        createdAt\n      }\n      error {\n        code\n        description\n      }\n    }\n  }\n}\n\nfragment AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment on AccessoryTypeLookup {\n  isNumberMandatory\n}\n"
  }
};
})();

(node as any).hash = "3a9730a5b4b5b151050e6388111eaf69";

export default node;
