import { Box, SvgIcon, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

const compactWidth = '6.25rem';
const fullWidth = '13.625rem';
const notchWidth = '1.1875rem';

export function GuayLogo({ sx }: { sx?: SxProps }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: theme.mixins.toolbar.minHeight,
        width: compactWidth,
        clipPath: `polygon(${notchWidth} 0, ${compactWidth} 0, calc(${compactWidth} - ${notchWidth}) 100%, 0 100%)`,
        [theme.breakpoints.up('sm')]: {
          width: fullWidth,
          clipPath: `polygon(${notchWidth} 0, ${fullWidth} 0, calc(${fullWidth} - ${notchWidth}) 100%, 0 100%)`,
        },
        transition: theme.transitions.create(['clip-path', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.shorter,
        }),
        ...sx,
      }}>
      <SvgIcon
        viewBox='0 0 218 48'
        sx={{
          height: theme.mixins.toolbar.minHeight,
          width: fullWidth,
          '& .extendedLogo': {
            transform: 'translateX(-2rem)',
            opacity: 0,
            [theme.breakpoints.up('sm')]: {
              transform: 'translateX(0px)',
              opacity: 1,
            },
            transition: theme.transitions.create(['transform', 'opacity'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shorter,
            }),
          },
        }}>
        <rect x='0' width='218' height='48' fill={theme.palette.secondary.main} />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M44.0249 11.234C35.8579 11.234 31 12.9509 31 20.2306V27.6493C31 34.9302 35.8599 36.6471 44.0236 36.6471H54.9263C65.1351 36.6471 69.5455 36.304 69.5455 29.1595V22.0852H49.2123V27.238H60.4821V27.9586C60.4821 30.3631 58.1142 30.6718 55.7455 30.6718H46.0673C41.4941 30.6718 40.3098 29.7444 40.3098 25.4866V22.3952C40.3098 18.1361 41.4941 17.2088 46.0667 17.2088H55.909C58.8081 17.2088 60.4815 17.5188 60.4815 19.9908H69.3833V19.2025C69.3833 14.3592 67.9545 11.234 57.6634 11.234H44.0249Z'
          fill='white'
        />
        <g className='extendedLogo'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M82.0373 11.5069H72.97V11.5083V27.5115C72.97 33.6929 76.7688 36.6471 85.0986 36.6471H98.0833C106.414 36.6471 110.212 33.6935 110.212 27.5115V11.5069H101.148V25.8641C101.148 29.0237 99.8001 30.2597 96.0837 30.2597H87.1008C83.385 30.2597 82.0373 29.0231 82.0373 25.8635V11.5069Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M138.728 26.5554H127.148L132.857 17.2274L138.728 26.5554ZM138.769 11.4647H126.984L118.992 23.8801L111 36.2955H121.113L123.765 31.906H142.114L144.927 36.2962H155L146.884 23.8804L138.769 11.4647Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M142.645 10.9845L159.625 27.7926V36.766H169.022V27.7926L186 10.9845H174.654L164.322 21.5251L153.991 10.9845H142.645Z'
            fill='white'
          />
        </g>
      </SvgIcon>
    </Box>
  );
}
