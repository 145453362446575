/**
 * @generated SignedSource<<91acf073ce2cbc04d79815d2a32ce684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as boomConfigurationLabelResolverType } from "../../../__resolvers__/BoomConfigurationLabelResolver";
export type SaleCraneSelectorFields_Manual_BoomConfigurationFragment$data = {
  readonly boomConfiguration: {
    readonly craneConfigurationId: string;
    readonly id: string;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_Manual_BoomConfigurationFragment";
};
export type SaleCraneSelectorFields_Manual_BoomConfigurationFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_Manual_BoomConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_Manual_BoomConfigurationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_Manual_BoomConfigurationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoomConfiguration",
      "kind": "LinkedField",
      "name": "boomConfiguration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "craneConfigurationId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoomConfigurationLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/BoomConfigurationLabelResolver').label,
          "path": "boomConfiguration.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "b5afffc87ada483f67601ce926beea00";

export default node;
