/**
 * @generated SignedSource<<cb4127b71f407f07b93e17756d9d1066>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutsListLayoutToolbarFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsToolbarMainFragment">;
  readonly " $fragmentType": "LayoutsListLayoutToolbarFragment";
};
export type LayoutsListLayoutToolbarFragment$key = {
  readonly " $data"?: LayoutsListLayoutToolbarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsListLayoutToolbarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutsListLayoutToolbarFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsToolbarMainFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9eac387b464a4748e209f149d18d68b1";

export default node;
