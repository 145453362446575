/**
 * @generated SignedSource<<75978e447942c766ab03636e6bee61e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuoteKind = "accessoriesRental" | "bare" | "laborRental" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuoteList_ActionsFragment$data = {
  readonly snapshot: {
    readonly kind: QuoteKind;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SaleList_CopyButtonFragment">;
  readonly " $fragmentType": "QuoteList_ActionsFragment";
};
export type QuoteList_ActionsFragment$key = {
  readonly " $data"?: QuoteList_ActionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteList_ActionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteList_ActionsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleList_CopyButtonFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Quote",
      "kind": "LinkedField",
      "name": "snapshot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuoteJobRevision",
  "abstractKey": null
};

(node as any).hash = "2283bfeb8eacf14ede845c2a171dc886";

export default node;
