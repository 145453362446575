import { Chip, ChipProps, SxProps, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ServiceCallStatus } from '../__enums__/ServiceCallStatus';

interface Props {
  statuses: readonly ServiceCallStatus[];
  compact?: boolean;
}

type ChipIconAndColor = Pick<ChipProps, 'icon' | 'color'>;

const getChipIconAndColor = (status: ServiceCallStatus): ChipIconAndColor => {
  switch (status) {
    case 'inWriting':
      return {
        icon: <EditIcon fontSize='inherit' />,
        color: 'draft',
      };
    case 'reserved':
      return {
        icon: <CheckIcon fontSize='inherit' />,
        color: 'advanced',
      };
    case 'inModification':
      return {
        icon: <EditIcon fontSize='inherit' />,
        color: 'setback',
      };
    case 'transferred':
      return {
        icon: <SendIcon fontSize='inherit' />,
        color: 'done',
      };
    case 'delayed':
      return {
        icon: <SkipNextIcon fontSize='inherit' />,
        color: 'pushback',
      };
    case 'dispatched':
      return {
        icon: <CheckCircleIcon fontSize='inherit' />,
        color: 'archived',
      };
    case 'canceled':
      return {
        icon: <CancelIcon fontSize='inherit' />,
        color: 'cancel',
      };
    case 'archived':
      return {
        icon: <ArchiveIcon fontSize='inherit' />,
        color: 'archived',
      };
    default:
      return {
        icon: undefined,
        color: 'default',
      };
  }
};

const statusPriorities: Record<ServiceCallStatus, number> = {
  canceled: 1,
  dispatched: 2,
  delayed: 3,
  transferred: 4,
  inModification: 5,
  reserved: 6,
  inWriting: 7,
  archived: 8,
  locked: 9,
};

function orderVisibleStatusesByPriority(statuses: readonly ServiceCallStatus[]): ServiceCallStatus[] {
  return statuses.filter((s) => s !== 'locked').sort((a, b) => statusPriorities[a] - statusPriorities[b]);
}

function getMainStatus(statuses: readonly ServiceCallStatus[]): ServiceCallStatus | undefined {
  return orderVisibleStatusesByPriority(statuses)[0];
}

export function ServiceCallStatusChip({ statuses, compact, ...rest }: Props & Omit<ChipProps, 'icon' | 'color' | 'label'>) {
  const { t } = useTranslation('serviceCall');
  const status = getMainStatus(statuses);

  if (!status) return null;

  if (compact) return <ServiceCallStatusIcon status={status} />;

  return <Chip {...getChipIconAndColor(status)} label={t(`status.${status}`)} size='small' {...rest} data-label-key={`status.${status}`} />;
}

function ServiceCallStatusIcon({ status }: { status: ServiceCallStatus }) {
  const theme = useTheme();

  const sx = (bgColor: string, color?: string): SxProps => ({
    backgroundColor: bgColor,
    borderRadius: '1rem',
    border: `0.25rem solid ${bgColor}`,
    color: color,
  });

  switch (status) {
    case 'inWriting':
      return <EditIcon sx={sx(theme.palette.draft.main, theme.palette.draft.contrastText)} />;
    case 'reserved':
      return <CheckIcon sx={sx(theme.palette.advanced.main, theme.palette.advanced.contrastText)} />;
    case 'inModification':
      return <EditIcon sx={sx(theme.palette.setback.main, theme.palette.setback.contrastText)} />;
    case 'transferred':
      return <SendIcon sx={sx(theme.palette.done.main, theme.palette.done.contrastText)} />;
    case 'delayed':
      return <SkipNextIcon sx={sx(theme.palette.pushback.main, theme.palette.pushback.contrastText)} />;
    case 'dispatched':
      return <CheckCircleIcon sx={sx(theme.palette.archived.main, theme.palette.archived.contrastText)} />;
    case 'canceled':
      return <CancelIcon sx={sx(theme.palette.cancel.main, theme.palette.cancel.contrastText)} />;
    case 'archived':
      return <ArchiveIcon sx={sx(theme.palette.archived.main, theme.palette.archived.contrastText)} />;
    default:
      return null;
  }
}
