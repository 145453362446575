import { ClientLanguage } from '../../__enums__/ClientLanguage';

const button = {
  selectClient: 'Select a client',
};

const dialog = {
  selectClient: 'Select a client',
  noResult: 'No result found',
  representative: 'REP',
  loading: 'Loading...',
  automaticSuggestions: 'Automatic Suggestions',
  allClients: 'All clients',
  requirementsTitle: 'Client requirements',
};

const field = {
  address: 'Client Address',
  name: 'Client Name',
  phoneNumber: 'Phone',
  preferredLanguage: 'Preferred Language',
};

const error = {
  clientIsRequired: 'Please select a client',
  errorWhileLoading: 'An error occurred while loading the client',
};

const placeholder = {
  changeLanguageWarning:
    "Changing the client's preferred language will refresh the automatic addenda. If you manually edited one of these, the modifications will be lost. Do you wish to proceed?",
  clientInfo: 'Enter client information',
  noClientSelected: 'None',
  selectClient: 'Select a client',
};

export const client = {
  button,
  client: 'Client',
  directSale: 'Direct Sale',
  sectionHeader: 'Info',
  dialog,
  error,
  language: {
    en: 'English',
    fr: 'French',
  } satisfies Record<ClientLanguage, string>,
  languageShort: {
    en: 'EN',
    fr: 'FR',
  } satisfies Record<ClientLanguage, string>,
  placeholder,
  field,
};
