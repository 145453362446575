import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { VehicleIdLabelResolver$key } from './__generated__/VehicleIdLabelResolver.graphql';

/**
 * @RelayResolver VehicleId.label: String
 * @rootFragment VehicleIdLabelResolver
 *
 * A label for VehicleId which includes displayName.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(vehicleId$key: VehicleIdLabelResolver$key): string {
  const vehicleId$data = readFragment(
    graphql`
      fragment VehicleIdLabelResolver on VehicleId {
        displayName
      }
    `,
    vehicleId$key,
  );
  return vehicleId$data.displayName;
}
