/**
 * @generated SignedSource<<288a14a41492af8ca9f3f48a89cb1404>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BlobStatus = "error" | "processing" | "received" | "success" | "uploading" | "%future added value";
export type TabCraneChartsErrorLogQuery$variables = {
  id: string;
};
export type TabCraneChartsErrorLogQuery$data = {
  readonly node: {
    readonly errorLogUrl?: string;
    readonly status?: BlobStatus;
    readonly storageErrorFileName?: string;
  } | null | undefined;
};
export type TabCraneChartsErrorLogQuery = {
  response: TabCraneChartsErrorLogQuery$data;
  variables: TabCraneChartsErrorLogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storageErrorFileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorLogUrl",
      "storageKey": null
    }
  ],
  "type": "CraneChartBlobMetadata",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TabCraneChartsErrorLogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TabCraneChartsErrorLogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9dd742ab84c9749527cdffd4f5e3b0d",
    "id": null,
    "metadata": {},
    "name": "TabCraneChartsErrorLogQuery",
    "operationKind": "query",
    "text": "query TabCraneChartsErrorLogQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on CraneChartBlobMetadata {\n      status\n      storageErrorFileName\n      errorLogUrl\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a617b9ac6c723d52cc03197af28d0f9c";

export default node;
