import { useMsal } from '@azure/msal-react';
import { Avatar, IconButton, Menu, MenuItem, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '../common/dialogs/ConfirmationDialog';
import { resolvedLanguage } from '../i18n';
import { config } from '../config';
import { DateTime } from 'luxon';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { getAccessToken } from '../RelayEnvironment';
import { findUserEmail } from '../common/utils/findUserEmail';

export function UserMenu({ sx }: { sx?: SxProps<Theme> }) {
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];
  const userEmail = findUserEmail(account);
  const releaseDate = DateTime.fromISO(config.RELEASE_DATE).toFormat(dateFormat);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { t, i18n } = useTranslation('layout');

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    setIsLogoutModalOpen(true);
  };

  const handleLogoutConfirm = () => {
    instance.logoutRedirect({ account });
  };

  const handleChangeLanguage = async () => {
    handleMenuClose();
    const currentLang = resolvedLanguage(i18n);
    await i18n.changeLanguage(currentLang === 'fr' ? 'en' : 'fr');
  };

  const handleCopyToken = async () => {
    handleMenuClose();
    const accessToken = await getAccessToken(instance);
    if (accessToken) {
      await navigator.clipboard.writeText(accessToken);
    }
  };

  const handleCopyTokenWithPrefix = async () => {
    handleMenuClose();
    const accessToken = await getAccessToken(instance);
    if (accessToken) {
      await navigator.clipboard.writeText(`bearer ${accessToken}`);
    }
  };

  return (
    <>
      <IconButton aria-label='userMenu.button' sx={sx} onClick={handleMenuClick} data-label-key='userMenu.button'>
        <Avatar sx={{ height: '2rem', width: '2rem' }}>{account?.name?.substring(0, 2)}</Avatar>
      </IconButton>
      <Menu MenuListProps={{ 'aria-label': 'userMenu' }} id='basic-menu' anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
        <MenuItem sx={{ pointerEvents: 'none' }} divider>
          <Stack>
            <Typography variant='body1'>{account?.name}</Typography>
            <Typography variant='subtitle2'>{userEmail}</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleChangeLanguage} divider>
          {t('app.changeLanguage')}
        </MenuItem>
        <MenuItem onClick={handleLogoutClick} divider>
          {t('auth.logout')}
        </MenuItem>
        {config.SHOW_COPY_TOKEN && <MenuItem onClick={handleCopyToken}>Copy Token</MenuItem>}
        {config.SHOW_COPY_TOKEN && (
          <MenuItem onClick={handleCopyTokenWithPrefix} divider>
            Copy Token with Prefix
          </MenuItem>
        )}
        <Typography variant='subtitle2' sx={{ px: 2, pt: 1 }}>
          {`${t('app.version')} ${config.APP_VERSION} | ${releaseDate}`}
        </Typography>
      </Menu>

      <ConfirmationDialog
        isOpen={isLogoutModalOpen}
        title={t('logoutModal.title')}
        message={t('logoutModal.confirmationText')}
        confirmText={t('logoutModal.confirmButton')}
        confirmColor='secondary'
        onConfirm={handleLogoutConfirm}
        onCancel={() => setIsLogoutModalOpen(false)}
      />
    </>
  );
}
