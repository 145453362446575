/**
 * @generated SignedSource<<9451362ace9197c5ed0fa1617a36e408>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_IsSecondServingFragment$data = {
  readonly isSecondServing: boolean;
  readonly " $fragmentType": "SaleProjectFields_IsSecondServingFragment";
};
export type SaleProjectFields_IsSecondServingFragment$key = {
  readonly " $data"?: SaleProjectFields_IsSecondServingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_IsSecondServingFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_IsSecondServingFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSecondServing",
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "7a9e8c27d4652cf867c851d694fdc0ae";

export default node;
