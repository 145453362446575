/**
 * @generated SignedSource<<3881d24ef602d94e08cafb17bdc50bc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TabDataSyncSeedImportedMutation$variables = Record<PropertyKey, never>;
export type TabDataSyncSeedImportedMutation$data = {
  readonly seedImportedData: {
    readonly boolean: boolean | null | undefined;
  };
};
export type TabDataSyncSeedImportedMutation = {
  response: TabDataSyncSeedImportedMutation$data;
  variables: TabDataSyncSeedImportedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "safety": true
        }
      }
    ],
    "concreteType": "SeedImportedDataPayload",
    "kind": "LinkedField",
    "name": "seedImportedData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": "seedImportedData(input:{\"safety\":true})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabDataSyncSeedImportedMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabDataSyncSeedImportedMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5aadab8c8d6129be2319f226f98c04b3",
    "id": null,
    "metadata": {},
    "name": "TabDataSyncSeedImportedMutation",
    "operationKind": "mutation",
    "text": "mutation TabDataSyncSeedImportedMutation {\n  seedImportedData(input: {safety: true}) {\n    boolean\n  }\n}\n"
  }
};
})();

(node as any).hash = "4aed0a2964f5513c3ea1e6aac19632e8";

export default node;
