import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageToLocale, resolvedLanguage } from '../../i18n';
import { LocalizedNumberFormat } from '../utils/numberUtils';
import { useEffectEvent } from '../utils/effectUtils';

/**
 * A Price is a fixed point number with 2 digits after the radix.
 *
 * Since all numbers in JS are floating point, we use a string to represent the value.
 */
export type Price = string;
/**
 * The internal functions required to produce a component that displays and manipulate a Price.
 */
export type PriceInputHooks = Readonly<{
  /**
   * Take a Price-like value and converts it to a Price.
   * @param value
   */
  priceOrDefault(value: Price | string | number | null | undefined): Price;

  /**
   * Take a Price and format it according to the current locale.
   * @param value
   */
  formatPrice(value: Price): string;

  /**
   * Take a Price and format it according to a standard locale for serialization purposes.
   * @param value
   */
  serializePrice(value: string): Price;

  /**
   * Take a Price-like value and format it according to a standard locale for serialization purposes.
   * @param value
   */
  serializePriceOrDefault(value: Price | string | number | null | undefined): Price;
}>;
/**
 * Provides the building blocks for making your own component to display and manipulate a Price.
 */
export function usePriceInput(): PriceInputHooks {
  const { i18n } = useTranslation();
  const locale = languageToLocale[resolvedLanguage(i18n)];

  const priceOrDefault = useCallback<PriceInputHooks['priceOrDefault']>((value) => {
    if (value == null) {
      return '';
    }

    if (typeof value === 'number' && isNaN(value)) {
      return '';
    }

    return `${value}`.trim();
  }, []);

  const priceFormatter = useMemo(() => new LocalizedNumberFormat(LocalizedNumberFormat.getDescriptor(locale)), [locale]);
  const priceSerializer = useMemo(() => new LocalizedNumberFormat(LocalizedNumberFormat.getUnspecifiedDescriptor()), []);

  const formatPrice = useCallback<PriceInputHooks['formatPrice']>((value) => priceFormatter.reformatFixedPoint(value, 2), [priceFormatter]);
  const serializePrice = useCallback<PriceInputHooks['serializePrice']>(
    (value) => priceSerializer.reformatFixedPoint(value, 2),
    [priceSerializer],
  );

  const serializePriceOrDefault = useCallback<PriceInputHooks['serializePriceOrDefault']>(
    (value) => serializePrice(priceOrDefault(value)),
    [priceOrDefault, serializePrice],
  );

  return useMemo(
    () => ({ priceOrDefault, formatPrice, serializePrice, serializePriceOrDefault }),
    [formatPrice, priceOrDefault, serializePrice, serializePriceOrDefault],
  );
}

export type PriceInputProps = {
  value?: Price | string | number | null | undefined;
  onChange?: (value: Price | null, event: ChangeEvent<HTMLInputElement>) => void;
} & Omit<TextFieldProps, 'value' | 'onChange'>;
/**
 * Displays and manipulates a Price value as a TextField with automatic cleanup and type conversion.
 */
export function PriceInput({ value: inputValue, onChange, onFocus, onBlur, ...rest }: PriceInputProps) {
  const { formatPrice, serializePriceOrDefault } = usePriceInput();

  const [value, setValue] = useState<Price>(() => serializePriceOrDefault(inputValue));
  const [text, setText] = useState<Price>(value);
  const [focused, setFocused] = useState<boolean>(false);

  const formatedValue = useMemo(() => (focused ? text : formatPrice(value)), [focused, formatPrice, text, value]);

  const syncInputValue = useEffectEvent((val: PriceInputProps['value']) => {
    const price = serializePriceOrDefault(val);

    const hasChanged = value !== price;
    if (hasChanged) {
      setValue(price);
      setText(price);
    }
  });
  useEffect(() => {
    syncInputValue(inputValue);
  }, [inputValue, syncInputValue]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setText(event.target.value);
      const price = serializePriceOrDefault(event.target.value);

      if (value !== price) {
        setValue(price);
        onChange?.(price === '' ? null : price, event);
      }
    },
    [onChange, serializePriceOrDefault, value],
  );
  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setFocused(true);
      onFocus?.(event);
    },
    [onFocus],
  );
  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setText(serializePriceOrDefault(text));
      setFocused(false);
      onBlur?.(event);
    },
    [onBlur, serializePriceOrDefault, text],
  );

  return <TextField {...rest} value={formatedValue} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />;
}
