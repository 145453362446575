/**
 * @generated SignedSource<<711bbc1f6dd5a0d22a595f3ea06c94f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarDrawerFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationAdminFragment">;
  readonly " $fragmentType": "SidebarDrawerFragment";
};
export type SidebarDrawerFragment$key = {
  readonly " $data"?: SidebarDrawerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarDrawerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarDrawerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthorizationAdminFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6bbd8e598ae9fa1a32d0526679167031";

export default node;
