import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { RepresentativeLabelResolver$key } from './__generated__/RepresentativeLabelResolver.graphql';

/**
 * @RelayResolver Representative.label: String
 * @rootFragment RepresentativeLabelResolver
 *
 * A label for Representative which includes name and number.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(representative$key: RepresentativeLabelResolver$key): string {
  const representative$data = readFragment(
    graphql`
      fragment RepresentativeLabelResolver on Representative {
        number
        name
      }
    `,
    representative$key,
  );
  return `${representative$data.number} - ${representative$data.name}`;
}
