/**
 * @generated SignedSource<<28692dd254682fc0cebd81e6b14c268f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuoteListPageContentDefaultFiltersFragment$data = {
  readonly representativeId: string | null | undefined;
  readonly roles: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"JobFilters_useJobFiltersSearchParamsFragment">;
  readonly " $fragmentType": "QuoteListPageContentDefaultFiltersFragment";
};
export type QuoteListPageContentDefaultFiltersFragment$key = {
  readonly " $data"?: QuoteListPageContentDefaultFiltersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteListPageContentDefaultFiltersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteListPageContentDefaultFiltersFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "representativeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobFilters_useJobFiltersSearchParamsFragment"
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "364821d80b960937a758456dadf1ea70";

export default node;
