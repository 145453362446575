/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const workPlanningStatuses = ["canceled", "inProgress", "inWriting", "locked", "worksiteSelected"] as const;
export type WorkPlanningStatus = typeof workPlanningStatuses[number];

export function isWorkPlanningStatus(value: string): value is WorkPlanningStatus {
  return workPlanningStatuses.includes(value)
}

export function asWorkPlanningStatus(value: string): WorkPlanningStatus | null {
  return isWorkPlanningStatus(value) ? value : null;
}

export function castWorkPlanningStatus(value: string): WorkPlanningStatus {
  if(!isWorkPlanningStatus(value)) {
    throw new Error(`Invalid Enum value for type "WorkPlanningStatus": "${value}"`);
  }
  
  return value;
}
