/**
 * @generated SignedSource<<dc639e202f95ca9a35105ba8c84853ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_WorkSchedulesFragment$data = {
  readonly workSchedules: ReadonlyArray<WorkScheduleEnum>;
  readonly " $fragmentType": "SaleProjectFields_WorkSchedulesFragment";
};
export type SaleProjectFields_WorkSchedulesFragment$key = {
  readonly " $data"?: SaleProjectFields_WorkSchedulesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_WorkSchedulesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_WorkSchedulesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workSchedules",
      "storageKey": null
    }
  ],
  "type": "NatureOfWorkLookup",
  "abstractKey": null
};

(node as any).hash = "a7ce767e84da553d14da6f3c54bf5308";

export default node;
