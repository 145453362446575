import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MouseEvent, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventBoundary } from '../common/components/EventBoundary';
import { SaleCopyDialog, SaleCopyDialogRef } from './SaleCopyDialog';
import { NAVIGATE_PREVENT_BLOCK_STATE } from '../common/hooks/usePreventNavigationOnFormDirty';
import { SaleList_CopyButtonFragment$key } from './__generated__/SaleList_CopyButtonFragment.graphql';

export function SaleList_CopyButton({
  $key,
  hideAccessories,
  disabled,
}: {
  $key: SaleList_CopyButtonFragment$key | null | undefined;
  hideAccessories: boolean;
  disabled?: boolean;
}) {
  const copyRef = useRef<SaleCopyDialogRef>(null);
  const navigate = useNavigate();

  const $data = useFragment(
    graphql`
      fragment SaleList_CopyButtonFragment on JobRevisionBase {
        id
      }
    `,
    $key,
  );

  const handleConfirm = useCallback(
    async (search: string) => {
      if (!$data?.id) return;
      navigate(`./copy?revisionId=${$data.id}&` + search, { state: NAVIGATE_PREVENT_BLOCK_STATE });
      copyRef.current?.close();
    },
    [$data?.id, navigate],
  );

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      copyRef.current?.open(handleConfirm);
    },
    [handleConfirm],
  );

  return (
    <EventBoundary sx={{ cursor: 'default' }}>
      <IconButton sx={{ opacity: 'var(--hover-highlight)' }} onClick={handleOpen} disabled={disabled}>
        <ContentCopyIcon />
      </IconButton>

      <SaleCopyDialog ref={copyRef} hasAccessories={!hideAccessories} />
    </EventBoundary>
  );
}
