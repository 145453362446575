/**
 * @generated SignedSource<<8c2a59537bdb31e92d56b85b1737a2f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentKindLookupLabelResolver$data = {
  readonly description: Record<string, string>;
  readonly " $fragmentType": "EquipmentKindLookupLabelResolver";
};
export type EquipmentKindLookupLabelResolver$key = {
  readonly " $data"?: EquipmentKindLookupLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentKindLookupLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentKindLookupLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "EquipmentKindLookup",
  "abstractKey": null
};

(node as any).hash = "394eb96f9d4e6b35da7522deb120a717";

export default node;
