import { Typography, TypographyProps } from '@mui/material';
import { ElementType } from 'react';

export function EllipsisedTypography({ children, component, sx, ...props }: TypographyProps & { component?: ElementType }) {
  return (
    <Typography
      {...(component ? { component } : {})}
      {...props}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(sx ?? {}),
      }}>
      {children}
    </Typography>
  );
}
