/**
 * @generated SignedSource<<f9fbbe3bea5c18fdd5e7e84220ecc1ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlacesAutocompleteResultLabelResolver$data = {
  readonly description: string;
  readonly " $fragmentType": "PlacesAutocompleteResultLabelResolver";
};
export type PlacesAutocompleteResultLabelResolver$key = {
  readonly " $data"?: PlacesAutocompleteResultLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlacesAutocompleteResultLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlacesAutocompleteResultLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "PlacesAutocompleteResult",
  "abstractKey": null
};

(node as any).hash = "c3145a560d37b597810896f7f179fbc1";

export default node;
