/**
 * @generated SignedSource<<de1d2f31a89ed7bfc3b952304582dbd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHasSatisfiedCostsDependenciesFragment$data = {
  readonly clientBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_AssignedClientFragment" | "ProjectBaseFields_WorksiteOverrides_ClientLocationFragment">;
  };
  readonly equipmentBase: {
    readonly craneSelector: {
      readonly manualConfiguration: {
        readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorManualFavoriteFragment">;
      };
      readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneSelectorModeFragment" | "SaleCraneSelectorFields_FavoriteConfigurationFragment">;
    };
  };
  readonly project: {
    readonly natureOfWork: {
      readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_NatureOfWorkSubCategoriesFragment">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_NatureOfWorkFragment" | "SaleProjectFields_NatureOfWorkSubCategoryFragment">;
  };
  readonly projectBase: {
    readonly assignedWorksiteInfo: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_LocationFragment" | "ProjectBaseFields_WorksiteOverrides_NameFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateFragment">;
  };
  readonly " $fragmentType": "useHasSatisfiedCostsDependenciesFragment";
};
export type useHasSatisfiedCostsDependenciesFragment$key = {
  readonly " $data"?: useHasSatisfiedCostsDependenciesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHasSatisfiedCostsDependenciesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useHasSatisfiedCostsDependenciesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInternalBase",
      "kind": "LinkedField",
      "name": "clientBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_AssignedClientFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverrides_ClientLocationFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isCopy",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_ArrivalDateFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksiteInfoInternal",
          "kind": "LinkedField",
          "name": "assignedWorksiteInfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverrides_NameFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverrides_LocationFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkSubCategoryFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NatureOfWorkLookup",
          "kind": "LinkedField",
          "name": "natureOfWork",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SaleProjectFields_NatureOfWorkSubCategoriesFragment"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_DispatchBranchFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentInternalBase",
      "kind": "LinkedField",
      "name": "equipmentBase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneSelectorInternal",
          "kind": "LinkedField",
          "name": "craneSelector",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SaleCraneSelectorFields_CraneSelectorModeFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SaleCraneSelectorFields_FavoriteConfigurationFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ManualConfigurationInternal",
              "kind": "LinkedField",
              "name": "manualConfiguration",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "JobEquipment_useCraneSelectorManualFavoriteFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "790e71232f2694f75ff79ae9ff99b5c7";

export default node;
