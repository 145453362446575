/**
 * @generated SignedSource<<01e05d675bcdc60f6513defc90c58bbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkLabelResolver$data = {
  readonly code: number;
  readonly description: Record<string, string>;
  readonly " $fragmentType": "NatureOfWorkLabelResolver";
};
export type NatureOfWorkLabelResolver$key = {
  readonly " $data"?: NatureOfWorkLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NatureOfWorkLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "NatureOfWorkLookup",
  "abstractKey": null
};

(node as any).hash = "546f6e8e0eba96c1749fd2c6a53b3b0e";

export default node;
