/**
 * @generated SignedSource<<df213066b86a3b77c8152922b1d59151>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ErrorBannerJobRevisionIsNotTheLatestErrorFragment$data = {
  readonly __typename: "JobRevisionIsNotTheLatestError";
  readonly jobRevisionType: string;
  readonly " $fragmentType": "ErrorBannerJobRevisionIsNotTheLatestErrorFragment";
};
export type ErrorBannerJobRevisionIsNotTheLatestErrorFragment$key = {
  readonly " $data"?: ErrorBannerJobRevisionIsNotTheLatestErrorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorBannerJobRevisionIsNotTheLatestErrorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorBannerJobRevisionIsNotTheLatestErrorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobRevisionType",
      "storageKey": null
    }
  ],
  "type": "JobRevisionIsNotTheLatestError",
  "abstractKey": null
};

(node as any).hash = "1a8402ca9cea82b2f089a9993b5b0271";

export default node;
