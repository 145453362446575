/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const workScheduleEnums = ["fiveDaysEightHours", "fiveDaysNineHours", "fiveDaysTenHours", "fourDaysTenHours", "fourDaysTenHoursOneDayFiveHours"] as const;
export type WorkScheduleEnum = typeof workScheduleEnums[number];

export function isWorkScheduleEnum(value: string): value is WorkScheduleEnum {
  return workScheduleEnums.includes(value)
}

export function asWorkScheduleEnum(value: string): WorkScheduleEnum | null {
  return isWorkScheduleEnum(value) ? value : null;
}

export function castWorkScheduleEnum(value: string): WorkScheduleEnum {
  if(!isWorkScheduleEnum(value)) {
    throw new Error(`Invalid Enum value for type "WorkScheduleEnum": "${value}"`);
  }
  
  return value;
}
