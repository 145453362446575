import graphql from 'babel-plugin-relay/macro';
import {
  ClickAwayListener,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { MouseEvent, Suspense, useMemo, useState } from 'react';
import Popper from '@mui/material/Popper';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { EmptyList } from '../../layout/components/form/FormList';
import { SuggestionPromptInput } from './__generated__/SuggestionsFakeQuery.graphql';

// Default to 0 for more suggestions. Adjusted as needed
export const scoreThreshold = 0;
// Default to 5 suggestions returned
export const suggestionCount = 5;

export function Suggestions({ content, disabled }: { content: (closePopper: () => void) => JSX.Element; disabled: boolean }) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggle = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const contentEl = useMemo(() => content(() => setOpen(false)), [content]);

  return (
    !disabled && (
      <>
        <IconButton aria-label={t('autocomplete.automaticSuggestions')} onClick={handleToggle} edge='end'>
          <StarIcon sx={{ color: theme.palette.suggestion.main }} />
        </IconButton>
        <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: 1 }} transition placement='bottom-end'>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Fade
                {...TransitionProps}
                timeout={open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen}>
                <Paper>
                  <Suspense fallback={<SuggestionsSkeleton />}>{contentEl}</Suspense>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </>
    )
  );
}

interface AutomaticSuggestionListItemProps<T> {
  value: T;
  onClick: (selectedValue: T) => void;
}

function AutomaticSuggestionListItem<T>({ value, onClick: handleChoiceSelected }: AutomaticSuggestionListItemProps<T>) {
  const theme = useTheme();

  return (
    <ListItem disablePadding sx={{ height: '2.25rem' }}>
      <ListItemButton data-testid='editLiftButton' onClick={() => handleChoiceSelected(value)} alignItems='center'>
        <Stack sx={{ width: '2rem' }}>
          <StarIcon sx={{ color: theme.palette.suggestion.main }} />
        </Stack>
        <ListItemText primary={`${value}`} primaryTypographyProps={{ noWrap: true }} />
      </ListItemButton>
    </ListItem>
  );
}

export function AutomaticSuggestionList<T>({
  items,
  onChange: handleChoiceSelected,
  keySelector,
}: {
  items: ReadonlyArray<{
    readonly score: number;
    readonly value: T;
  }>;
  onChange: (selectedValue: T) => void;
  keySelector: (value: T) => string;
}) {
  const { t } = useTranslation('common');

  return (
    <Stack sx={{ padding: '1rem' }}>
      {items?.length ? (
        <>
          <Typography>{t('autocomplete.automaticSuggestions')}</Typography>
          <List>
            {items.map((l) => (
              <AutomaticSuggestionListItem<T> key={keySelector(l.value)} value={l.value} onClick={handleChoiceSelected} />
            ))}
          </List>
        </>
      ) : (
        <EmptyList placeholder={t('placeholder.noSuggestions')} />
      )}
    </Stack>
  );
}

function SuggestionsSkeleton() {
  return (
    <Stack sx={{ padding: '1rem' }}>
      <Skeleton variant='rounded' width='10rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
      <List>
        <ListItem disablePadding>
          <Skeleton variant='rounded' width='20rem' height='2.25rem' sx={{ mb: '0.25rem' }} />
        </ListItem>
        <ListItem disablePadding>
          <Skeleton variant='rounded' width='20rem' height='2.25rem' sx={{ mb: '0.25rem' }} />
        </ListItem>
        <ListItem disablePadding>
          <Skeleton variant='rounded' width='20rem' height='2.25rem' sx={{ mb: '0.25rem' }} />
        </ListItem>
      </List>
    </Stack>
  );
}

// Empty required fields of suggestionPrompt parameter for suggestions
export const emptySuggestionPromptInput: SuggestionPromptInput = {
  accessoryTypes: [],
  clientRequirements: [],
  vehicleIds: [],
  worksiteRequirements: [],
};

// Used to generate SuggestionPromptInput type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const suggestionsFakeQuery = graphql`
  query SuggestionsFakeQuery($count: Int!, $prompt: SuggestionPromptInput!, $scoreThreshold: Float!) {
    suggestedClients(count: $count, prompt: $prompt, scoreThreshold: $scoreThreshold) {
      value {
        id
      }
    }
  }
`;
