/**
 * @generated SignedSource<<d0df69ba4104ac413f90408315bac857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateDetailsPageQuery$variables = {
  id: string;
  isNew: boolean;
};
export type AddendumTemplateDetailsPageQuery$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateDetailsPageFragment">;
  } | null | undefined;
};
export type AddendumTemplateDetailsPageQuery = {
  response: AddendumTemplateDetailsPageQuery$data;
  variables: AddendumTemplateDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isNew"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddendumTemplateDetailsPageQuery",
    "selections": [
      {
        "condition": "isNew",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AddendumTemplateDetailsPageFragment"
                  }
                ],
                "type": "AddendumTemplate",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddendumTemplateDetailsPageQuery",
    "selections": [
      {
        "condition": "isNew",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "template",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddendumTemplateConditionsInternal",
                    "kind": "LinkedField",
                    "name": "conditions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "saleKinds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NatureOfWorkLookup",
                        "kind": "LinkedField",
                        "name": "natureOfWorks",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "name": "label",
                            "args": null,
                            "fragment": {
                              "kind": "InlineFragment",
                              "selections": [
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "description",
                                  "storageKey": null
                                }
                              ],
                              "type": "NatureOfWorkLookup",
                              "abstractKey": null
                            },
                            "kind": "RelayResolver",
                            "storageKey": null,
                            "isOutputType": true
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "natureOfWorkSubCategories",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "questionKinds",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "AddendumTemplate",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "314a6c0ac53e8580e0365c87f2bf0860",
    "id": null,
    "metadata": {},
    "name": "AddendumTemplateDetailsPageQuery",
    "operationKind": "query",
    "text": "query AddendumTemplateDetailsPageQuery(\n  $id: ID!\n  $isNew: Boolean!\n) {\n  node(id: $id) @skip(if: $isNew) {\n    __typename\n    ... on AddendumTemplate {\n      ...AddendumTemplateDetailsPageFragment\n    }\n    id\n  }\n}\n\nfragment AddendumTemplateDetailsPageFragment on AddendumTemplate {\n  id\n  ...AddendumTemplateFields_TitleFrFragment\n  ...AddendumTemplateFields_TitleEnFragment\n  ...AddendumTemplateFieldsKindFragment\n  ...AddendumTemplateFields_TemplateFrFragment\n  ...AddendumTemplateFields_TemplateEnFragment\n  ...AddendumTemplateFieldsSaleKindsFragment\n  ...AddendumTemplateFieldsNatureOfWorkCodesFragment\n  ...AddendumTemplateFieldsNatureOfWorkSubcategoryFragment\n  ...AddendumTemplateFieldsQuestionKindsFragment\n}\n\nfragment AddendumTemplateFieldsKindFragment on AddendumTemplate {\n  kind\n}\n\nfragment AddendumTemplateFieldsNatureOfWorkCodesFragment on AddendumTemplate {\n  conditions {\n    natureOfWorks {\n      id\n      code\n      ...NatureOfWorkLabelResolver\n    }\n  }\n}\n\nfragment AddendumTemplateFieldsNatureOfWorkSubcategoryFragment on AddendumTemplate {\n  conditions {\n    natureOfWorkSubCategories\n  }\n}\n\nfragment AddendumTemplateFieldsQuestionKindsFragment on AddendumTemplate {\n  conditions {\n    questionKinds\n  }\n}\n\nfragment AddendumTemplateFieldsSaleKindsFragment on AddendumTemplate {\n  conditions {\n    saleKinds\n  }\n}\n\nfragment AddendumTemplateFields_TemplateEnFragment on AddendumTemplate {\n  template\n}\n\nfragment AddendumTemplateFields_TemplateFrFragment on AddendumTemplate {\n  template\n}\n\nfragment AddendumTemplateFields_TitleEnFragment on AddendumTemplate {\n  title\n}\n\nfragment AddendumTemplateFields_TitleFrFragment on AddendumTemplate {\n  title\n}\n\nfragment NatureOfWorkLabelResolver on NatureOfWorkLookup {\n  code\n  description\n}\n"
  }
};
})();

(node as any).hash = "1b8cfdbf640caf1f8b7e9cc03a32ccdf";

export default node;
