/**
 * @generated SignedSource<<a2a1b4ca2ccce2f5a181a9a5edd0add5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteBillingCodeRuleInput = {
  id: string;
};
export type BillingCodeRuleDeleteButtonMutation$variables = {
  input: DeleteBillingCodeRuleInput;
};
export type BillingCodeRuleDeleteButtonMutation$data = {
  readonly deleteBillingCodeRule: {
    readonly deleteBillingCodeRuleResponse: {
      readonly success: boolean;
    } | null | undefined;
  };
};
export type BillingCodeRuleDeleteButtonMutation = {
  response: BillingCodeRuleDeleteButtonMutation$data;
  variables: BillingCodeRuleDeleteButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteBillingCodeRulePayload",
    "kind": "LinkedField",
    "name": "deleteBillingCodeRule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeleteBillingCodeRuleResponse",
        "kind": "LinkedField",
        "name": "deleteBillingCodeRuleResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingCodeRuleDeleteButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillingCodeRuleDeleteButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1b74f64cba3084d9d217bad31bc4eb2c",
    "id": null,
    "metadata": {},
    "name": "BillingCodeRuleDeleteButtonMutation",
    "operationKind": "mutation",
    "text": "mutation BillingCodeRuleDeleteButtonMutation(\n  $input: DeleteBillingCodeRuleInput!\n) {\n  deleteBillingCodeRule(input: $input) {\n    deleteBillingCodeRuleResponse {\n      success\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "705626386a80b2036c1f5ff7b3d4040a";

export default node;
