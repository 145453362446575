import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { DataID, useFragment } from 'react-relay';

import { useForm } from '../../common/utils/formUtils';
import { LoadingButton } from '../../common/components/LoadingButton';
import { BillingCodeList } from '../BillingCodeList';

import graphql from 'babel-plugin-relay/macro';
import { BillingCodeFormFragment$key } from './__generated__/BillingCodeFormFragment.graphql';
import { BillingCodeFormMutation, UpdateBillingCodeListInput } from './__generated__/BillingCodeFormMutation.graphql';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import { useErrorBanner } from '../../common/components/ErrorBanner';

export const billingCodeFormMutation = graphql`
  mutation BillingCodeFormMutation($data: UpdateBillingCodeListInput!) {
    updateBillingCodes(input: { updateBillingCodeList: $data }) {
      billingCodes {
        code
        description
        microDescription
      }
      errors {
        __typename
      }
    }
  }
`;

const billingCodeFormFragment = graphql`
  fragment BillingCodeFormFragment on Query {
    billingCodes(where: { billingCodeCategory: { eq: accessories } }) {
      id
      code
      description
      microDescription
    }
  }
`;

export type BillingCodeFormValues = {
  billingCodes: {
    id: DataID;
    code: number;
    description: {
      en: string;
      fr: string;
    };
    microDescription: {
      en: string;
      fr: string;
    };
  }[];
};

export function BillingCodeForm({ fragmentKey }: { fragmentKey: BillingCodeFormFragment$key }) {
  const { t } = useTranslation('configuration');
  const data = useFragment(billingCodeFormFragment, fragmentKey);
  const [commitSaveBillingCodes, isCurrentlySaving] = usePromiseMutation<BillingCodeFormMutation>(billingCodeFormMutation);
  const { reportUnexpectedError, reportHandledError } = useErrorBanner();
  // Used to disable the save button while waiting for updated data to be fed back into the component after a save.
  // Without this, the save button quickly goes from disabled to enabled then to disabled again after a save.
  const [dataUpdatePending, setDataUpdatePending] = useState(false);

  const defaultValues: BillingCodeFormValues = useMemo(
    () => ({
      billingCodes: data.billingCodes.map((d) => ({
        ...d,
        description: {
          en: d.description['en'],
          fr: d.description['fr'],
        },
        microDescription: {
          en: d.microDescription?.['en'] ?? '',
          fr: d.microDescription?.['fr'] ?? '',
        },
      })),
    }),
    [data],
  );

  const methods = useForm<BillingCodeFormValues>({ defaultValues, mode: 'onChange' });

  useEffect(() => {
    methods.reset(defaultValues);
    setDataUpdatePending(false);
  }, [defaultValues, methods]);

  const handleSubmit = useCallback(
    async (values: BillingCodeFormValues) => {
      const dirtyBillingCodes = methods.formState.dirtyFields.billingCodes;

      if (!dirtyBillingCodes || !methods.formState.isValid) return;

      const payload: UpdateBillingCodeListInput = {
        billingCodes: values.billingCodes
          .filter((_, index) => dirtyBillingCodes[index])
          .map((billingCode) => ({
            action: 'update',
            id: billingCode.id,
            value: {
              enMicroDescription: billingCode.microDescription.en || null,
              frMicroDescription: billingCode.microDescription.fr || null,
            },
          })),
      };

      try {
        const { response } = await commitSaveBillingCodes({
          variables: { data: payload },
        });

        if (response?.updateBillingCodes?.errors?.length) {
          reportHandledError(response?.updateBillingCodes.errors, () => t('error.errorDuringSaveBillingCodes'));
          return;
        }
      } catch {
        reportUnexpectedError(() => t('error.errorDuringSaveBillingCodes'));
      } finally {
        setDataUpdatePending(true);
      }
    },
    [
      methods.formState.dirtyFields.billingCodes,
      methods.formState.isValid,
      commitSaveBillingCodes,
      reportHandledError,
      t,
      reportUnexpectedError,
    ],
  );

  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <Box display='flex' justifyContent='flex-end' mx={2}>
          <LoadingButton
            type='submit'
            disabled={!methods.formState.isValid || !methods.formState.isDirty || dataUpdatePending}
            isLoading={isCurrentlySaving}>
            {t('button.save', { ns: 'common' })}
          </LoadingButton>
        </Box>
        <BillingCodeList />
      </FormProvider>
    </form>
  );
}
