import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useNavigate } from 'react-router-dom';
import { DataID, useFragment, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  useElementFactory,
} from '../common/components/ResponsiveGrid';
import { useCallback } from 'react';
import { toCamelCase } from '../common/utils/stringUtils';
import { Box } from '@mui/material';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import { BillingCodeRuleListItemFragment$key } from './__generated__/BillingCodeRuleListItemFragment.graphql';
import { BillingCodeRuleListFragment$key } from './__generated__/BillingCodeRuleListFragment.graphql';

export function BillingCodeRuleList({ $key }: { $key: BillingCodeRuleListFragment$key }) {
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();

  const [$data, refetch] = useRefetchableFragment(
    graphql`
      fragment BillingCodeRuleListFragment on Query
      @refetchable(queryName: "BillingCodeRuleListFragmentQuery")
      @argumentDefinitions(after: { type: "String" }, before: { type: "String" }, first: { type: "Int" }, last: { type: "Int" }) {
        billingCodeRules(after: $after, before: $before, first: $first, last: $last) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              ...BillingCodeRuleListItemFragment
            }
          }
        }
      }
    `,
    $key,
  );

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'kinds', label: t('fields.label.kinds'), size: 'minmax(8rem, auto)' },
    { id: 'description', label: t('fields.label.description'), size: 'minmax(16rem, auto)' },
  ];

  const rowElementFactory = useElementFactory($data.billingCodeRules?.edges, (node, orderByColumns) => (
    <BillingCodeRuleListItem $key={node} orderByColumns={orderByColumns} />
  ));

  const handleItemClick = useCallback((id: DataID) => navigate(`/billing-code-rules/${id}`), [navigate]);

  return (
    $data.billingCodeRules && (
      <ResponsiveGrid
        connectionFragmentKey={$data.billingCodeRules}
        refetch={refetch}
        onItemClick={handleItemClick}
        columnDefinitions={columns}
        rowElementFactory={rowElementFactory}
        listElementFactory={() => <></>}
        listSx={{
          'li > *': {
            px: '1rem',
          },
        }}
      />
    )
  );
}

function BillingCodeRuleListItem({
  $key,
  orderByColumns,
}: {
  $key: BillingCodeRuleListItemFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { t } = useAmbientTranslation();
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleListItemFragment on BillingCodeRule {
        description
        id
        conditions {
          kinds
        }
      }
    `,
    $key,
  );

  const formattedKinds = $data.conditions.kinds.length
    ? $data.conditions.kinds.map((item) => t(`saleKinds.${toCamelCase(item)}`, { ns: 'common' })).join(', ')
    : t('placeholder.kindListEmpty');

  return (
    <>
      {orderByColumns([
        <Box key='kinds' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography title={formattedKinds} variant='body2' component='span'>
            {formattedKinds}
          </EllipsisedTypography>
        </Box>,
        <Box key='description' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography title={$data.description} variant='body2' component='span'>
            {$data.description}
          </EllipsisedTypography>
        </Box>,
      ])}
    </>
  );
}
