/**
 * @generated SignedSource<<a5bf1a321362fc4b21a95d1c4138a393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRow_PermalinkFragment$data = {
  readonly isRead: boolean;
  readonly permalink: string;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_BaseFragment">;
  readonly " $fragmentType": "NotificationRow_PermalinkFragment";
};
export type NotificationRow_PermalinkFragment$key = {
  readonly " $data"?: NotificationRow_PermalinkFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_PermalinkFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRow_PermalinkFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permalink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRead",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_BaseFragment"
    }
  ],
  "type": "PermalinkNotification",
  "abstractKey": null
};

(node as any).hash = "cbce6d704fec95f848b58505e6c93261";

export default node;
