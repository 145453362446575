import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DataID, useFragment } from 'react-relay';

import { useForm } from '../../common/utils/formUtils';
import { LoadingButton } from '../../common/components/LoadingButton';
import { _throw } from '../../common/utils/_throw';
import { NatureOfWorkList } from '../NatureOfWorkList';

import graphql from 'babel-plugin-relay/macro';
import { NatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { WorkScheduleEnum } from '../../__enums__/WorkScheduleEnum';
import { NatureOfWorkFormFragment$key } from './__generated__/NatureOfWorkFormFragment.graphql';
import { NatureOfWorkFormSaveMutation, UpdateNatureOfWorksListInput } from './__generated__/NatureOfWorkFormSaveMutation.graphql';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import { useErrorBanner } from '../../common/components/ErrorBanner';

const natureOfWorkFormFragment = graphql`
  fragment NatureOfWorkFormFragment on Query {
    natureOfWorks(order: { code: ASC }) {
      id
      code
      description
      defaultWorkSchedule
      workSchedules
      natureOfWorkSubCategories
    }
  }
`;

export const natureOfWorkFormSaveMutation = graphql`
  mutation NatureOfWorkFormSaveMutation($data: UpdateNatureOfWorksListInput!) {
    updateNatureOfWorks(input: { updateNatureOfWorksList: $data }) {
      natureOfWorkLookups {
        code
        defaultWorkSchedule
        workSchedules
        natureOfWorkSubCategories
      }
      errors {
        __typename
      }
    }
  }
`;

export type NatureOfWorkFormValues = {
  natureOfWorks: {
    id: DataID;
    code: number;
    description: {
      en: string;
      fr: string;
    };
    defaultWorkSchedule: WorkScheduleEnum | null;
    workSchedules: WorkScheduleEnum[];
    natureOfWorkSubCategories: NatureOfWorkSubCategoryEnum[];
  }[];
};

export function NatureOfWorkForm({ fragmentKey }: { fragmentKey: NatureOfWorkFormFragment$key }) {
  const { t } = useTranslation('configuration');
  const data = useFragment(natureOfWorkFormFragment, fragmentKey);
  const [commitSaveNatureOfWorks, isCurrentlySaving] = usePromiseMutation<NatureOfWorkFormSaveMutation>(natureOfWorkFormSaveMutation);
  const { reportUnexpectedError, reportHandledError } = useErrorBanner();
  // Used to disable the save button while waiting for updated data to be fed back into the component after a save.
  // Without this, the save button quickly goes from disabled to enabled then to disabled again after a save.
  const [dataUpdatePending, setDataUpdatePending] = useState(false);

  const defaultValues: NatureOfWorkFormValues = useMemo(
    () => ({
      natureOfWorks: data.natureOfWorks.map((d) => ({
        ...d,
        description: {
          en: d.description['en'],
          fr: d.description['fr'],
        },
        // always display work schedules in the same order to make rows easier to compare visually
        workSchedules: d.workSchedules.slice().sort() as WorkScheduleEnum[],
        defaultWorkSchedule: d.defaultWorkSchedule as WorkScheduleEnum,
        natureOfWorkSubCategories: d.natureOfWorkSubCategories.slice().sort() as NatureOfWorkSubCategoryEnum[],
      })),
    }),
    [data],
  );

  const methods = useForm<NatureOfWorkFormValues>({ defaultValues, mode: 'onChange' });

  useEffect(() => {
    methods.reset(defaultValues);
    setDataUpdatePending(false);
  }, [defaultValues, methods]);

  const handleSubmit = useCallback(
    async (values: NatureOfWorkFormValues) => {
      const dirtyNatureOfWorks = methods.formState.dirtyFields.natureOfWorks;

      if (!dirtyNatureOfWorks || !methods.formState.isValid) return;

      const payload: UpdateNatureOfWorksListInput = {
        natureOfWorks: values.natureOfWorks
          .filter((_, index) => dirtyNatureOfWorks[index])
          .map((natureOfWork) => ({
            action: 'update',
            id: natureOfWork.id,
            value: {
              workSchedules: natureOfWork.workSchedules,
              defaultWorkSchedule: natureOfWork.defaultWorkSchedule ?? _throw(() => new Error('defaultWorkSchedule is required')),
              natureOfWorkSubCategories: natureOfWork.natureOfWorkSubCategories,
            },
          })),
      };

      try {
        const { response } = await commitSaveNatureOfWorks({
          variables: { data: payload },
        });

        if (response?.updateNatureOfWorks?.errors?.length) {
          reportHandledError(response?.updateNatureOfWorks.errors, () => t('error.errorDuringSaveNatureOfWorks'));
          return;
        }
      } catch {
        reportUnexpectedError(() => t('error.errorDuringSaveNatureOfWorks'));
      } finally {
        setDataUpdatePending(true);
      }
    },
    [
      methods.formState.dirtyFields.natureOfWorks,
      methods.formState.isValid,
      commitSaveNatureOfWorks,
      reportHandledError,
      t,
      reportUnexpectedError,
    ],
  );

  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <Box display='flex' justifyContent='flex-end' mx={2}>
          <LoadingButton
            type='submit'
            disabled={!methods.formState.isValid || !methods.formState.isDirty || dataUpdatePending}
            isLoading={isCurrentlySaving}>
            {t('button.save', { ns: 'common' })}
          </LoadingButton>
        </Box>
        <NatureOfWorkList />
      </FormProvider>
    </form>
  );
}
