/**
 * @generated SignedSource<<463688c65147c118efd5b80dc5682133>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkFormFragment$data = {
  readonly natureOfWorks: ReadonlyArray<{
    readonly code: number;
    readonly defaultWorkSchedule: WorkScheduleEnum;
    readonly description: Record<string, string>;
    readonly id: string;
    readonly natureOfWorkSubCategories: ReadonlyArray<NatureOfWorkSubCategoryEnum>;
    readonly workSchedules: ReadonlyArray<WorkScheduleEnum>;
  }>;
  readonly " $fragmentType": "NatureOfWorkFormFragment";
};
export type NatureOfWorkFormFragment$key = {
  readonly " $data"?: NatureOfWorkFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NatureOfWorkFormFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": {
            "code": "ASC"
          }
        }
      ],
      "concreteType": "NatureOfWorkLookup",
      "kind": "LinkedField",
      "name": "natureOfWorks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultWorkSchedule",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workSchedules",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "natureOfWorkSubCategories",
          "storageKey": null
        }
      ],
      "storageKey": "natureOfWorks(order:{\"code\":\"ASC\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c2b17fd9a619df791848eaa5f179d27e";

export default node;
