/**
 * @generated SignedSource<<50d64e364648098d124f0eb636462072>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_Item_SaleProjectFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemContent_SaleProjectFragment">;
  readonly " $fragmentType": "AccessoryLinesFields_Item_SaleProjectFragment";
};
export type AccessoryLinesFields_Item_SaleProjectFragment$key = {
  readonly " $data"?: AccessoryLinesFields_Item_SaleProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_Item_SaleProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_Item_SaleProjectFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryLinesFields_ItemContent_SaleProjectFragment"
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "3371b86e5e90b72d28b1e6202df468f3";

export default node;
