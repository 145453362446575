// Theses values come from ../public/config.js
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const env = { ...window.env };

export const config = {
  APP_VERSION: env.APP_VERSION,
  API_URL: env.API_URL,
  WS_URL: env.WS_URL,
  AUTH_API_SCOPE: env.AUTH_API_SCOPE,
  AUTH_AUTHORITY: env.AUTH_AUTHORITY,
  AUTH_CLIENT_ID: env.AUTH_CLIENT_ID,
  AUTH_LOGIN_REDIRECT: env.AUTH_LOGIN_REDIRECT,
  AUTH_REDIRECT_URI: env.AUTH_REDIRECT_URI,
  RELEASE_DATE: env.RELEASE_DATE,
  SENTRY_DSN: env.SENTRY_DSN,
  SENTRY_ENVIRONMENT: env.SENTRY_ENVIRONMENT,
  SENTRY_RELEASE: env.SENTRY_RELEASE,
  SENTRY_TRACESSAMPLERATE: env.SENTRY_TRACESSAMPLERATE ? (+(env.SENTRY_TRACESSAMPLERATE || '') as number) : undefined,
  SENTRY_REPLAYSSESSIONSAMPLERATE: env.SENTRY_REPLAYSSESSIONSAMPLERATE
    ? (+(env.SENTRY_REPLAYSSESSIONSAMPLERATE || '') as number)
    : undefined,
  SENTRY_REPLAYSONERRORSAMPLERATE: env.SENTRY_REPLAYSONERRORSAMPLERATE
    ? (+(env.SENTRY_REPLAYSONERRORSAMPLERATE || '') as number)
    : undefined,
  FEATURE_FLAG_HOST: env.FEATURE_FLAG_HOST,
  FEATURE_FLAG_CLIENT_KEY: env.FEATURE_FLAG_CLIENT_KEY,
  CAN_RESEED_DATA: env.CAN_RESEED_DATA,
  CAN_DELETE_DATA: env.CAN_DELETE_DATA,
  CAN_RESYNC_DATA: env.CAN_RESYNC_DATA,
  SHOW_COPY_TOKEN: env.SHOW_COPY_TOKEN,
};
