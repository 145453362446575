/**
 * @generated SignedSource<<13c597adb4ccdce63ea4bb5bd798391f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabCraneChartsQuery$variables = {
  first?: number | null | undefined;
};
export type TabCraneChartsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CraneChartListFragment">;
};
export type TabCraneChartsQuery = {
  response: TabCraneChartsQuery$data;
  variables: TabCraneChartsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TabCraneChartsQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CraneChartListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TabCraneChartsQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "order",
            "value": [
              {
                "createdAt": "DESC"
              }
            ]
          }
        ],
        "concreteType": "CraneChartBlobMetadatasConnection",
        "kind": "LinkedField",
        "name": "craneChartBlobMetadatas",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CraneChartBlobMetadatasEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CraneChartBlobMetadata",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CraneChart",
                    "kind": "LinkedField",
                    "name": "craneChart",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "byteCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "craneChartId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "make",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "model",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EquipmentKindLookup",
                    "kind": "LinkedField",
                    "name": "equipmentKind",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "abbreviation",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "capacity",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Connection",
            "abstractKey": "__isConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "394f2278046e025bd3bbf6f5a22aafa5",
    "id": null,
    "metadata": {},
    "name": "TabCraneChartsQuery",
    "operationKind": "query",
    "text": "query TabCraneChartsQuery(\n  $first: Int\n) {\n  ...CraneChartListFragment_3ASum4\n}\n\nfragment CraneChartListActionsFragment on CraneChartBlobMetadata {\n  ...CraneChartListDeactivateButtonFragment\n  craneChart {\n    id\n  }\n}\n\nfragment CraneChartListDeactivateButtonFragment on CraneChartBlobMetadata {\n  id\n}\n\nfragment CraneChartListFragment_3ASum4 on Query {\n  craneChartBlobMetadatas(first: $first, order: [{createdAt: DESC}]) {\n    ...ResponsiveGridFragment\n    edges {\n      node {\n        id\n        ...CraneChartListItemFragment\n      }\n    }\n  }\n}\n\nfragment CraneChartListItemFragment on CraneChartBlobMetadata {\n  ...CraneChartListActionsFragment\n  id\n  name\n  byteCount\n  status\n  updatedAt\n  craneChartId\n  craneChart {\n    id\n  }\n  make\n  model\n  equipmentKind {\n    abbreviation\n    id\n  }\n  capacity\n}\n\nfragment ResponsiveGridFragment on Connection {\n  __isConnection: __typename\n  totalCount\n  pageInfo {\n    startCursor\n    endCursor\n    hasPreviousPage\n    hasNextPage\n  }\n  edges {\n    __typename\n    node {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f7ab6887661388c477f95b182f2d692";

export default node;
