import { useCallback, useInsertionEffect, useRef } from 'react';
import { useRefSync } from '../hooks/useRefSync';

/**
 * A polyfill for the experimental (and now defunct) useEvent: https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation.
 *
 * This is the best known way of setting up a stable callback that needs to access the reactive state.
 *
 * It returns a stable callback that calls the latest version of the function provided to the hook. This "latest" version
 * is updated right before any effect had the time to run, which prevents cross component state desync.
 *
 * @param fn - The event handler.
 */

// Any is required to extend the proper function's form.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEvent<F extends (...args: any[]) => void>(fn: F): F {
  const fnRef = useRef<F>(fn);

  useInsertionEffect(() => {
    fnRef.current = fn;
  });

  return useCallback<F>(((...args) => fnRef.current(...args)) as F, []);
}

const NOT_CALLED = Symbol('Not Called');

/**
 * A polyfill for the very experimental useEffectEvent: https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
 *
 * This is the best known way of ensuring that an event only trigger once per render cycle when called from an effect.
 *
 * It returns a stable callback that MUST be used within the same component and cannot be passed as props. It is designed
 * to be used to extract event functions from useEffects, not to make those functions reusable, but to avoid dependencies
 * triggering the effect needlessly. In other words, these callbacks must be called nearly immediately.
 *
 * @param fn - The event handler.
 */
// Any is required to extend the proper function's form.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEffectEvent<F extends (...args: any[]) => void>(fn: F): F {
  const eventFn = useEvent(fn);

  // Used to cache the event's result and memoize its result if the function is called multiple times in the same
  // render cycle. Forcing a stable symbol as the current value every render will reset the cache on render.
  const resultRef = useRefSync<unknown>(NOT_CALLED);

  return useCallback<F>(
    ((...args) => {
      if (resultRef.current === NOT_CALLED) {
        // Void return is actually undefined, which is fine in this case and is why we check for a specific symbol.
        // noinspection JSVoidFunctionReturnValueUsed
        resultRef.current = eventFn(...args);
      }

      return resultRef.current;
    }) as F,
    [eventFn, resultRef],
  );
}
