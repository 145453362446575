import { useFieldCraneConfigurationCollectionFavorite, useFieldCraneSelectorMode } from './fields/SaleCraneSelectorFields';
import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useEffect, useMemo } from 'react';
import { useFieldAdditionalCranesAutomaticCollection } from './AdditionalCranesFields';
import { JobEquipment_useCraneSelectorAutomaticFavoriteFragment$key } from './__generated__/JobEquipment_useCraneSelectorAutomaticFavoriteFragment.graphql';
import { useEffectEvent } from '../common/utils/effectUtils';
import { JobEquipment_useSyncAdditionalCranesAutomaticQuery } from './__generated__/JobEquipment_useSyncAdditionalCranesAutomaticQuery.graphql';
import { JobEquipment_useSyncAdditionalCranesAutomaticFragment$key } from './__generated__/JobEquipment_useSyncAdditionalCranesAutomaticFragment.graphql';
import { useOperations, useOperationsError } from '../AppSharedState';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import * as Sentry from '@sentry/react';

export function useCraneSelectorAutomaticFavorite(
  $key: JobEquipment_useCraneSelectorAutomaticFavoriteFragment$key | null | undefined,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useCraneSelectorAutomaticFavoriteFragment on CraneSelectorInternal {
        ...SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment
        automaticConfiguration {
          ...AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment
        }
      }
    `,
    $key,
  );

  const { craneConfigurationCollectionFavorite, craneConfigurationCollectionFavoriteIsDirty } =
    useFieldCraneConfigurationCollectionFavorite($data, required);

  const favorite$data = useFragment(
    graphql`
      fragment JobEquipment_AutomaticFavoriteFragment on AutomaticConfigurationInfo {
        capacity @required(action: THROW) {
          capacity
          label
        }
        equipmentKind @required(action: THROW) {
          id
          code
          label
        }
        vehicleId @required(action: THROW) {
          key
          label
        }
        configurationKind @required(action: THROW) {
          id
          code
          label
        }
        boomConfiguration @required(action: THROW) {
          id
          label
        }
        boomLength
        jibLength
        counterweight
        offsetAngle
        radius
        maxWeight
      }
    `,
    craneConfigurationCollectionFavorite?.matchingCostLineAutomaticRulesFavorite$key,
  );

  const { additionalCranesAutomatic, additionalCranesAutomaticAreDirty } = useFieldAdditionalCranesAutomaticCollection(
    $data?.automaticConfiguration,
  );

  const automaticFavorite = useMemo(
    () =>
      favorite$data
        ? {
            capacity: favorite$data.capacity,
            equipmentKind: favorite$data.equipmentKind,
            vehicleId: favorite$data.vehicleId,
            configurationKind: favorite$data.configurationKind,
            boomConfiguration: favorite$data.boomConfiguration,
            boomLength: favorite$data.boomLength,
            jibLength: favorite$data.jibLength,
            counterweight: favorite$data.counterweight,
            offsetAngle: favorite$data.offsetAngle,
            radius: favorite$data.radius,
            maxWeight: favorite$data.maxWeight,
            additionalCranes: additionalCranesAutomatic.filter((v) => !v.$removed),
          }
        : null,
    [additionalCranesAutomatic, favorite$data],
  );

  const automaticFavoriteIsDirty = useMemo(() => {
    return craneConfigurationCollectionFavoriteIsDirty || additionalCranesAutomaticAreDirty;
  }, [additionalCranesAutomaticAreDirty, craneConfigurationCollectionFavoriteIsDirty]);
  return { automaticFavorite, automaticFavoriteIsDirty, additionalCranesAutomaticAreDirty };
}

export const SYNC_ADDITIONAL_CRANES_AUTOMATIC_OP_KEY = 'useSyncAdditionalCranesAutomatic';
export function useSyncAdditionalCranesAutomatic(
  $key: JobEquipment_useSyncAdditionalCranesAutomaticFragment$key | null | undefined,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useSyncAdditionalCranesAutomaticFragment on CraneSelectorInternal {
        ...SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment
        ...SaleCraneSelectorFields_CraneSelectorModeFragment
        automaticConfiguration {
          ...AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment
        }
      }
    `,
    $key,
  );

  const { craneSelectorMode } = useFieldCraneSelectorMode($data, required);

  const { craneConfigurationCollectionFavorite, craneConfigurationCollectionFavoriteIsDirty } =
    useFieldCraneConfigurationCollectionFavorite($data, required);

  const favorite$data = useFragment(
    graphql`
      fragment JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment on AutomaticConfigurationInfo {
        boomConfigurationId
      }
    `,
    craneConfigurationCollectionFavorite?.matchingConfigurationBoomConfiguration$key,
  );

  const { appendAdditionalCraneAutomatic, clearAdditionalCranesAutomatic } = useFieldAdditionalCranesAutomaticCollection(
    $data?.automaticConfiguration,
  );

  const environment = useRelayEnvironment();

  const { startOperation, endOperation } = useOperations(SYNC_ADDITIONAL_CRANES_AUTOMATIC_OP_KEY);
  const { resetError, setError } = useOperationsError(SYNC_ADDITIONAL_CRANES_AUTOMATIC_OP_KEY);
  const [_, setSubscription] = useCancellableSubscription();

  const refreshAdditionalCranes = useEffectEvent((id: string) => {
    startOperation();
    resetError();

    setSubscription(
      fetchQuery<JobEquipment_useSyncAdditionalCranesAutomaticQuery>(
        environment,
        graphql`
          query JobEquipment_useSyncAdditionalCranesAutomaticQuery($id: ID!) {
            node(id: $id) @required(action: THROW) {
              __typename
              ... on BoomConfiguration {
                additionalBoomConfigurations {
                  additionalBoomConfiguration @required(action: THROW) {
                    capacity
                    craneConfigurationId
                    equipmentKind @required(action: THROW) {
                      id
                      code
                      label
                    }
                    configurationKind @required(action: THROW) {
                      id
                      code
                      label
                    }
                    id
                    label
                  }
                }
              }
            }
          }
        `,
        { id: id },
      ).subscribe({
        error: (error: Error) => {
          Sentry.captureException(error);
          endOperation();
          setError(error);
        },
        next: (value) => {
          if (value.node.__typename !== 'BoomConfiguration') {
            endOperation();
            throw new Error(`Invalid node type, expected BoomConfiguration but got ${value.node.__typename}`);
          }

          for (const { additionalBoomConfiguration } of value.node.additionalBoomConfigurations) {
            appendAdditionalCraneAutomatic({
              id: 'new',
              capacity: {
                capacity: additionalBoomConfiguration.capacity,
                label: `${additionalBoomConfiguration.capacity}`,
              },
              equipmentKind: additionalBoomConfiguration.equipmentKind,
              configurationKind: additionalBoomConfiguration.configurationKind,
              boomConfiguration: {
                id: additionalBoomConfiguration.id,
                craneConfigurationId: additionalBoomConfiguration.craneConfigurationId,
                label: additionalBoomConfiguration.label,
              },
            });
          }
          endOperation();
        },
        unsubscribe: () => {
          endOperation();
          resetError();
        },
      }),
    );
  });

  const clearAdditionalCranes = useEffectEvent(() => clearAdditionalCranesAutomatic());

  useEffect(() => {
    if (!craneConfigurationCollectionFavoriteIsDirty || craneSelectorMode !== 'lifts') {
      return;
    }

    clearAdditionalCranes();
    if (favorite$data?.boomConfigurationId) {
      refreshAdditionalCranes(favorite$data.boomConfigurationId);
    }
  }, [
    clearAdditionalCranes,
    craneConfigurationCollectionFavoriteIsDirty,
    craneSelectorMode,
    favorite$data?.boomConfigurationId,
    refreshAdditionalCranes,
  ]);
}
