import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { BillingCodeLabelResolver$key } from './__generated__/BillingCodeLabelResolver.graphql';

/**
 * @RelayResolver BillingCode.label: Localized
 * @rootFragment BillingCodeLabelResolver
 *
 * An object that contains the label for every language for BillingCodes which includes microDescription and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(billingCode$key: BillingCodeLabelResolver$key): Record<string, string> {
  const billingCode$data = readFragment(
    graphql`
      fragment BillingCodeLabelResolver on BillingCode {
        microDescription
        description
      }
    `,
    billingCode$key,
  );

  return Object.fromEntries(
    Object.entries(billingCode$data.description).map(([k, v]) => [k, `${billingCode$data.microDescription?.[k] ?? v}`]),
  );
}
