import graphql from 'babel-plugin-relay/macro';
import { DataID, fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { ChipTypeMap, IconButton } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  Queryable,
} from './PaginatedAutocomplete';
import { useTranslation } from 'react-i18next';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';
import { convertToTsQuery } from '../utils/stringUtils';
import { resolvedLanguage } from '../../i18n';
import { AccessoryGroupAutocompleteListFragment$data } from './__generated__/AccessoryGroupAutocompleteListFragment.graphql';
import { AccessoryGroupAutocompleteQuery } from './__generated__/AccessoryGroupAutocompleteQuery.graphql';

interface AccessoryGroupAutocompleteProps {
  accessoryTypeCode: number | null;
  dispatchBranchId: DataID | null;
}

export type ForwardAccessoryGroupAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<AccessoryGroupAutocompleteListFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const AccessoryGroupAutocomplete = forwardRef<
  HTMLInputElement,
  AccessoryGroupAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<AccessoryGroupAutocompleteListFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel' | 'queryRef',
      boolean,
      boolean,
      ElementType
    >
>(function AccessoryGroupAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    accessoryTypeCode,
    dispatchBranchId,
    textFieldProps,
    ...paginatedAutocompleteProps
  }: AccessoryGroupAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<AccessoryGroupAutocompleteListFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel' | 'queryRef',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const { t, i18n } = useTranslation('serviceCall');
  const env = useRelayEnvironment();
  const [isSearchingAllBranches, setIsSearchingAllBranches] = useState<boolean | null>(null);

  const queryRef = useRef<Queryable>(null);

  const handleQuery = useCallback(
    (searchTerm: string | null) =>
      fetchQuery<AccessoryGroupAutocompleteQuery>(
        env,
        graphql`
          query AccessoryGroupAutocompleteQuery($searchTerm: String, $accessoryTypeCode: Int, $dispatchBranchId: ID) {
            ...AccessoryGroupAutocompleteListFragment
              @arguments(searchTerm: $searchTerm, accessoryTypeCode: $accessoryTypeCode, dispatchBranchId: $dispatchBranchId)
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
          accessoryTypeCode,
          dispatchBranchId: isSearchingAllBranches ? null : dispatchBranchId,
        },
      ),
    [env, accessoryTypeCode, isSearchingAllBranches, dispatchBranchId],
  );

  const handleSearchDispatchBranchClick = useCallback(() => {
    setIsSearchingAllBranches(false);
  }, []);

  const handleSearchAllBranchesClick = useCallback(() => {
    setIsSearchingAllBranches(true);
  }, []);

  useEffect(() => {
    // do this to avoid calling query on initial render
    if (isSearchingAllBranches != null) {
      queryRef.current?.query();
    }
  }, [isSearchingAllBranches]);

  const startAdornment = dispatchBranchId ? (
    isSearchingAllBranches ? (
      <IconButton
        data-testid='searchDispatchBranchButton'
        onClick={handleSearchDispatchBranchClick}
        disabled={paginatedAutocompleteProps.disabled}>
        <LocationOffOutlinedIcon />
      </IconButton>
    ) : (
      <IconButton
        data-testid='searchAllBranchesButton'
        onClick={handleSearchAllBranchesClick}
        disabled={paginatedAutocompleteProps.disabled}>
        <AddLocationAltOutlinedIcon />
      </IconButton>
    )
  ) : null;

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<AccessoryGroupAutocompleteListFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      ref={ref}
      queryRef={queryRef}
      fragment={graphql`
        fragment AccessoryGroupAutocompleteListFragment on Query
        @refetchable(queryName: "AccessoryGroupAutocompleteListFragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          accessoryTypeCode: { type: "Int" }
          dispatchBranchId: { type: "ID" }
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 25 }
        ) {
          searchResults: searchAccessoryGroups(
            searchTerm: $searchTerm
            accessoryTypeCode: $accessoryTypeCode
            dispatchBranchId: $dispatchBranchId
            after: $cursor
            first: $count
          ) @connection(key: "AccessoryGroupAutocompleteListFragment_searchResults") {
            edges {
              node {
                id
                label
                branch {
                  name
                }
              }
            }
          }
        }
      `}
      onQuery={handleQuery}
      groupBy={(option) => option.branch?.name[resolvedLanguage(i18n)] ?? ''}
      {...paginatedAutocompleteProps}
      textFieldProps={(params) => {
        const p = textFieldProps?.(params);
        return {
          ...p,
          placeholder: t('button.select', { ns: 'common' }),
          InputProps: {
            ...(p?.InputProps ?? params.InputProps),
            startAdornment,
          },
        };
      }}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AccessoryGroupAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<AccessoryGroupAutocompleteListFragment$data>,
      'fragment' | 'onQuery' | 'getOptionLabel' | 'queryRef',
      Multiple,
      DisableClearable,
      ChipComponent
    > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
