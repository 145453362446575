/**
 * @generated SignedSource<<99918c7d1118f201867d5b53d67554a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as billingCodeLabelResolverType } from "../../__resolvers__/BillingCodeLabelResolver";
export type BillingCodeRuleFieldsBillingCodesVisibleFragment$data = {
  readonly effects: {
    readonly billingCodes: {
      readonly visible: ReadonlyArray<{
        readonly code: number;
        readonly id: string;
        readonly label: Record<string, string> | null | undefined;
      } | null | undefined>;
    };
  };
  readonly " $fragmentType": "BillingCodeRuleFieldsBillingCodesVisibleFragment";
};
export type BillingCodeRuleFieldsBillingCodesVisibleFragment$key = {
  readonly " $data"?: BillingCodeRuleFieldsBillingCodesVisibleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsBillingCodesVisibleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFieldsBillingCodesVisibleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EffectsInternal",
      "kind": "LinkedField",
      "name": "effects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingCodeEffects",
          "kind": "LinkedField",
          "name": "billingCodes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BillingCode",
              "kind": "LinkedField",
              "name": "visible",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BillingCodeLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../__resolvers__/BillingCodeLabelResolver').label,
                  "path": "effects.billingCodes.visible.label"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "467cf36a9112a88128a39728aa38cae6";

export default node;
