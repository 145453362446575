/**
 * @generated SignedSource<<6d79cb3b6306523387f405b486ea2d22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as branchLabelResolverType } from "../../__resolvers__/BranchLabelResolver";
import { label as equipmentKindLookupLabelResolverType } from "../../__resolvers__/EquipmentKindLookupLabelResolver";
import { label as representativeLabelResolverType } from "../../__resolvers__/RepresentativeLabelResolver";
export type QuoteListFiltersFragment$data = {
  readonly equipmentKinds: ReadonlyArray<{
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  }>;
  readonly selectedDispatchBranches: ReadonlyArray<{
    readonly __typename: "Branch";
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined>;
  readonly selectedProjectManagers: ReadonlyArray<{
    readonly __typename: "Representative";
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined>;
  readonly selectedRepresentatives: ReadonlyArray<{
    readonly __typename: "Representative";
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined>;
  readonly " $fragmentType": "QuoteListFiltersFragment";
};
export type QuoteListFiltersFragment$key = {
  readonly " $data"?: QuoteListFiltersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteListFiltersFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RepresentativeLabelResolver"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dispatchBranchIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "equipmentKindCodes"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectManagerIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "representativeIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteListFiltersFragment",
  "selections": [
    {
      "alias": "selectedDispatchBranches",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "dispatchBranchIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BranchLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/BranchLabelResolver').label,
              "path": "selectedDispatchBranches.label"
            },
            (v2/*: any*/)
          ],
          "type": "Branch",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "codes",
          "variableName": "equipmentKindCodes"
        }
      ],
      "concreteType": "EquipmentKindLookup",
      "kind": "LinkedField",
      "name": "equipmentKinds",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquipmentKindLookupLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/EquipmentKindLookupLabelResolver').label,
          "path": "equipmentKinds.label"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "selectedProjectManagers",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "projectManagerIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": (v3/*: any*/),
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/RepresentativeLabelResolver').label,
              "path": "selectedProjectManagers.label"
            },
            (v2/*: any*/)
          ],
          "type": "Representative",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "selectedRepresentatives",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "representativeIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": (v3/*: any*/),
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/RepresentativeLabelResolver').label,
              "path": "selectedRepresentatives.label"
            },
            (v2/*: any*/)
          ],
          "type": "Representative",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "27044454fec572fff573624ee882452e";

export default node;
