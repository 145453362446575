import { useEffect, useState } from 'react';

function getOnlineStatus() {
  return typeof navigator !== 'undefined' ? navigator.onLine : true;
}

export function useNavigatorOnline(): boolean {
  const [status, setStatus] = useState(getOnlineStatus());

  useEffect(() => {
    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return status;
}
