/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const craneSelectorModes = ["lifts", "manual"] as const;
export type CraneSelectorMode = typeof craneSelectorModes[number];

export function isCraneSelectorMode(value: string): value is CraneSelectorMode {
  return craneSelectorModes.includes(value)
}

export function asCraneSelectorMode(value: string): CraneSelectorMode | null {
  return isCraneSelectorMode(value) ? value : null;
}

export function castCraneSelectorMode(value: string): CraneSelectorMode {
  if(!isCraneSelectorMode(value)) {
    throw new Error(`Invalid Enum value for type "CraneSelectorMode": "${value}"`);
  }
  
  return value;
}
