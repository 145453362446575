const placeholder = {
  noLifts: 'Aucun levage',
  newLiftName: 'Levage {{number}}',
  selectConfiguration: 'Sélectionner une configuration',
};

const field = {
  name: 'Nom du levage',
  building: {
    height: 'Hauteur (A)',
    interiorRadius: 'Rayon intérieur (B)',
    exteriorRadius: 'Rayon extérieur (C)',
    ceilingHeight: 'Hauteur plafond (D)',
  },
  site: {
    width: 'Largeur (E)',
    length: 'Longueur (F)',
  },
  load: {
    weight: 'Poids (G)',
    height: 'Hauteur (H)',
    width: 'Largeur (I)',
    length: 'Longueur',
  },
  accessories: {
    height: 'Hauteur des accessoires (J)',
    weight: 'Poids des accessoires',
  },
  mode: {
    lifts: 'Sélecteur de grue',
    manual: 'Configuration manuelle',
  },
  equipment: {
    additionalCrane: 'Grue additionnelle',
    addConfiguration: 'Ajouter une configuration',
    boom: 'Flèche',
    boomConfiguration: 'Configuration de flèche',
    capacity: 'Capacité',
    configuration: 'Configuration',
    configurationOption: 'Option de configuration',
    counterWeight: 'Contrepoids',
    jib: 'Fléchette',
    maxWeight: 'Poids de la charge',
    model: 'Modèle',
    noReplacement: 'Aucun remplacement',
    noSubcontractor: 'Aucun sous-traitant',
    offset: 'Offset',
    otherInformation: 'Autres informations',
    radius: 'Rayon',
    requiredEquipment: 'Équipement requis',
    type: 'Genre',
  },
};

const lift = {
  deleteLift: 'Supprimer le levage',
  deleteLiftMessage: 'Voulez-vous vraiment supprimer ce levage\u00a0?',
};

const configurationList = {
  title: 'Liste de configurations',
};

export const craneSelector = {
  addLift: 'Ajouter un levage',
  building: 'Bâtiment',
  configurationList,
  craneSelector: 'Sélecteur de grue',
  craneSelectionMode: 'Sélection de la grue',
  drawing: 'Dessin technique',
  editLift: 'Modifier le levage',
  field,
  lift,
  lifts: 'Levages',
  loadAndAccessories: 'Charge & Accessoires',
  manualConfiguration: 'Configuration manuelle',
  models: 'Modèles correspondants',
  placeholder,
  site: 'Emplacement',
  parallel: 'Parallèle',
  perpendicular: 'Perpendiculaire',
  viewLift: 'Consulter le levage',
};
