import { ElementType, forwardRef, ReactElement, Ref, RefAttributes } from 'react';
import { ChipTypeMap } from '@mui/material';
import { ServiceCallKind, serviceCallKinds } from '../../__enums__/ServiceCallKind';
import { useTranslation } from 'react-i18next';
import { QuoteKind, quoteKinds } from '../../__enums__/QuoteKind';
import { ForwardAutocompleteProps, SelectPicker, SelectPickerProps } from './SelectPicker';

export type ForwardSaleKindAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  SelectPickerProps<QuoteKind | ServiceCallKind, Multiple, DisableClearable, false, ChipComponent> &
    ForwardAutocompleteProps<QuoteKind | ServiceCallKind, Multiple, DisableClearable, false, ChipComponent>,
  'ref' | 'options' | 'getOptionLabel' | 'getOptionKey'
>;

const saleKinds = [...quoteKinds, ...serviceCallKinds].reduce(
  (acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]),
  [] as readonly (QuoteKind | ServiceCallKind)[],
);

export const SaleKindAutocomplete = forwardRef<HTMLInputElement, ForwardSaleKindAutocompleteProps<boolean, boolean, ElementType>>(
  function SaleKindAutocomplete<
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
  >(props: ForwardSaleKindAutocompleteProps<Multiple, DisableClearable, ChipComponent>, ref: Ref<HTMLInputElement>) {
    const { t } = useTranslation('common');

    return (
      <SelectPicker<QuoteKind | ServiceCallKind, Multiple, DisableClearable, false, ChipComponent>
        ref={ref}
        options={saleKinds}
        getOptionKey={(o) => o}
        getOptionLabel={(option) => t(`saleKinds.${option}`)}
        {...props}
      />
    );
  },
) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: ForwardSaleKindAutocompleteProps<Multiple, DisableClearable, ChipComponent> & RefAttributes<HTMLInputElement>,
) => ReactElement;
