import { Fab, FabProps, SpeedDial, SpeedDialAction, SpeedDialActionProps, SpeedDialIcon, SpeedDialProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export interface AutoSpeedDialProps<T> {
  mainOption?: T;
  options?: readonly T[];
  keySelector: (option: T) => string;
  iconSelector: (option: T) => JSX.Element | undefined;
  labelSelector: (option: T) => string | JSX.Element;
  onOptionClick?: (selectedOption: T) => void;
  componentProps?: {
    root?: Partial<Omit<SpeedDialProps, 'ref'>>;
    fab?: Partial<Omit<FabProps, 'onClick'>>;
    speedDialAction?: Partial<Omit<SpeedDialActionProps, 'onClick'>>;
    speedDialActionOption?: (options: T) => Partial<Omit<SpeedDialActionProps, 'onClick'>>;
  };
}

/**
 * Generic speed dial component adapted From Mui
 * @param options Options of the speed dial
 * @param mainOption Main option
 * @param keySelector Selects key of option for translation
 * @param iconSelector Selects icon of option
 * @param labelSelector Selects label of option
 * @param handleOptionClick Click event of option
 * @param componentProps Customizable root, fab, speedDialAction props
 * @constructor
 */
export function AutoSpeedDial<T>({
  mainOption,
  options = [],
  keySelector,
  iconSelector,
  labelSelector,
  onOptionClick: handleOptionClick,
  componentProps,
}: AutoSpeedDialProps<T>) {
  if (options.length === 0) {
    return (
      <Fab onClick={() => mainOption !== undefined && handleOptionClick?.(mainOption)} color='primary' {...componentProps?.fab}>
        <AddIcon />
      </Fab>
    );
  }

  return (
    <SpeedDial icon={<SpeedDialIcon />} {...{ ariaLabel: '', ...componentProps?.root }}>
      {mainOption !== undefined &&
        [mainOption, ...options].map((option) => (
          <SpeedDialAction
            key={keySelector(option)}
            icon={iconSelector(option)}
            tooltipTitle={labelSelector(option)}
            tooltipOpen
            onClick={() => handleOptionClick?.(option)}
            {...componentProps?.speedDialAction}
            {...componentProps?.speedDialActionOption?.(option)}
          />
        ))}
    </SpeedDial>
  );
}
