/**
 * @generated SignedSource<<35fbc306663cec86765831d4d80f02c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenuFragment$data = {
  readonly notifications: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
    }> | null | undefined;
  } | null | undefined;
  readonly unreadNotifications: {
    readonly nodes: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "NotificationMenuFragment";
};
export type NotificationMenuFragment$key = {
  readonly " $data"?: NotificationMenuFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationMenuFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "NotificationRowFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./NotificationMenuFragmentQuery.graphql')
    }
  },
  "name": "NotificationMenuFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "order",
          "value": {
            "createdAt": "DESC"
          }
        }
      ],
      "concreteType": "NotificationsConnection",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "notifications(first:100,order:{\"createdAt\":\"DESC\"})"
    },
    {
      "alias": "unreadNotifications",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "isRead": {
              "eq": false
            }
          }
        }
      ],
      "concreteType": "NotificationsConnection",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "notifications(first:100,where:{\"isRead\":{\"eq\":false}})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "c1fc623da203c4d4cb4b09dfd815530b";

export default node;
