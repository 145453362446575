import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Suspense } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { DataID, useLazyLoadQuery } from 'react-relay';
import { RequirementsDialogQuery } from './__generated__/RequirementsDialogQuery.graphql';

export function RequirementsDialog({
  requirementIds,
  onClose: handleCloseDialog,
  isOpen,
  title,
}: {
  requirementIds: DataID[];
  onClose: () => void;
  isOpen: boolean;
  title: string;
}) {
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} fullScreen={compact} scroll='paper' maxWidth='lg' fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton color='inherit' data-testid='closeButton' onClick={handleCloseDialog} sx={{ ml: -1.5 }}>
          <CloseIcon />
        </IconButton>
        {title}
      </DialogTitle>

      <DialogContent sx={{ [theme.breakpoints.down('sm')]: { pt: '1rem' } }}>
        <Suspense fallback={<Skeleton variant='rounded' height='20rem' />}>
          <RequirementsList requirementsIds={requirementIds} />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
}

function RequirementsList({ requirementsIds }: { requirementsIds: DataID[] }) {
  const descriptions = useLazyLoadQuery<RequirementsDialogQuery>(
    graphql`
      query RequirementsDialogQuery($ids: [ID!]!) {
        nodes(ids: $ids) {
          ... on Requirement {
            id
            code
            description
            name
          }
        }
      }
    `,
    {
      ids: requirementsIds,
    },
    { fetchPolicy: 'store-and-network' },
  );

  return (
    <List sx={{ listStyleType: 'none' }}>
      {descriptions.nodes?.map(
        (requirement) =>
          requirement && (
            <ListItem key={requirement.id} sx={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: '2rem' }}>
              <Typography variant='h6' component='h3'>{`${requirement.code} - ${requirement.name}`}</Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap', ml: '0.75rem' }}>{requirement.description ?? ''} </Typography>
            </ListItem>
          ),
      )}
    </List>
  );
}
