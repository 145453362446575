/**
 * @generated SignedSource<<cb849ca63461a06c768f17083984c622>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorizationWriteFragment$data = {
  readonly me: {
    readonly accessLevels: {
      readonly hasWrite: boolean;
    };
  } | null | undefined;
  readonly " $fragmentType": "AuthorizationWriteFragment";
};
export type AuthorizationWriteFragment$key = {
  readonly " $data"?: AuthorizationWriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationWriteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthorizationWriteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccessLevels",
          "kind": "LinkedField",
          "name": "accessLevels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasWrite",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a6e6b493e4748f76fb5c7680a9873819";

export default node;
