import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import { RateStrategySaveButtonMutation, SaveRateStrategyInput } from './__generated__/RateStrategySaveButtonMutation.graphql';
import { DataID } from 'react-relay';
import { _throw } from '../../common/utils/_throw';
import { useNavigate } from 'react-router-dom';
import { Save } from '@mui/icons-material';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useFormMappings, useFormValidate } from '../../common/utils/forms';
import { rateStrategyFormContext } from './RateStrategyFields';
import LoadingButton from '@mui/lab/LoadingButton';
import { useErrorBanner } from '../../common/components/ErrorBanner';

declare module './RateStrategyFields' {
  interface RateStrategyFieldsMappings {
    save: SaveRateStrategyInput;
  }
}

export function RateStrategySaveButton({ id }: { id: DataID | null }) {
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();
  const { reportUnexpectedError, reportHandledError } = useErrorBanner();

  const [commit, isLoading] = usePromiseMutation<RateStrategySaveButtonMutation>(graphql`
    mutation RateStrategySaveButtonMutation($id: ID, $saveRateStrategy: SaveRateStrategyInput!) {
      saveRateStrategy(id: $id, saveRateStrategy: $saveRateStrategy) {
        rateStrategyLookup {
          id
          ...RateStrategyDetailsPageFragment
        }
        errors {
          __typename
          ... on SalesApiValidationError {
            ...ErrorBannerValidationErrorFragment
          }
        }
      }
    }
  `);

  const { mapAll } = useFormMappings(rateStrategyFormContext);
  const validate = useFormValidate(rateStrategyFormContext);

  const handleSave = useCallback(async () => {
    if (!validate('save')) return;

    try {
      const saveRateStrategy = mapAll('save');

      const { response } = await commit({ variables: { id, saveRateStrategy } });

      if (response?.saveRateStrategy.errors?.length) {
        reportHandledError(response.saveRateStrategy.errors, () => t('errorMessages.errorDuringSave', { ns: 'common' }));
        return;
      }

      if (response && id == null) {
        const newId = response.saveRateStrategy.rateStrategyLookup?.id ?? _throw('Save mutation did not result in a valid rate strategy');
        navigate(`/configuration/rate-strategies/${newId}`, { replace: true });
      }
    } catch {
      reportUnexpectedError(() => t('errorMessages.errorDuringSave', { ns: 'common' }));
    }
  }, [validate, mapAll, commit, id, reportHandledError, t, navigate, reportUnexpectedError]);

  return (
    <LoadingButton loading={isLoading} onClick={handleSave} startIcon={<Save />}>
      {t('button.save', { ns: 'common' })}
    </LoadingButton>
  );
}
