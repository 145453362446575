/**
 * @generated SignedSource<<2d24044b5283b6e26b76be095757ed8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageConditionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsCapacitiesFragment" | "BillingCodeRuleFieldsClientFragment" | "BillingCodeRuleFieldsEquipmentKindsFragment" | "BillingCodeRuleFieldsKindsFragment" | "BillingCodeRuleFieldsNatureOfWorkFragment" | "BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment" | "BillingCodeRuleFieldsQuestionsBase">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageConditionsFragment";
};
export type BillingCodeRuleDetailsPageConditionsFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageConditionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageConditionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageConditionsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsCapacitiesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsEquipmentKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsClientFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsNatureOfWorkFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsQuestionsBase"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "d8d5bb39936c40cc55279d91dfb2bbd1";

export default node;
