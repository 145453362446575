/**
 * @generated SignedSource<<8f3a6720cd80fbbf7ed9b20b882a6aa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateFields_TitleFrFragment$data = {
  readonly title: Record<string, string>;
  readonly " $fragmentType": "AddendumTemplateFields_TitleFrFragment";
};
export type AddendumTemplateFields_TitleFrFragment$key = {
  readonly " $data"?: AddendumTemplateFields_TitleFrFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFields_TitleFrFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateFields_TitleFrFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "d9c0b0f808a6d41f954b261edf2f4888";

export default node;
