/**
 * @generated SignedSource<<1c245db5ee809e9c5863f5a83938c875>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
  readonly " $fragmentType": "SaleProjectFields_WorkDescriptionInput_SuggestionsFragment";
};
export type SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$key = {
  readonly " $data"?: SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_WorkDescriptionInput_SuggestionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_WorkDescriptionInput_SuggestionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipAccessories",
          "variableName": "skipAccessories"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSuggestionsFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "20959701966b4f870350af22b9fd6b72";

export default node;
