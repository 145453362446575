import { Breakpoint, Theme } from '@mui/material';

/**
 * Inspired by MUI useMediaQuery
 * Returns the currently matched media query coming from the theme at the moment of the call
 * Note: It is not responsive, meaning if the media changes while the function is called
 * Only the latest matched media will be returned from the theme.
 *
 * @param theme: The MUI theme containing the media queries
 * @param matchMediaOverride: Allows to override the default match media coming from the window
 * See https://github.com/mui/material-ui/blob/512896973499adbbda057e7f3685d1b23cc02de9/packages/mui-material/src/useMediaQuery/useMediaQuery.js
 */
export default function getMatchedMedia(
  theme: Theme,
  matchMediaOverride: ((query: string) => MediaQueryList) | undefined = undefined,
): Breakpoint | undefined {
  // Wait for jsdom to support the match media feature.
  // All the browsers MUI support have this built-in.
  // This defensive check is here for simplicity.
  // Most of the time, the match media logic isn't central to people tests.
  const supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';
  if (!supportMatchMedia) {
    return undefined;
  }

  const matchMedia = matchMediaOverride ? matchMediaOverride : window.matchMedia;

  return theme.breakpoints.keys.find((k) => {
    const query = theme.breakpoints.only(k).replace(/^@media( ?)/m, '');
    return matchMedia?.(query).matches;
  });
}
