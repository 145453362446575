export const billingCodeRule = {
  dialog: {
    deleteTitle: 'Delete Rule',
    deleteMessage: 'Do you want to delete this rule?',
    confirmation: 'Ok',
  },
  newTitle: 'New Rule',
  editTitle: 'Edit Rule',
  error: {
    errorDuringDelete: 'An error occurred while deleting the rule',
  },
  placeholder: {
    information: 'Information',
    kindListEmpty: 'All kinds',
  },
  fields: {
    label: {
      clients: 'Clients',
      conditions: 'Conditions',
      billingCodes: 'Billing codes',
      description: 'Description',
      kinds: 'Kinds',
      capacities: 'Capacities',
      equipmentKinds: 'Equipment kinds',
      billingCodesVisible: 'Visible',
      billingCodesAutomatic: 'Automatic',
      natureOfWorks: 'Natures of work',
      natureOfWorkSubCategory: 'Nature of work subcategories',
      specialSituation: 'Particular situations',
    },
    specialProjects: 'Special projects',
  },
  title: 'Billing Code Rules',
};
