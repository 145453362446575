import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import { AddendumTemplateSaveButtonMutation, SaveAddendumTemplateInput } from './__generated__/AddendumTemplateSaveButtonMutation.graphql';
import { DataID } from 'react-relay';
import { _throw } from '../../common/utils/_throw';
import { useNavigate } from 'react-router-dom';
import { Save } from '@mui/icons-material';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useFormMappings, useFormValidate } from '../../common/utils/forms';
import { addendumTemplateFormContext } from './AddendumTemplateFields';
import LoadingButton from '@mui/lab/LoadingButton';

declare module './AddendumTemplateFields' {
  interface AddendumTemplateFieldsMappings {
    save: SaveAddendumTemplateInput;
  }
}

export function AddendumTemplateSaveButton({ id }: { id: DataID | null }) {
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();

  const [commit, isLoading] = usePromiseMutation<AddendumTemplateSaveButtonMutation>(graphql`
    mutation AddendumTemplateSaveButtonMutation($id: ID, $saveAddendumTemplate: SaveAddendumTemplateInput!) {
      saveAddendumTemplate(id: $id, saveAddendumTemplate: $saveAddendumTemplate) {
        addendumTemplate {
          id
          ...AddendumTemplateDetailsPageFragment
        }
      }
    }
  `);

  const { mapAll } = useFormMappings(addendumTemplateFormContext);
  const validate = useFormValidate(addendumTemplateFormContext);

  const handleSave = useCallback(async () => {
    if (!validate('save')) return;

    const saveAddendumTemplate = mapAll('save');

    const { response } = await commit({ variables: { id, saveAddendumTemplate } });
    if (response && id == null) {
      const newId = response.saveAddendumTemplate.addendumTemplate?.id ?? _throw('Save mutation did not result in a valid addendum');
      navigate(`/configuration/addenda/${newId}`, { replace: true });
    }
  }, [validate, mapAll, commit, id, navigate]);

  return (
    <LoadingButton loading={isLoading} onClick={handleSave} startIcon={<Save />}>
      {t('button.save', { ns: 'common' })}
    </LoadingButton>
  );
}
