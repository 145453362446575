/**
 * @generated SignedSource<<d736438c322354e3af0fc9ac9175819e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleCraneSelectorFields_Manual_JibLengthFragment$data = {
  readonly jibLength: string | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_Manual_JibLengthFragment";
};
export type SaleCraneSelectorFields_Manual_JibLengthFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_Manual_JibLengthFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_Manual_JibLengthFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_Manual_JibLengthFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "21695464a6f7b8147d679f60af8b1961";

export default node;
