import { useFragment } from 'react-relay';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import graphql from 'babel-plugin-relay/macro';
import { RefObject, useCallback } from 'react';
import { _throw } from '../common/utils/_throw';
import { useNavigate } from 'react-router-dom';
import { useFormMappings } from '../common/utils/forms';
import { jobStageBaseFormContext } from '../jobs/JobStageBaseFields';
import { merge } from 'ts-deepmerge';
import { NAVIGATE_PREVENT_BLOCK_STATE } from '../common/hooks/usePreventNavigationOnFormDirty';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { workPlanningFormContext } from './WorkPlanningFields';
import { SaveWorkPlanningInput, WorkPlanningCopyButtonMutation } from './__generated__/WorkPlanningCopyButtonMutation.graphql';
import { WorkPlanningCopyButtonFragment$key } from './__generated__/WorkPlanningCopyButtonFragment.graphql';
import { WorkPlanningCopyDialogRef } from './WorkPlanningCopyDialog';
import { useErrorBanner } from '../common/components/ErrorBanner';
import { useOperations } from '../AppSharedState';

export const WORK_PLANNING_COPY_OPERATION_KEY = 'WorkPlanningCopyButtonMutation';

declare module '../jobs/JobStageBaseFields' {
  interface JobStageBaseFieldsMappings {
    save: Pick<SaveWorkPlanningInput, 'attachments' | 'costsBase' | 'clientBase' | 'equipmentBase' | 'projectBase' | 'questionsBase'>;
  }
}

declare module './WorkPlanningFields' {
  interface WorkPlanningFieldsMappings {
    save: Pick<SaveWorkPlanningInput, 'project'>;
  }
}

export function WorkPlanningCopyButton({
  $key,
  copyDialogRef,
  onClick,
  disabled,
}: {
  $key: WorkPlanningCopyButtonFragment$key;
  copyDialogRef: RefObject<WorkPlanningCopyDialogRef>;
  onClick: () => void;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();
  const { dismissError, reportUnexpectedError, reportHandledError } = useErrorBanner();
  const { startOperation, endOperation, hasOperationInFlight } = useOperations(WORK_PLANNING_COPY_OPERATION_KEY);

  const $data = useFragment(
    graphql`
      fragment WorkPlanningCopyButtonFragment on WorkPlanningJobRevision {
        id
      }
    `,
    $key,
  );

  const [commit] = usePromiseMutation<WorkPlanningCopyButtonMutation>(graphql`
    mutation WorkPlanningCopyButtonMutation($revisionId: ID, $snapshot: SaveWorkPlanningInput!) {
      addWorkPlanningRevision(input: { revisionId: $revisionId, snapshot: $snapshot }) {
        lifeCycleBranch {
          latestWorkPlanning {
            id
            ...WorkPlanningDetails_PageFragment @arguments(isCopy: false, skipAccessories: false, skipAttachments: false)
          }
        }
        errors {
          __typename
          ... on JobRevisionIsNotTheLatestError {
            ...ErrorBannerJobRevisionIsNotTheLatestErrorFragment
          }
        }
      }
    }
  `);

  const { mapDirty: mapDirtyBase } = useFormMappings(jobStageBaseFormContext);
  const { mapDirty: mapDirtyWorkPlanning } = useFormMappings(workPlanningFormContext);

  const handleConfirm = useCallback(
    async (search: string) => {
      if (hasOperationInFlight) return;
      dismissError(true);

      const requestBase = mapDirtyBase('save');
      const requestWorkPlanning = mapDirtyWorkPlanning('save');
      const request = merge({}, requestBase, requestWorkPlanning);

      if (Object.keys(request).length > 0) {
        startOperation();
        try {
          const { response } = await commit({
            variables: {
              revisionId: $data.id,
              snapshot: request,
            },
          });

          if (response?.addWorkPlanningRevision.errors?.length) {
            reportHandledError(response.addWorkPlanningRevision.errors, () => t('errorMessages.errorDuringCopy', { ns: 'common' }));
            return;
          }

          if (response) {
            const newRevisionId =
              response.addWorkPlanningRevision.lifeCycleBranch?.latestWorkPlanning?.id ??
              _throw('Copy mutation did not result in a valid revision');
            navigate(`/work-plannings/copy?revisionId=${newRevisionId}&` + search, { replace: true, state: NAVIGATE_PREVENT_BLOCK_STATE });
          }
        } catch {
          reportUnexpectedError(() => t('errorMessages.errorDuringCopy', { ns: 'common' }));
        } finally {
          endOperation();
          copyDialogRef.current?.close();
        }
      } else {
        navigate(`/work-plannings/copy?revisionId=${$data.id}&` + search, { replace: true, state: NAVIGATE_PREVENT_BLOCK_STATE });
      }
    },
    [
      hasOperationInFlight,
      dismissError,
      mapDirtyBase,
      mapDirtyWorkPlanning,
      startOperation,
      commit,
      $data.id,
      reportHandledError,
      t,
      navigate,
      reportUnexpectedError,
      endOperation,
      copyDialogRef,
    ],
  );

  const handleClick = useCallback(() => {
    // forbid save commands from running in parallel and cannot copy if there is no revision
    if (disabled || !$data?.id) {
      return;
    }

    onClick();
    copyDialogRef.current?.open(handleConfirm);
  }, [copyDialogRef, disabled, handleConfirm, $data?.id, onClick]);

  return (
    <MenuItem onClick={handleClick} disabled={disabled}>
      <ContentCopyIcon sx={{ mr: '0.5rem' }} />
      {t('button.copy', { ns: 'common' })}
    </MenuItem>
  );
}
