/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const salesQuestionKinds = ["accidentScene", "bargeWork", "clamShellBucketOrGrapple", "conventionalCrawlerCranesWithTravel", "conventionalCrawlerCranesWithoutTravel", "craneOrObstacleProximity", "events", "excavationProximityLessThan10Feet", "excavationProximityMoreThan10Feet", "hazardousTerrain", "jettyBridgeViaductDamSlabOrParkingDeck", "luffingJibOrExtensionInstallation", "movingCraneOrEquipmentWithPulling", "movingCraneOrEquipmentWithoutPulling", "peopleLifting", "powerlineProximity", "tandemLiftingClientCrane", "tandemLiftingGuayCrane", "unloadingOnQuay", "valueOf1MillionOrMore", "videoOrPhotoShooting", "windTurbines", "workOf80TOrMore"] as const;
export type SalesQuestionKind = typeof salesQuestionKinds[number];

export function isSalesQuestionKind(value: string): value is SalesQuestionKind {
  return salesQuestionKinds.includes(value)
}

export function asSalesQuestionKind(value: string): SalesQuestionKind | null {
  return isSalesQuestionKind(value) ? value : null;
}

export function castSalesQuestionKind(value: string): SalesQuestionKind {
  if(!isSalesQuestionKind(value)) {
    throw new Error(`Invalid Enum value for type "SalesQuestionKind": "${value}"`);
  }
  
  return value;
}
