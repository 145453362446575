/**
 * @generated SignedSource<<85f1ecf9d4bdef454cc5c00234adf6b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type craneCapacityAutocompleteFragmentQuery$variables = {
  configurationKindCode?: number | null | undefined;
  equipmentKindCode?: number | null | undefined;
  searchTerm?: string | null | undefined;
  skip?: number | null | undefined;
  vehicleIds?: ReadonlyArray<string> | null | undefined;
};
export type craneCapacityAutocompleteFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CraneCapacityAutocompleteFragment">;
};
export type craneCapacityAutocompleteFragmentQuery = {
  response: craneCapacityAutocompleteFragmentQuery$data;
  variables: craneCapacityAutocompleteFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "configurationKindCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equipmentKindCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  },
  {
    "defaultValue": 0,
    "kind": "LocalArgument",
    "name": "skip"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vehicleIds"
  }
],
v1 = {
  "kind": "Variable",
  "name": "configurationKindCode",
  "variableName": "configurationKindCode"
},
v2 = {
  "kind": "Variable",
  "name": "equipmentKindCode",
  "variableName": "equipmentKindCode"
},
v3 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v4 = {
  "kind": "Variable",
  "name": "skip",
  "variableName": "skip"
},
v5 = {
  "kind": "Variable",
  "name": "vehicleIds",
  "variableName": "vehicleIds"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "craneCapacityAutocompleteFragmentQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CraneCapacityAutocompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "craneCapacityAutocompleteFragmentQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "Literal",
            "name": "take",
            "value": 25
          },
          (v5/*: any*/)
        ],
        "concreteType": "SearchCraneCapacitiesCollectionSegment",
        "kind": "LinkedField",
        "name": "searchCraneCapacities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FullTextSearchResultOfCraneCapacity",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CraneCapacity",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/)
                      ],
                      "type": "CraneCapacity",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionSegmentInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9c05c82d4cbe545870daf110cdcef0e",
    "id": null,
    "metadata": {},
    "name": "craneCapacityAutocompleteFragmentQuery",
    "operationKind": "query",
    "text": "query craneCapacityAutocompleteFragmentQuery(\n  $configurationKindCode: Int\n  $equipmentKindCode: Int\n  $searchTerm: String\n  $skip: Int = 0\n  $vehicleIds: [String!]\n) {\n  ...CraneCapacityAutocompleteFragment_1le3vd\n}\n\nfragment CraneCapacityAutocompleteFragment_1le3vd on Query {\n  searchResults: searchCraneCapacities(searchTerm: $searchTerm, vehicleIds: $vehicleIds, equipmentKindCode: $equipmentKindCode, configurationKindCode: $configurationKindCode, skip: $skip, take: 25) {\n    items {\n      result {\n        capacity\n        ...CraneCapacityLabelResolver\n      }\n    }\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n\nfragment CraneCapacityLabelResolver on CraneCapacity {\n  capacity\n}\n"
  }
};
})();

(node as any).hash = "e97d300e6193ed325eea619b923ba44c";

export default node;
