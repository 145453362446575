/**
 * @generated SignedSource<<0510e5ae52ae180731426d64791180de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuoteListFragment$data = {
  readonly searchQuotes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly lifeCycleBranchId: string;
        readonly " $fragmentSpreads": FragmentRefs<"QuoteList_ItemFragment" | "QuoteList_RowFragment">;
      };
    }> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ResponsiveGridFragment">;
  } | null | undefined;
  readonly " $fragmentType": "QuoteListFragment";
};
export type QuoteListFragment$key = {
  readonly " $data"?: QuoteListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteListFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "representativeIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./QuoteListFragmentQuery.graphql')
    }
  },
  "name": "QuoteListFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "before",
          "variableName": "before"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "last",
          "variableName": "last"
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            {
              "snapshot": {
                "statusOrder": "ASC"
              }
            },
            {
              "snapshot": {
                "projectBase": {
                  "arrivalDate": {
                    "date": "ASC"
                  }
                }
              }
            },
            {
              "id": "ASC"
            }
          ]
        },
        {
          "kind": "Variable",
          "name": "representativeIds",
          "variableName": "representativeIds"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "SearchQuotesConnection",
      "kind": "LinkedField",
      "name": "searchQuotes",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ResponsiveGridFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchQuotesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuoteJobRevision",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lifeCycleBranchId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "QuoteList_RowFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "QuoteList_ItemFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b0d3e8144480f13d5d01551a3d69eb23";

export default node;
