import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { PlacesAutocompleteResultLabelResolver$key } from './__generated__/PlacesAutocompleteResultLabelResolver.graphql';

/**
 * @RelayResolver PlacesAutocompleteResult.label: String
 * @rootFragment PlacesAutocompleteResultLabelResolver
 *
 * A label for placesAutocompleteResult which includes name and name.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(placesAutocompleteResult$key: PlacesAutocompleteResultLabelResolver$key): string {
  const placesAutocompleteResult$data = readFragment(
    graphql`
      fragment PlacesAutocompleteResultLabelResolver on PlacesAutocompleteResult {
        description
      }
    `,
    placesAutocompleteResult$key,
  );
  return placesAutocompleteResult$data.description;
}
