/**
 * @generated SignedSource<<1cb1a44400f5c5bbb169e73b7e3b32e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateFragment">;
  readonly " $fragmentType": "SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment";
};
export type SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$key = {
  readonly " $data"?: SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCopy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isCopy",
          "variableName": "isCopy"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_ArrivalDateFragment"
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};

(node as any).hash = "cfe3bf7839280a701597a3e8f445f4c3";

export default node;
