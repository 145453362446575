/**
 * @generated SignedSource<<30f511cf75d87f0e7b05919049ac57da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionsBaseFields_BerlinWallFragment$data = {
  readonly isBerlinWallOrSupportedExcavation: boolean;
  readonly " $fragmentType": "QuestionsBaseFields_BerlinWallFragment";
};
export type QuestionsBaseFields_BerlinWallFragment$key = {
  readonly " $data"?: QuestionsBaseFields_BerlinWallFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsBaseFields_BerlinWallFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionsBaseFields_BerlinWallFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBerlinWallOrSupportedExcavation",
      "storageKey": null
    }
  ],
  "type": "SalesQuestionForExcavationProximityLessThan10Feet",
  "abstractKey": null
};

(node as any).hash = "8b7a29adaa85ff1bbe8dcf5613566c1c";

export default node;
