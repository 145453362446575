import { Breakpoints, Theme, useMediaQuery } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { Params, useLocation, useNavigate, useOutlet, useParams } from 'react-router-dom';
import { useEffectEvent } from '../utils/effectUtils';

/**
 * Toggles between a matched component or a configurable fallback depending on a given mediaQuery.
 *
 * While you should always prioritize using actual CSS media queries, or the useMediaQuery hook for this behavior, it is
 * sometimes necessary to use a component to implement this behavior, notably, in routes.
 *
 * This component has two modes of operation.
 *  - It can either accept a fallback component, which will render in place of the match component when appropriate,
 *  - Or, a specific route to redirect to. This second behavior is useful when dealing with mobile/desktop-only routes
 *    but comes with the caveat that it might render the match component for a frame while the redirection happens. This
 *    is done to reduce flickering when the component is used to control the first visible component of the page. If
 *    a frame perfect redirection is required, you can always use the fallback component mode and manage the redirection
 *    manually.
 * @param mediaQuery  The media query that controls whether to show the match or to fallback to the secondary behavior.
 * @param match       The primary component to render when the media query is matched.
 * @param fallback    The fallback component to render when the media query is not matched.
 * @param to          The route to navigate to when the media query is not matched.
 * @param placeholder The component to render while the redirection is taking effect.
 */
export function MediaQuerySwitch({
  mediaQuery,
  match,
  ...rest
}: {
  mediaQuery: (breakpoints: Breakpoints) => string;
  match: ReactNode;
} & ({ fallback: ReactNode } | { to: string | ((params: Readonly<Params>) => string); placeholder?: ReactNode })) {
  const query = useMediaQuery((theme: Theme) => mediaQuery(theme.breakpoints));

  if (query) {
    return match;
  }

  if ('fallback' in rest) {
    return rest.fallback;
  }

  return <FallbackNavigation placeholder={rest.placeholder ?? match} to={rest.to} />;
}

function FallbackNavigation({ placeholder, to }: { placeholder: ReactNode; to: string | ((params: Readonly<Params>) => string) }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const outlet = useOutlet();

  const redirect = useEffectEvent(() => {
    // HACK: Since this component is meant to be used in nested routes, there might be multiple instances of the
    //  FallbackNavigation in the tree at the same time, all rendered with different props. This checks makes
    //  sure that only the most nested instance will trigger by attempting to render sub-routes if they match
    //  and only navigating when no route matches.
    if (outlet) {
      return;
    }

    const target = typeof to === 'function' ? to(params) : to;

    navigate(target + search, { replace: true, relative: 'path' });
  });
  useEffect(() => {
    redirect();
  }, [redirect]);

  return placeholder;
}
