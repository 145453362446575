import { PickersActionBarProps } from '@mui/x-date-pickers-pro';
import { Button, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @description Custom action bar for the MUI DatePickers to allow the action buttons to have different variants to better
 * fit our dialog action guidelines: variant standard for main action and variant text for secondary actions.
 * Also translate the button texts instead of using built in localeText, since we use fixed adapterLocale 'fr-CA' in the
 * LocalizationProvider
 */
export function CustomActionBar(props: PickersActionBarProps) {
  const { t } = useTranslation('common');
  const { onAccept: handleAccept, onClear: handleClear, onCancel: handleCancel, onSetToday: handleSetToday, actions, className } = props;

  if (actions == null || actions.length === 0) {
    return null;
  }

  return (
    <DialogActions className={className}>
      {actions.map((a) => {
        switch (a) {
          case 'accept':
            return (
              <Button key='accept' onClick={handleAccept}>
                {t('button.ok')}
              </Button>
            );
          case 'cancel':
            return (
              <Button key='cancel' onClick={handleCancel} variant='text'>
                {t('button.cancel')}
              </Button>
            );
          case 'clear':
            return (
              <Button key='clear' onClick={handleClear} variant='text'>
                {t('button.clear')}
              </Button>
            );
          case 'today':
            return (
              <Button key='today' onClick={handleSetToday} variant='text'>
                {t('dateTime.today')}
              </Button>
            );
          default:
            return null;
        }
      })}
    </DialogActions>
  );
}
