export const undefinedCoalesce = <T>(maybeUndefinedValue: T | undefined, fallbackValue: T): T =>
  maybeUndefinedValue === undefined ? fallbackValue : maybeUndefinedValue;

/**
 * A type-guard function that matches only defined types.
 * @param value
 */
export function isDefined<T>(value: T | null | undefined): value is T {
  return value != null;
}

/**
 * Returns a type-guard function that matches a discriminant from a discriminated union.
 * @param discriminantKey   The property key used as a discriminant in the union.
 * @param discriminantValue The unique value identifying the type to guard against.
 */
export function discriminate<K extends PropertyKey, V extends string | number | boolean>(discriminantKey: K, discriminantValue: V) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <T extends Record<K, any>>(obj: T & Record<K, V extends T[K] ? T[K] : V>): obj is Extract<T, Record<K, V>> =>
    obj[discriminantKey] === discriminantValue;
}
