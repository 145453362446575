import { useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { ButtonProps } from '@mui/material';
import { MoreButton, MoreButtonProps } from './MoreButton';

export interface AutoSplitButtonProps<T> {
  options?: T[];
  mainOption?: T;
  keySelector: (option: T) => string;
  labelSelector: (option: T) => string | JSX.Element;
  onOptionClick: (selectedOption: T) => void;
  componentProps?: {
    root?: Partial<Omit<ButtonGroupProps, 'ref'>>;
    buttonMain?: Partial<Omit<ButtonProps, 'onClick'>>;
    buttonMenu?: Partial<Omit<ButtonProps, 'ref' | 'onClick'>>;
    menu?: Partial<NonNullable<MoreButtonProps['componentProps']>['menu']>;
    menuPaper?: Partial<NonNullable<MoreButtonProps['componentProps']>['paper']>;
    menuItem?: Partial<Omit<MenuItemProps, 'onClick' | 'selected'>>;
    menuItemOption?: (option: T) => Partial<Omit<MenuItemProps, 'onClick' | 'selected'>>;
  };
}

/**
 *  Generic split button component adapted From Mui
 *  If there are no options passed, a default button is rendered
 *  https://mui.com/material-ui/react-button-group/#split-button
 * @param options Options in the split button
 * @param mainOption Main option
 * @param labelSelector Selects label of option
 * @param keySelector Selects key of option for translation
 * @param handleOptionClick Click event of option
 * @param componentProps Customizable root, buttonMain, buttonMenu, menuItem props
 * @constructor
 */
export function AutoSplitButton<T>({
  options = [],
  mainOption,
  labelSelector,
  keySelector,
  onOptionClick: handleOptionClick,
  componentProps,
}: AutoSplitButtonProps<T>) {
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMainOnClick = () => {
    if (mainOption !== undefined) {
      handleOptionClick?.(mainOption);
    }
  };

  if (options.length === 0) {
    return (
      <Button onClick={handleMainOnClick} {...componentProps?.buttonMain}>
        {mainOption !== undefined && labelSelector(mainOption)}
      </Button>
    );
  }

  return (
    <>
      <ButtonGroup variant='contained' ref={anchorRef} {...componentProps?.root}>
        <Button onClick={handleMainOnClick} {...componentProps?.buttonMain}>
          {mainOption !== undefined && labelSelector(mainOption)}
        </Button>
        <MoreButton
          componentProps={{
            root: {
              size: 'small',
              ...componentProps?.buttonMenu,
            },
            menu: { sx: { mt: '0.125rem' }, ...componentProps?.menu },
            paper: componentProps?.menuPaper,
          }}
          items={(handleClose) =>
            options.map(
              (option) =>
                option !== mainOption && (
                  <MenuItem
                    key={keySelector(option)}
                    onClick={() => {
                      handleClose();
                      handleOptionClick(option);
                    }}
                    {...componentProps?.menuItem}
                    {...componentProps?.menuItemOption?.(option)}>
                    {labelSelector(option)}
                  </MenuItem>
                ),
            )
          }
        />
      </ButtonGroup>
    </>
  );
}
