/**
 * @generated SignedSource<<27a66473cdc7c55ae059c899fb751345>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryTypeLabelResolver$data = {
  readonly code: number;
  readonly shortDescription: Record<string, string>;
  readonly " $fragmentType": "AccessoryTypeLabelResolver";
};
export type AccessoryTypeLabelResolver$key = {
  readonly " $data"?: AccessoryTypeLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryTypeLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryTypeLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    }
  ],
  "type": "AccessoryTypeLookup",
  "abstractKey": null
};

(node as any).hash = "54d1fe2c6097d867b07aef89c365aa63";

export default node;
