import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { BranchLabelResolver$key } from './__generated__/BranchLabelResolver.graphql';

/**
 * @RelayResolver Branch.label: Localized
 * @rootFragment BranchLabelResolver
 *
 * An object that contains a label for every language for Branch which includes number and name.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(branch$key: BranchLabelResolver$key): Record<string, string> {
  const branch$data = readFragment(
    graphql`
      fragment BranchLabelResolver on Branch {
        number
        name
      }
    `,
    branch$key,
  );
  return Object.fromEntries(Object.entries(branch$data.name).map(([k, v]) => [k, `${branch$data.number} - ${v}`]));
}
