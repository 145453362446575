/**
 * @generated SignedSource<<a660bd1e4f5b45839b377cface5f5999>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneChartListDeactivateButtonMutation$variables = {
  id: string;
};
export type CraneChartListDeactivateButtonMutation$data = {
  readonly deactivateCraneChartBlob: {
    readonly craneChartBlobMetadata: {
      readonly " $fragmentSpreads": FragmentRefs<"CraneChartListItemFragment">;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    }> | null | undefined;
  };
};
export type CraneChartListDeactivateButtonMutation = {
  response: CraneChartListDeactivateButtonMutation$data;
  variables: CraneChartListDeactivateButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "blobId",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CraneChartListDeactivateButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateCraneChartBlobPayload",
        "kind": "LinkedField",
        "name": "deactivateCraneChartBlob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CraneChartBlobMetadata",
            "kind": "LinkedField",
            "name": "craneChartBlobMetadata",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CraneChartListItemFragment"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CraneChartListDeactivateButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateCraneChartBlobPayload",
        "kind": "LinkedField",
        "name": "deactivateCraneChartBlob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CraneChartBlobMetadata",
            "kind": "LinkedField",
            "name": "craneChartBlobMetadata",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CraneChart",
                "kind": "LinkedField",
                "name": "craneChart",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "byteCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "craneChartId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "make",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "model",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquipmentKindLookup",
                "kind": "LinkedField",
                "name": "equipmentKind",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "abbreviation",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "capacity",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02e2ca05016f1e8d4e7fd67f19a35b60",
    "id": null,
    "metadata": {},
    "name": "CraneChartListDeactivateButtonMutation",
    "operationKind": "mutation",
    "text": "mutation CraneChartListDeactivateButtonMutation(\n  $id: ID!\n) {\n  deactivateCraneChartBlob(input: {blobId: $id}) {\n    craneChartBlobMetadata {\n      ...CraneChartListItemFragment\n      id\n    }\n    errors {\n      __typename\n    }\n  }\n}\n\nfragment CraneChartListActionsFragment on CraneChartBlobMetadata {\n  ...CraneChartListDeactivateButtonFragment\n  craneChart {\n    id\n  }\n}\n\nfragment CraneChartListDeactivateButtonFragment on CraneChartBlobMetadata {\n  id\n}\n\nfragment CraneChartListItemFragment on CraneChartBlobMetadata {\n  ...CraneChartListActionsFragment\n  id\n  name\n  byteCount\n  status\n  updatedAt\n  craneChartId\n  craneChart {\n    id\n  }\n  make\n  model\n  equipmentKind {\n    abbreviation\n    id\n  }\n  capacity\n}\n"
  }
};
})();

(node as any).hash = "39744e8fe7ef8c5d21c9efc0931e2f16";

export default node;
