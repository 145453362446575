/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const quoteKinds = ["accessoriesRental", "bare", "laborRental", "liftingPlan", "liftingTest", "operatedHourly", "operatedMonthly", "rollingEquipment"] as const;
export type QuoteKind = typeof quoteKinds[number];

export function isQuoteKind(value: string): value is QuoteKind {
  return quoteKinds.includes(value)
}

export function asQuoteKind(value: string): QuoteKind | null {
  return isQuoteKind(value) ? value : null;
}

export function castQuoteKind(value: string): QuoteKind {
  if(!isQuoteKind(value)) {
    throw new Error(`Invalid Enum value for type "QuoteKind": "${value}"`);
  }
  
  return value;
}
