/**
 * @generated SignedSource<<04c07110387e4c5f56097fbe5652e6c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneChartListFragment$data = {
  readonly craneChartBlobMetadatas: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"CraneChartListItemFragment">;
      };
    }> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ResponsiveGridFragment">;
  } | null | undefined;
  readonly " $fragmentType": "CraneChartListFragment";
};
export type CraneChartListFragment$key = {
  readonly " $data"?: CraneChartListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneChartListFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./CraneChartListFragmentQuery.graphql')
    }
  },
  "name": "CraneChartListFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "before",
          "variableName": "before"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "last",
          "variableName": "last"
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            {
              "createdAt": "DESC"
            }
          ]
        }
      ],
      "concreteType": "CraneChartBlobMetadatasConnection",
      "kind": "LinkedField",
      "name": "craneChartBlobMetadatas",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ResponsiveGridFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneChartBlobMetadatasEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CraneChartBlobMetadata",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CraneChartListItemFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7c91dfd786eb9cdaedcbe8d3923195e3";

export default node;
