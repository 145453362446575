import { Button, ButtonProps, CircularProgress, useTheme } from '@mui/material';

type Props = {
  isLoading: boolean;
  icon?: JSX.Element;
  component?: React.ElementType;
} & ButtonProps;

/**
 *  Generic Loading Button
 *
 * @deprecated Prefer https://mui.com/material-ui/react-button/#loading-button
 * @param isLoading
 * @param icon
 * @param component
 * @param sx
 * @param rest
 *
 * @deprecated Prefer MUI LoadingButton
 */
export function LoadingButton({ isLoading, icon, component, sx, ...rest }: Props) {
  return (
    <Button sx={{ ...sx, gap: '0.5rem' }} {...rest} disabled={isLoading || rest.disabled} {...(component && { component })}>
      {icon ? isLoading ? <LoadingButtonSpinner /> : icon : isLoading && <LoadingButtonSpinner />}
      {/* TODO hide children while loading, keep them hidden in the DOM and overlay the spinner on top of it to preserve the button's size */}
      {rest.children}
    </Button>
  );
}

export function LoadingButtonSpinner() {
  const theme = useTheme();

  return <CircularProgress size={24} thickness={5} sx={{ p: 0.5, color: theme.typography.disabled }} data-testid='loadingButtonSpinner' />;
}
