/**
 * @generated SignedSource<<8442b66d882ddc1c7d641de28ed2b7e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageFormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageConditionsFragment" | "BillingCodeRuleDetailsPageEffectsFragment" | "BillingCodeRuleDetailsPageInformationFragment">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageFormFragment";
};
export type BillingCodeRuleDetailsPageFormFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageFormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPageInformationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPageConditionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPageEffectsFragment"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "9c408c6748b098f1117dd7800418e5b1";

export default node;
