import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Disposable } from 'relay-runtime';

export function useRetainQuery(): readonly [subscriptionRef: MutableRefObject<Disposable | undefined>, set: (sub?: Disposable) => void] {
  const query = useRef<Disposable>();

  useEffect(() => {
    return () => query.current?.dispose();
  }, []);

  const set = useCallback((q?: Disposable) => {
    query.current?.dispose();
    query.current = q;
  }, []);

  return [query, set] as const;
}
