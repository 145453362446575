/**
 * @generated SignedSource<<753d34ab0becb9626b8df726c7d0d3e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleEquipmentFields_PreventReplacementFragment$data = {
  readonly preventReplacement: boolean;
  readonly " $fragmentType": "SaleEquipmentFields_PreventReplacementFragment";
};
export type SaleEquipmentFields_PreventReplacementFragment$key = {
  readonly " $data"?: SaleEquipmentFields_PreventReplacementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleEquipmentFields_PreventReplacementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleEquipmentFields_PreventReplacementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preventReplacement",
      "storageKey": null
    }
  ],
  "type": "ISaleEquipment",
  "abstractKey": "__isISaleEquipment"
};

(node as any).hash = "8a4d0e5d548526a4b0650efe86555add";

export default node;
