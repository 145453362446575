/** A read-only Map that can be called as a function to get a value. */
export interface QuickMap<K, V> extends ReadonlyMap<K, V> {
  (key: K): V | undefined;

  /** @inheritDoc ReadonlyMap.forEach */
  // Override the type of the last callback argument to be a QuickMap<K, V> instead of a ReadonlyMap<K, V>.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forEach(callbackfn: (value: V, key: K, map: QuickMap<K, V>) => void, thisArg?: any): void;
}

/** Turns any `ReadonlyMap<K, V>` into a `QuickMap<K, V>`. */
export const createQuickMap = <K, V>(map: ReadonlyMap<K, V>): QuickMap<K, V> => {
  const quickMap: QuickMap<K, V> = Object.defineProperties(
    Object.assign((key: K): V | undefined => map.get(key), {
      // Dummy value to satisfy type constraints, overridden below
      size: 0,
      get: (key: K) => map.get(key),
      has: (key: K) => map.has(key),
      keys: () => map.keys(),
      values: () => map.values(),
      entries: () => map.entries(),
      [Symbol.iterator]: () => map[Symbol.iterator](),
      forEach: (callbackfn: (value: V, key: K, map: QuickMap<K, V>) => void, thisArg?: unknown) => {
        map.forEach((value, key) => {
          // Override the last argument so that it's the QuickMap object and not the underlying ReadonlyMap object
          callbackfn.call(thisArg, value, key, quickMap);
        });
      },
    }),
    { size: { get: () => map.size } },
  );
  return quickMap;
};
