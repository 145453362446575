/**
 * @generated SignedSource<<578770ae75650237e5dceb8a46f6e0d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_ClientRequirementsSuggestionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
  readonly " $fragmentType": "ClientBaseFields_ClientRequirementsSuggestionsFragment";
};
export type ClientBaseFields_ClientRequirementsSuggestionsFragment$key = {
  readonly " $data"?: ClientBaseFields_ClientRequirementsSuggestionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientRequirementsSuggestionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_ClientRequirementsSuggestionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipAccessories",
          "variableName": "skipAccessories"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSuggestionsFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "1928d117b2d50f3f1848bf72183f2b97";

export default node;
