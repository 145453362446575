/**
 * @generated SignedSource<<bab4ab3c6a44575fd0dd6daa50ab8200>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRowFragment$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_BaseFragment" | "NotificationRow_PermalinkFragment">;
  readonly " $fragmentType": "NotificationRowFragment";
};
export type NotificationRowFragment$key = {
  readonly " $data"?: NotificationRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_BaseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_PermalinkFragment"
    }
  ],
  "type": "INotification",
  "abstractKey": "__isINotification"
};

(node as any).hash = "947c7223928be8f2f254ef16c20a8fbe";

export default node;
