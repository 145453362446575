/**
 * @generated SignedSource<<0469a71c8009d54c4db51f0416884693>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageRootEtagFragment$data = {
  readonly eTag: string;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageRootEtagFragment";
};
export type BillingCodeRuleDetailsPageRootEtagFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageRootEtagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageRootEtagFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageRootEtagFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eTag",
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "8050ebf31df6100fb5da25feeea5208b";

export default node;
