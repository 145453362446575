import { IMaskInput, IMaskInputProps } from 'react-imask';
import { forwardRef, useCallback } from 'react';

export type MaskInputProps = IMaskInputProps<HTMLInputElement>;

export const MaskInput = forwardRef<HTMLInputElement, MaskInputProps>(function MaskInput({ onChange, name, value, ...other }, ref) {
  const handleAccept = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (val: string) => value !== val && onChange?.({ target: { name, value: val } } as any),
    [value, onChange, name],
  );

  return <IMaskInput name={name} onAccept={handleAccept} inputRef={ref} unmask value={value} {...other} />;
});
