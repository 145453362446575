/**
 * @generated SignedSource<<7e50ff1e86c0e229a18afd7957185aa8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_UseDispatchBranchChangeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_SalesBranchFragment">;
  readonly " $fragmentType": "SaleProjectFields_UseDispatchBranchChangeFragment";
};
export type SaleProjectFields_UseDispatchBranchChangeFragment$key = {
  readonly " $data"?: SaleProjectFields_UseDispatchBranchChangeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_UseDispatchBranchChangeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_UseDispatchBranchChangeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_DispatchBranchFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_SalesBranchFragment"
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "f97c52c961e503b3bf70a1f00ebb73bd";

export default node;
