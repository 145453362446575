import { useEffect } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GuayLogo } from './common/svg/GuayLogo';

interface Props {
  onConfirmUpdate: () => void;
}

export function AppForceUpdate({ onConfirmUpdate: handleConfirmUpdate }: Props) {
  const { t } = useTranslation('layout');

  useEffect(() => {
    document.body.classList.add('disable-scroll');
    return () => document.body.classList.remove('disable-scroll');
  }, []);

  return (
    <Box
      sx={{
        height: '100dvh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundImage: `url(/imgs/fullscreen-background-dim.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Paper sx={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}>
        <Box sx={{ alignSelf: 'center', paddingBottom: '1rem' }}>
          <GuayLogo />
        </Box>
        <Typography sx={{ paddingBottom: '1rem' }}>{t('forceRefresh.version')}</Typography>
        <Button onClick={handleConfirmUpdate}>{t('forceRefresh.refresh')}</Button>
      </Paper>
    </Box>
  );
}
