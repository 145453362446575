import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { CraneCapacityLabelResolver$key } from './__generated__/CraneCapacityLabelResolver.graphql';

/**
 * @RelayResolver CraneCapacity.label: String
 * @rootFragment CraneCapacityLabelResolver
 *
 * A label for CraneCapacity which includes capacity.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(craneCapacity$key: CraneCapacityLabelResolver$key): string {
  const craneCapacity$data = readFragment(
    graphql`
      fragment CraneCapacityLabelResolver on CraneCapacity {
        capacity
      }
    `,
    craneCapacity$key,
  );
  return `${craneCapacity$data.capacity}`;
}
