import graphql from 'babel-plugin-relay/macro';
import { DataID, useFragment, useRefetchableFragment } from 'react-relay';
import {
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  ResponsiveGridForwardProps,
  useElementFactory,
} from '../../common/components/ResponsiveGrid';
import { Box } from '@mui/material';
import { EllipsisedTypography } from '../../common/components/EllipsisedTypography';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddendumTemplateListItemFragment$key } from './__generated__/AddendumTemplateListItemFragment.graphql';
import { AddendumTemplateListFragment$key } from './__generated__/AddendumTemplateListFragment.graphql';
import { visuallyHidden } from '@mui/utils';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';

interface Props extends ResponsiveGridForwardProps {
  fragmentKey: AddendumTemplateListFragment$key;
}

export function AddendumTemplateList({ fragmentKey }: Props) {
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment AddendumTemplateListFragment on Query
      @refetchable(queryName: "AddendumListFragmentQuery")
      @argumentDefinitions(after: { type: "String" }, before: { type: "String" }, first: { type: "Int" }, last: { type: "Int" }) {
        addendumTemplates(after: $after, before: $before, first: $first, last: $last, order: [{ title: { fr: ASC } }, { id: ASC }]) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              ...AddendumTemplateListItemFragment
            }
          }
        }
      }
    `,
    fragmentKey,
  );

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'title', label: t('addenda.field.title'), size: 'auto' },
    { id: 'kind', label: t('addenda.field.kind'), size: 'auto' },
  ];

  const rowElementFactory = useElementFactory(data.addendumTemplates?.edges, (node, orderByColumns) => (
    <AddendumTemplateListRow fragmentKey={node} orderByColumns={orderByColumns} />
  ));

  const handleItemClick = useCallback((id: DataID) => navigate(`/configuration/addenda/${id}`), [navigate]);

  return (
    data.addendumTemplates && (
      <ResponsiveGrid
        connectionFragmentKey={data.addendumTemplates}
        refetch={refetch}
        onItemClick={handleItemClick}
        columnDefinitions={columns}
        rowElementFactory={rowElementFactory}
        listElementFactory={() => <></>}
        listSx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          'li > *': {
            px: '1rem',
          },
        }}
      />
    )
  );
}

function AddendumTemplateListRow({
  fragmentKey,
  orderByColumns,
}: {
  fragmentKey: AddendumTemplateListItemFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { t } = useAmbientTranslation();
  const addendum = useFragment(
    graphql`
      fragment AddendumTemplateListItemFragment on AddendumTemplate {
        id
        title
        kind
      }
    `,
    fragmentKey,
  );

  return (
    <>
      <h3 style={visuallyHidden}>
        {addendum.title.fr}
        <br />
        {addendum.kind}
      </h3>
      {orderByColumns([
        <Box key='title' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            {addendum.title.fr}
          </EllipsisedTypography>
        </Box>,
        <Box key='kind' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            {t(`addendumKind.${addendum.kind}`, { ns: 'jobs' })}
          </EllipsisedTypography>
        </Box>,
      ])}
    </>
  );
}
