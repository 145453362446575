/**
 * @generated SignedSource<<af961f1c2675ff1ca722f2a99a833584>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkPlanningFields_NotesFragment$data = {
  readonly notes: string | null | undefined;
  readonly " $fragmentType": "WorkPlanningFields_NotesFragment";
};
export type WorkPlanningFields_NotesFragment$key = {
  readonly " $data"?: WorkPlanningFields_NotesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningFields_NotesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningFields_NotesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    }
  ],
  "type": "WorkPlanningProjectInternal",
  "abstractKey": null
};

(node as any).hash = "e3f7000d25d2cc35df125aac41c068d2";

export default node;
