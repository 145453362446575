/**
 * @generated SignedSource<<e7b03ac06a0e746d05393c2f4e93c68e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RateStrategyLookupLabelResolver$data = {
  readonly description: Record<string, string>;
  readonly " $fragmentType": "RateStrategyLookupLabelResolver";
};
export type RateStrategyLookupLabelResolver$key = {
  readonly " $data"?: RateStrategyLookupLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"RateStrategyLookupLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RateStrategyLookupLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "RateStrategyLookup",
  "abstractKey": null
};

(node as any).hash = "fb23f0ff1769d58c0db7ecd1f1a30544";

export default node;
