/**
 * @generated SignedSource<<17bd677df891918ee9b20f85d6a5a25f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRow_BaseFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly isRead: boolean;
  readonly " $fragmentType": "NotificationRow_BaseFragment";
};
export type NotificationRow_BaseFragment$key = {
  readonly " $data"?: NotificationRow_BaseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_BaseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRow_BaseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRead",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "INotification",
  "abstractKey": "__isINotification"
};

(node as any).hash = "4aa19d2b215a0e016982b9f51a027bda";

export default node;
