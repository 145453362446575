/**
 * @generated SignedSource<<ea39348b5a02fb86f35f3d11c927e5b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenu_NotificationSubscription$variables = Record<PropertyKey, never>;
export type NotificationMenu_NotificationSubscription$data = {
  readonly notificationChanged: {
    readonly notification: {
      readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
    };
  };
};
export type NotificationMenu_NotificationSubscription = {
  response: NotificationMenu_NotificationSubscription$data;
  variables: NotificationMenu_NotificationSubscription$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationMenu_NotificationSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationChangedPayload",
        "kind": "LinkedField",
        "name": "notificationChanged",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationRowFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationMenu_NotificationSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationChangedPayload",
        "kind": "LinkedField",
        "name": "notificationChanged",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isINotification"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRead",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "permalink",
                    "storageKey": null
                  }
                ],
                "type": "PermalinkNotification",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d41e29eff892fd3f23a534a349da268a",
    "id": null,
    "metadata": {},
    "name": "NotificationMenu_NotificationSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationMenu_NotificationSubscription {\n  notificationChanged {\n    notification {\n      __typename\n      ...NotificationRowFragment\n      id\n    }\n  }\n}\n\nfragment NotificationRowFragment on INotification {\n  __isINotification: __typename\n  __typename\n  ...NotificationRow_BaseFragment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_BaseFragment on INotification {\n  __isINotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotification {\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n"
  }
};

(node as any).hash = "74221aa91c28fc94ba4e6e41654c9385";

export default node;
