import { debounce } from '@mui/material';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, SyntheticEvent, useMemo, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { ChipTypeMap } from '@mui/material/Chip';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { AddressInputSearchQuery } from './__generated__/AddressInputSearchQuery.graphql';
import { useCancellableSubscription } from '../hooks/useCancellableSubscription';
import { ForwardAutocompleteProps, SelectPicker, SelectPickerProps, SelectPickerValue } from './SelectPicker';

export type Place = {
  readonly label: string | null | undefined;
  readonly placeId: string;
  readonly description: string;
};

export interface AddressInputProps<
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    SelectPickerProps<Place, false, DisableClearable, true, ChipComponent> &
      ForwardAutocompleteProps<Place, false, DisableClearable, true, ChipComponent>,
    'freeSolo' | 'autoSelect' | 'options' | 'renderInput' | 'onChange' | 'getOptionKey'
  > {
  onChange: (value: SelectPickerValue<Place, false, DisableClearable, true>, event: SyntheticEvent) => void;
  onQuery?: (searchTerms: string, event: SyntheticEvent) => void;
  sessionToken: string;
  error?: boolean;
}

export const AddressInput = forwardRef<HTMLDivElement, AddressInputProps<boolean | undefined, ElementType>>(function AddressInput<
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    onChange: handleOnChange,
    onQuery: handleQuery,
    textFieldProps,
    sessionToken,
    error,
    ...autocompleteProps
  }: AddressInputProps<DisableClearable, ChipComponent>,
  ref: Ref<HTMLDivElement>,
) {
  const [searchResults, setSearchResults] = useState<readonly Place[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [, setSubscription] = useCancellableSubscription();
  const relay = useRelayEnvironment();
  const doQuery = useMemo(
    () =>
      debounce((searchTerm: string, event: SyntheticEvent) => {
        handleQuery?.(searchTerm, event);
        setSubscription(
          fetchQuery<AddressInputSearchQuery>(
            relay,
            graphql`
              query AddressInputSearchQuery($searchTerm: String!, $sessionToken: String!) {
                placesAutocomplete(searchTerm: $searchTerm, sessionToken: $sessionToken) {
                  description
                  label
                  placeId
                }
              }
            `,
            {
              sessionToken,
              searchTerm,
            },
          ).subscribe({
            start: () => {
              setIsLoading(true);
            },
            next: (result) => {
              setSearchResults(structuredClone(result.placesAutocomplete));
            },
            complete: () => setIsLoading(false),
            error: () => setIsLoading(false),
            unsubscribe: () => setIsLoading(false),
          }),
        );
      }, 400),
    [handleQuery, setSubscription, relay, sessionToken],
  );

  return (
    <SelectPicker
      ref={ref}
      {...autocompleteProps}
      filterOptions={(o) => o}
      loading={isLoading}
      onChange={(ev, value, reason) => {
        // The blur removes the place id (takes only the string in the autocomplete)
        // So in order to keep differentiating a place from a freeSolo
        if (reason !== 'blur') {
          handleOnChange?.(value, ev);
        }
      }}
      onInputChange={(ev, value, reason) => {
        if (reason !== 'reset') {
          doQuery(value, ev);
        }
      }}
      getOptionKey={(o) => (typeof o === 'string' ? o : o.placeId)}
      getOptionLabel={(o) => (typeof o === 'string' ? o : (o.label ?? `${o}`))}
      textFieldProps={(params) => ({
        ...textFieldProps?.(params),
        multiline: true,
        error: error,
      })}
      options={searchResults}
      freeSolo
      autoSelect
    />
  );
}) as <DisableClearable extends boolean | undefined, ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']>(
  props: AddressInputProps<DisableClearable, ChipComponent> & RefAttributes<HTMLDivElement>,
) => ReactElement;
