/**
 * @generated SignedSource<<b6905d927dd544710b2ca856ae5ff284>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ErrorBannerValidationErrorFragment$data = {
  readonly errors: ReadonlyArray<{
    readonly actualValue: string | null | undefined;
    readonly code: string;
    readonly comparisonPropertyName: string | null | undefined;
    readonly comparisonValue: string | null | undefined;
    readonly index: number | null | undefined;
    readonly propertyName: string;
  }>;
  readonly " $fragmentType": "ErrorBannerValidationErrorFragment";
};
export type ErrorBannerValidationErrorFragment$key = {
  readonly " $data"?: ErrorBannerValidationErrorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorBannerValidationErrorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorBannerValidationErrorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesValidationError",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "propertyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "comparisonValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "comparisonPropertyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SalesApiValidationError",
  "abstractKey": null
};

(node as any).hash = "bfa89a35385200c9528190eed8c7751d";

export default node;
