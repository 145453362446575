import { Navigate, Route } from 'react-router-dom';
import { MediaQuerySwitch } from '../common/components/MediaQuerySwitch';
import { Breakpoints } from '@mui/material';
import { TranslationNamespaceContext } from '../common/hooks/useAmbientTranslation';
import { BillingCodeRuleListPage } from './BillingCodeRuleListPage';
import { BillingCodeRuleDetailsPage } from './BillingCodeRuleDetailsPage';

const downSm = (b: Breakpoints) => b.down('sm');

export const billingCodeRuleRoutes = (
  <>
    <Route
      path='billing-code-rules'
      element={
        // Billing code rules routes are not meant to be used on mobile
        <MediaQuerySwitch
          mediaQuery={downSm}
          match={<Navigate to='..' replace />}
          fallback={
            <TranslationNamespaceContext.Provider value='billingCodeRule'>
              <BillingCodeRuleListPage />
            </TranslationNamespaceContext.Provider>
          }
        />
      }
    />
    <Route
      path='billing-code-rules/:id'
      element={
        <MediaQuerySwitch
          mediaQuery={downSm}
          match={<Navigate to='..' replace />}
          fallback={
            <TranslationNamespaceContext.Provider value='billingCodeRule'>
              <BillingCodeRuleDetailsPage />
            </TranslationNamespaceContext.Provider>
          }
        />
      }
    />
  </>
);
