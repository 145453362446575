import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { NatureOfWorkLabelResolver$key } from './__generated__/NatureOfWorkLabelResolver.graphql';

/**
 * @RelayResolver NatureOfWorkLookup.label: Localized
 * @rootFragment NatureOfWorkLabelResolver
 *
 * An object that contains the label for every language for Nature of work which includes code and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(natureOfWork$key: NatureOfWorkLabelResolver$key): Record<string, string> {
  const natureOfWork$data = readFragment(
    graphql`
      fragment NatureOfWorkLabelResolver on NatureOfWorkLookup {
        code
        description
      }
    `,
    natureOfWork$key,
  );
  return Object.fromEntries(Object.entries(natureOfWork$data.description).map(([k, v]) => [k, `${natureOfWork$data.code} - ${v}`]));
}
