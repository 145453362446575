/**
 * @generated SignedSource<<20d7422045af7c4b0a32779121f76adc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreditRisk = "danger" | "extreme" | "warning" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreditAlertIndicatorFragment$data = {
  readonly creditRisk: CreditRisk | null | undefined;
  readonly creditRiskDescription: Record<string, string> | null | undefined;
  readonly " $fragmentType": "CreditAlertIndicatorFragment";
};
export type CreditAlertIndicatorFragment$key = {
  readonly " $data"?: CreditAlertIndicatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreditAlertIndicatorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreditAlertIndicatorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creditRisk",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creditRiskDescription",
      "storageKey": null
    }
  ],
  "type": "Client",
  "abstractKey": null
};

(node as any).hash = "9f40c6ecf832cc637631aecf55048edc";

export default node;
