import { createFieldKey, SetValueFn, useField, useFieldMapper } from '../../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useCallback } from 'react';
import { SaleEquipmentFields_PreventSubcontractingFragment$key } from './__generated__/SaleEquipmentFields_PreventSubcontractingFragment.graphql';
import { SaleEquipmentFields_PreventReplacementFragment$key } from './__generated__/SaleEquipmentFields_PreventReplacementFragment.graphql';
import { saleFormContext } from '../SaleFields';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';

export function useJobKindHasEquipment(kind: QuoteKind | ServiceCallKind) {
  return ['bare', 'laborRental', 'operatedHourly', 'operatedMonthly'].includes(kind);
}

export function useJobKindRequiresEquipment(kind: QuoteKind | ServiceCallKind) {
  return ['bare', 'operatedHourly', 'operatedMonthly'].includes(kind);
}

const fieldPreventReplacementKey = createFieldKey<boolean>();
export function useFieldPreventReplacement($key: SaleEquipmentFields_PreventReplacementFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_PreventReplacementFragment on ISaleEquipment {
        preventReplacement
      }
    `,
    $key,
  );

  const [preventReplacement, setPreventReplacement] = useField(
    saleFormContext,
    fieldPreventReplacementKey,
    $data?.preventReplacement ?? false,
  );

  const useMapper = useFieldMapper(saleFormContext, fieldPreventReplacementKey);
  useMapper((v) => ({ equipmentSale: { preventReplacement: v } }), [], 'save');

  const renderPreventReplacement = useCallback(
    () => (
      <SaleEquipmentFields_CheckBoxInput
        value={preventReplacement}
        setValue={setPreventReplacement}
        labelKey='field.equipment.noReplacement'
        disabled={disabled}
      />
    ),
    [disabled, preventReplacement, setPreventReplacement],
  );

  return { preventReplacement, renderPreventReplacement };
}

const fieldPreventSubcontractingKey = createFieldKey<boolean>();
export function useFieldPreventSubcontracting(
  $key: SaleEquipmentFields_PreventSubcontractingFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_PreventSubcontractingFragment on ISaleEquipment {
        preventSubcontracting
      }
    `,
    $key,
  );

  const [preventSubcontracting, setPreventSubcontracting] = useField(
    saleFormContext,
    fieldPreventSubcontractingKey,
    $data?.preventSubcontracting ?? false,
  );

  const useMapper = useFieldMapper(saleFormContext, fieldPreventSubcontractingKey);
  useMapper((v) => ({ equipmentSale: { preventSubcontracting: v } }), [], 'save');

  const renderPreventSubcontracting = useCallback(
    () => (
      <SaleEquipmentFields_CheckBoxInput
        value={preventSubcontracting}
        setValue={setPreventSubcontracting}
        labelKey='field.equipment.noSubcontractor'
        disabled={disabled}
      />
    ),
    [disabled, preventSubcontracting, setPreventSubcontracting],
  );

  return { preventSubcontracting, renderPreventSubcontracting };
}

function SaleEquipmentFields_CheckBoxInput({
  value,
  setValue,
  labelKey,
  disabled,
}: {
  value: boolean;
  setValue: SetValueFn<boolean>;
  labelKey: string;
  disabled: boolean;
}) {
  const { t } = useTranslation('craneSelector');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.checked);
    },
    [setValue],
  );

  return (
    <FormControlLabel
      disabled={disabled}
      control={<Checkbox checked={value} onChange={handleChange} />}
      label={t(labelKey)}
      labelPlacement='end'
    />
  );
}
