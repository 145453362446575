/**
 * @generated SignedSource<<b8a8d9415618c08c74ecbacd8d18bd4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment" | "AccessoryLinesFields_SyncBillableWithAccessoryType" | "AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment">;
  readonly " $fragmentType": "AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment";
};
export type AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment$key = {
  readonly " $data"?: AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryLinesFields_SyncBillableWithAccessoryType"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment"
    }
  ],
  "type": "AccessoryTypeLookup",
  "abstractKey": null
};

(node as any).hash = "f6699af71dd4b6a265db7b8cfde12017";

export default node;
