import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Subscription } from 'relay-runtime/lib/network/RelayObservable';

export function useCancellableSubscription(): readonly [
  subscriptionRef: MutableRefObject<Subscription | undefined>,
  set: (sub?: Subscription) => void,
] {
  const subscription = useRef<Subscription>();

  useEffect(() => {
    return () => subscription.current?.unsubscribe();
  }, []);

  const set = useCallback((sub?: Subscription) => {
    subscription.current?.unsubscribe();
    subscription.current = sub;
  }, []);

  return [subscription, set] as const;
}
