import { useTranslation } from 'react-i18next';

import { useFormContext } from '../common/utils/formUtils';
import { resolvedLanguage } from '../i18n';
import {
  ResponsiveGridColumnDefinition,
  ResponsiveGridContent,
  ResponsiveGridHeader,
  ResponsiveGridItem,
} from '../common/components/ResponsiveGrid';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import { FormTextFieldCell } from '../common/components/FormGrid';

import { BillingCodeFormValues } from './tabEnumsSections/BillingCodeForm';

export function BillingCodeList() {
  const { t } = useTranslation('configuration');
  const { getValues } = useFormContext<BillingCodeFormValues>();

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'code', label: t('enums.billingCode.code'), size: 'minmax(1rem, 1fr)', sx: { fontWeight: 'bold' } },
    {
      id: 'description',
      label: t('enums.billingCode.description'),
      size: 'minmax(6rem, 4fr)',
      sx: { fontWeight: 'bold' },
    },
    { id: 'labelFr', label: t('enums.billingCode.labelFr'), size: 'minmax(6rem, 3fr)', sx: { fontWeight: 'bold' } },
    { id: 'labelEn', label: t('enums.billingCode.labelEn'), size: 'minmax(6rem, 3fr)', sx: { fontWeight: 'bold' } },
  ];

  return (
    <ResponsiveGridContent
      formMode
      gridMode
      gridTemplateColumns='minmax(1rem, 1fr) minmax(6rem, 4fr) minmax(6rem, 3fr) minmax(6rem, 3fr)'
      listSx={{
        // Adds a padding in cells to improve readability, specially when elipsing.
        'li.responsive-grid__header  > *': {
          px: '1rem',
        },
        'li:not(.responsive-grid__header)  > *': {
          px: '0.5rem',
        },
      }}>
      <ResponsiveGridHeader columnsDefinitions={columns} />
      {getValues('billingCodes').map((code, idx) => (
        <BillingCodeRow value={code} key={code.id} index={idx} />
      ))}
    </ResponsiveGridContent>
  );
}

function BillingCodeRow({ value, index }: { value: BillingCodeFormValues['billingCodes'][number]; index: number }) {
  const { i18n } = useTranslation('configuration');
  const i18nLang = resolvedLanguage(i18n);

  return (
    <ResponsiveGridItem id={value.id}>
      <EllipsisedTypography key='code' variant='body2' component='div'>
        {value.code}
      </EllipsisedTypography>
      <EllipsisedTypography key='description' variant='body2' component='div'>
        {value.description[i18nLang]}
      </EllipsisedTypography>
      <FormTextFieldCell
        key='labelFr'
        type='string'
        name={`billingCodes.${index}.microDescription.fr`}
        borderless
        sx={{ paddingLeft: '0 !important' }}
      />
      <FormTextFieldCell
        key='labelEn'
        type='string'
        name={`billingCodes.${index}.microDescription.en`}
        borderless
        sx={{ paddingLeft: '0 !important' }}
      />
    </ResponsiveGridItem>
  );
}
