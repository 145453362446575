/**
 * @generated SignedSource<<7e7e087859b5402c234f4119c69e77c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_IsEstablishedScheduleFragment$data = {
  readonly isEstablishedSchedule: boolean;
  readonly " $fragmentType": "SaleProjectFields_IsEstablishedScheduleFragment";
};
export type SaleProjectFields_IsEstablishedScheduleFragment$key = {
  readonly " $data"?: SaleProjectFields_IsEstablishedScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_IsEstablishedScheduleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_IsEstablishedScheduleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEstablishedSchedule",
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "5f3ce4646a8772c409e9a182c1552581";

export default node;
