/**
 * @generated SignedSource<<6919c00e1863f5db3b97d4e3c88be4bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RateStrategyFieldsEnglishLabelFragment$data = {
  readonly description: Record<string, string>;
  readonly " $fragmentType": "RateStrategyFieldsEnglishLabelFragment";
};
export type RateStrategyFieldsEnglishLabelFragment$key = {
  readonly " $data"?: RateStrategyFieldsEnglishLabelFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RateStrategyFieldsEnglishLabelFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RateStrategyFieldsEnglishLabelFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "RateStrategyLookup",
  "abstractKey": null
};

(node as any).hash = "1e0404dada7fd83147cc84ff2fc1ec95";

export default node;
