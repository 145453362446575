import { Patchable, Patchable_EditItem, PatchableList, PatchableList_RenderFn, PatchablePropsBase } from './patchable';
import { ReactNode, useCallback, useMemo } from 'react';
import { FormContext, FormProvider, SetValueFn } from './forms';

/**
 * Produce a function to render a patchable list as a collection of sub forms.
 * Typically used to make inline editing components.
 * @param values      The list of V to render.
 * @param setValues   A setter to update the list.
 * @param keySelector Returns a value to use as a key from a V.
 * @param context     The subform context to provide for each item in the collection.
 */
export function useRenderSubFormCollection<V extends Patchable<Record<string | number, unknown>>>(
  values: V[],
  setValues: SetValueFn<V[]>,
  keySelector: (value: V) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormContext<any>,
) {
  return useCallback(
    (render: PatchableList_RenderFn<V>) => (
      <PatchableList
        values={values}
        setValues={setValues}
        keySelector={keySelector}
        render={(props, index) => <FormProvider context={context}>{render(props, index)}</FormProvider>}
      />
    ),
    [context, keySelector, setValues, values],
  );
}

export type PatchableNewProps<V extends Patchable<unknown>> = PatchablePropsBase<V>;
export type PatchableForm_RenderNewFn<V extends Patchable<unknown>> = (props: PatchableNewProps<V>) => ReactNode;
/**
 * Produce a function to render an interface to add a new item in a patchable collection using a sub form.
 * @param values    A list of V that will be monitored to ensure the form is re-mounted every time a new item is added or removed.
 * @param addValue  A callback that adds the value in the list.
 * @param context   The subform context to provide for the item.
 */
export function useRenderNewItem<V extends Patchable<Record<string, unknown>>>(
  values: V[],
  addValue: (value: V) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormContext<any>,
) {
  return useCallback(
    (render: PatchableForm_RenderNewFn<V>) => {
      const key = `new-${values.length}`;
      return (
        <FormProvider key={key} context={context}>
          {<PatchableForm_NewItem<V> id={key} addValue={addValue} render={render} />}
        </FormProvider>
      );
    },
    [addValue, context, values.length],
  );
}

function PatchableForm_NewItem<V extends Patchable<Record<string, unknown>>>({
  id,
  addValue,
  render,
}: {
  id: string;
  addValue: (value: V) => void;
  render: PatchableForm_RenderNewFn<V>;
}) {
  const props = useMemo(() => ({ id, onChange: addValue }), [id, addValue]);
  return render(props);
}

export type PatchableForm_RenderNotFoundFn = (key: string) => ReactNode;
export type PatchableForm_RenderEditFn<V extends Patchable<Record<string, unknown>>> = PatchableList_RenderFn<V>;
/**
 * Produce a function to render an interface to edit an existing item from a patchable collection using a sub form.
 * @param values      A list of V from which the item will be picked.
 * @param setValues   A setter to update the list.
 * @param keySelector Returns a value to use as a key from a V.
 * @param context     The subform context to provide for the item.
 */
export function useRenderItemById<V extends Patchable<Record<string, unknown>>>(
  values: V[],
  setValues: SetValueFn<V[]>,
  keySelector: (value: V) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormContext<any>,
) {
  return useCallback(
    (key: string, render: PatchableForm_RenderEditFn<V>, renderNotFound: PatchableForm_RenderNotFoundFn) => {
      const index = values.findIndex((v) => keySelector(v) === key);
      return index < 0 ? (
        renderNotFound(key)
      ) : (
        <FormProvider key={key} context={context}>
          <Patchable_EditItem index={index} value={values[index]} setValues={setValues} keySelector={keySelector} render={render} />
        </FormProvider>
      );
    },
    [context, keySelector, setValues, values],
  );
}
