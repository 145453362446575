/**
 * @generated SignedSource<<906356e7beaa4654ef9178abf978a212>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_SaleBranchInput_SuggestionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
  readonly " $fragmentType": "SaleProjectFields_SaleBranchInput_SuggestionsFragment";
};
export type SaleProjectFields_SaleBranchInput_SuggestionsFragment$key = {
  readonly " $data"?: SaleProjectFields_SaleBranchInput_SuggestionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_SaleBranchInput_SuggestionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_SaleBranchInput_SuggestionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipAccessories",
          "variableName": "skipAccessories"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSuggestionsFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "9c5183e744715855f822689f6bf1c27f";

export default node;
