/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const clientLanguages = ["en", "fr"] as const;
export type ClientLanguage = typeof clientLanguages[number];

export function isClientLanguage(value: string): value is ClientLanguage {
  return clientLanguages.includes(value)
}

export function asClientLanguage(value: string): ClientLanguage | null {
  return isClientLanguage(value) ? value : null;
}

export function castClientLanguage(value: string): ClientLanguage {
  if(!isClientLanguage(value)) {
    throw new Error(`Invalid Enum value for type "ClientLanguage": "${value}"`);
  }
  
  return value;
}
