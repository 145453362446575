/**
 * @generated SignedSource<<3a3c043958ee873878ccfe4ed6e4b671>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_QueriedCopyClientAddressButtonQuery$variables = {
  id: string;
};
export type ProjectBaseFields_QueriedCopyClientAddressButtonQuery$data = {
  readonly assignedClient: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_CopyClientAddressButtonFragment">;
  };
};
export type ProjectBaseFields_QueriedCopyClientAddressButtonQuery = {
  response: ProjectBaseFields_QueriedCopyClientAddressButtonQuery$data;
  variables: ProjectBaseFields_QueriedCopyClientAddressButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectBaseFields_QueriedCopyClientAddressButtonQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "assignedClient",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_CopyClientAddressButtonFragment"
                }
              ],
              "type": "Client",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "assignedClient"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectBaseFields_QueriedCopyClientAddressButtonQuery",
    "selections": [
      {
        "alias": "assignedClient",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlaceToken",
                        "kind": "LinkedField",
                        "name": "placeToken",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "placeId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDirectSales",
                    "storageKey": null
                  }
                ],
                "type": "IOverridableClient",
                "abstractKey": "__isIOverridableClient"
              }
            ],
            "type": "Client",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f338af1e636f7f31b4636a250a019d81",
    "id": null,
    "metadata": {},
    "name": "ProjectBaseFields_QueriedCopyClientAddressButtonQuery",
    "operationKind": "query",
    "text": "query ProjectBaseFields_QueriedCopyClientAddressButtonQuery(\n  $id: ID!\n) {\n  assignedClient: node(id: $id) {\n    __typename\n    ... on Client {\n      ...ProjectBaseFields_CopyClientAddressButtonFragment\n    }\n    id\n  }\n}\n\nfragment ClientBaseFields_ClientOverrides_IsDirectSalesFragment on IOverridableClient {\n  __isIOverridableClient: __typename\n  isDirectSales\n}\n\nfragment ClientBaseFields_ClientOverrides_LocationFragment on IOverridableClient {\n  __isIOverridableClient: __typename\n  location {\n    address\n    placeToken {\n      id\n      placeId\n    }\n  }\n}\n\nfragment ProjectBaseFields_CopyClientAddressButtonFragment on IOverridableClient {\n  __isIOverridableClient: __typename\n  location {\n    address\n    placeToken {\n      id\n      placeId\n    }\n  }\n  ...ClientBaseFields_ClientOverrides_IsDirectSalesFragment\n  ...ClientBaseFields_ClientOverrides_LocationFragment\n}\n"
  }
};
})();

(node as any).hash = "1b30d9800ec73fc25f0f8a6b3e8da783";

export default node;
