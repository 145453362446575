import { createTheme, ThemeOptions } from '@mui/material/styles';
import { alpha, getContrastRatio, hslToRgb, lighten, PaletteColor, PaletteColorOptions } from '@mui/material';
import { CSSProperties } from 'react';
import { defaultTimezone } from './common/utils/dateTimeUtils';

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    drawer: {
      width: {
        wide: string;
        narrow: string;
      };
    };
  }
}

declare module '@mui/material/styles' {
  interface PaletteOptions {
    draft?: PaletteColorOptions;
    advanced?: PaletteColorOptions;
    setback?: PaletteColorOptions;
    pushback?: PaletteColorOptions;
    validation?: PaletteColorOptions;
    done?: PaletteColorOptions;
    archived?: PaletteColorOptions;
    cancel?: PaletteColorOptions;
    suggestion?: PaletteColorOptions;
  }

  interface Palette {
    draft: PaletteColor;
    advanced: PaletteColor;
    setback: PaletteColor;
    pushback: PaletteColor;
    validation: PaletteColor;
    done: PaletteColor;
    archived: PaletteColor;
    cancel: PaletteColor;
    suggestion: PaletteColor;
  }

  interface ChipPropsColorOverrides {
    draft: true;
    advanced: true;
    setback: true;
    pushback: true;
    validation: true;
    done: true;
    archived: true;
    cancel: true;
  }

  interface TypographyVariants {
    disabled: CSSProperties;
  }

  interface TypographyVariantsOptions {
    disabled?: CSSProperties;
  }

  interface ButtonPropsColorOverrides {
    draft: true;
    advanced: true;
    setback: true;
    pushback: true;
    validation: true;
    done: true;
    archived: true;
    cancel: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    draft: true;
    advanced: true;
    setback: true;
    pushback: true;
    validation: true;
    done: true;
    archived: true;
    cancel: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    toolbar: true;
  }

  interface ButtonPropsColorOverrides {
    draft: true;
    advanced: true;
    setback: true;
    pushback: true;
    validation: true;
    done: true;
    archived: true;
    cancel: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    disabled: true;
  }
}

const mainTextColor = alpha('#000000', 0.87);
const invertedTextColor = '#FFFFFF';

// base theme to allow reuse of palette in default export https://mui.com/material-ui/customization/theming/
const baseTheme = createTheme({
  palette: {
    text: {
      primary: mainTextColor,
    },
    background: {
      default: '#F4F4F4',
    },
    primary: {
      main: hslToRgb('hsl(0, 0%, 19%)'),
      light: hslToRgb('hsl(0, 0%, 39%)'),
      dark: hslToRgb('hsl(0, 0%, 9%)'),
    },
    secondary: {
      main: hslToRgb('hsl(353, 78%, 48%)'),
      light: hslToRgb('hsl(359, 100%, 66%)'),
      dark: hslToRgb('hsl(349, 100%, 37%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(353, 78%, 48%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    info: {
      main: hslToRgb('hsl(205, 66%, 42%)'),
      light: hslToRgb('hsl(199, 98%, 48%)'),
      dark: hslToRgb('hsl(206, 99%, 31%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(205, 66%, 42%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    success: {
      main: hslToRgb('hsl(115, 35%, 35%)'),
      light: hslToRgb('hsl(122, 39%, 49%)'),
      dark: hslToRgb('hsl(124, 55%, 24%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(115, 35%, 35%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    error: {
      main: hslToRgb('hsl(360, 61%, 51%)'),
      light: hslToRgb('hsl(1, 83%, 63%)'),
      dark: hslToRgb('hsl(0, 68%, 45%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(360, 61%, 51%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    warning: {
      main: hslToRgb('hsl(25, 91%, 58%)'),
      light: hslToRgb('hsl(36, 100%, 50%)'),
      dark: hslToRgb('hsl(21, 100%, 45%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(25, 91%, 58%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    suggestion: {
      main: hslToRgb('hsl(43, 96%, 58%)'),
      light: hslToRgb('hsl(43, 95%, 66%)'),
      dark: hslToRgb('hsl(43, 70%, 40%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(43, 96%, 58%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    draft: {
      main: hslToRgb('hsl(50, 100%, 50%)'),
      light: hslToRgb('hsl(50, 100%, 60%)'),
      dark: hslToRgb('hsl(50, 100%, 45%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(50, 100%, 50%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    advanced: {
      main: hslToRgb('hsl(205, 66%, 42%)'),
      light: hslToRgb('hsl(205, 66%, 52%)'),
      dark: hslToRgb('hsl(205, 66%, 32%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(205, 66%, 42%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    setback: {
      main: hslToRgb('hsl(25, 91%, 58%)'),
      light: hslToRgb('hsl(25, 91%, 68%)'),
      dark: hslToRgb('hsl(25, 91%, 48%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(25, 91%, 58%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    pushback: {
      main: hslToRgb('hsl(0, 0%, 88%)'),
      light: hslToRgb('hsl(0, 0%, 98%)'),
      dark: hslToRgb('hsl(0, 0%, 77%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(0, 0%, 88%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    validation: {
      main: hslToRgb('hsl(88, 50%, 53%)'),
      light: hslToRgb('hsl(88, 50%, 63%)'),
      dark: hslToRgb('hsl(88, 50%, 43%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(88, 50%, 53%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    done: {
      main: hslToRgb('hsl(115, 35%, 35%)'),
      light: hslToRgb('hsl(115, 35%, 45%)'),
      dark: hslToRgb('hsl(115, 35%, 25%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(115, 35%, 35%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    archived: {
      main: hslToRgb('hsl(0, 0%, 19%)'),
      light: hslToRgb('hsl(0, 0%, 29%)'),
      dark: hslToRgb('hsl(0, 0%, 9%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(0, 0%, 19%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
    cancel: {
      main: hslToRgb('hsl(360, 61%, 51%)'),
      light: hslToRgb('hsl(360, 61%, 61%)'),
      dark: hslToRgb('hsl(360, 61%, 41%)'),
      contrastText: getContrastRatio(hslToRgb('hsl(360, 61%, 51%)'), mainTextColor) > 4.5 ? mainTextColor : invertedTextColor,
    },
  },
  mixins: {
    toolbar: {
      minHeight: '3rem',
    },
    drawer: {
      width: {
        wide: '16rem',
        narrow: 'calc(3.5rem + 1px)',
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
});

export const mainTheme = createTheme(
  baseTheme,
  // MUI Material
  {
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: '0.25rem',
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiAppBar: {
        styleOverrides: { root: { backgroundColor: baseTheme.palette.primary.dark } },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          size: 'medium',
        },
        variants: [
          {
            props: { size: 'medium' },
            style: {
              height: '2.5rem',
            },
          },
          {
            props: { size: 'toolbar' },
            style: {
              height: '3rem',
              py: '0.75rem',
              borderRadius: 0,
              boxShadow: 'none',
            },
          },
        ],
      },
      MuiChip: {
        styleOverrides: {
          root: { borderRadius: '1rem' },
          icon: { fontSize: '1.125rem' },
        },
        variants: [
          {
            props: { size: 'small' },
            style: {
              height: '1.5rem',
            },
          },
          {
            props: { size: 'medium' },
            style: {
              height: '2rem',
            },
          },
        ],
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            minHeight: '100vh',
            overflowX: 'hidden',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '0.875rem 1rem',
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            color: lighten(baseTheme.palette.common.black, 0.53),
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '0.5rem',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: baseTheme.palette.common.black,
            '&.Mui-disabled': {
              '.MuiFormLabel-asterisk': {
                '&::before': {
                  color: 'inherit',
                },
              },
            },
          },
          /* Only way I found to override the content
         of the asterisk of MUI's required input text
         Replace it with a more visibile, bigger asterisk,
         and move it closer to the text
        */
          asterisk: {
            visibility: 'hidden',
            '&::before': {
              content: '"\u2217"',
              fontWeight: 'bolder',
              visibility: 'visible',
              position: 'relative',
              top: '-0.2rem',
              left: '0.2rem',
              fontSize: '1.4rem',
              color: baseTheme.palette.error.main,
            },
          },
        },
        defaultProps: {
          shrink: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: true,
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderRadius: '0.25rem',
          },
        },
      },
      MuiTablePagination: {
        defaultProps: {
          rowsPerPageOptions: [10, 25, 50, 100], //< Based on our API's query limits
        },
        styleOverrides: {
          root: {
            borderRadius: '0.25rem',
          },
          toolbar: {
            minHeight: '3.25rem !important',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          size: 'small',
        },
        styleOverrides: {
          root: {
            '&.borderless': {
              height: '100%',
              '& fieldset': {
                border: 'none',
              },
              '& .MuiInputBase-root': {
                height: '100%',
                alignContent: 'center',
              },
              '& .Mui-error': {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: 0,
                borderColor: baseTheme.palette.error.main,
              },
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiToggleButtonGroup: {
        defaultProps: { size: 'medium' },
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.background.paper,
          },
        },
        variants: [
          {
            props: { size: 'medium' },
            style: {
              height: '2.5rem',
            },
          },
        ],
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
    },
    typography: {
      subtitle2: {
        color: baseTheme.palette.text.secondary,
      },
      disabled: {
        color: baseTheme.palette.text.disabled,
      },
    },
  } satisfies ThemeOptions,
  // MUI X
  {
    components: {
      MuiDatePicker: {
        defaultProps: {
          timezone: defaultTimezone,
        },
      },
      MuiDateTimePicker: {
        defaultProps: {
          timezone: defaultTimezone,
        },
      },
      MuiDateRangePicker: {
        defaultProps: {
          timezone: defaultTimezone,
        },
      },
      MuiTimePicker: {
        defaultProps: {
          timezone: defaultTimezone,
        },
      },
    },
  },
  // MUI Labs
  {
    components: {
      MuiLoadingButton: {
        defaultProps: {
          variant: 'contained',
          size: 'medium',
        },
        variants: [
          {
            props: { size: 'medium' },
            style: {
              height: '2.5rem',
            },
          },
        ],
      },
    },
  },
);

export const deepNavTheme = createTheme(mainTheme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.background.paper,
          color: baseTheme.palette.text.primary,
          [mainTheme.breakpoints.up('sm')]: { boxShadow: 'none' },
        },
      },
    },
  },
});
